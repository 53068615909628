import {
  Box,
  ButtonGroup,
  Flex,
  Stack,
  toast,
  useToast,
} from "@chakra-ui/react";
import { blue } from "@material-ui/core/colors";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineClockCircle,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { BsDot, BsPlayFill } from "react-icons/bs";
import { PaystackButton } from "react-paystack";
import { useParams } from "react-router";
import { Route } from "react-router-dom";
import { useCourseListing } from ".";
import {
  Button,
  CourseProductCard,
  Heading,
  Image,
  Link,
  Ratings,
  Spinner,
  Text,
} from "../../components";
import { useApp } from "../../contexts";
import { useGoBack } from "../../hooks";
import {
  checkUserErollmentElegibility,
  enrollUser,
  http,
  userGetCourseDetailsUser,
  userPurchaseFreeCourse,
} from "../../services";
import { addToCart } from "../../services/http/endpoints/cart";
import {
  mainAreaHeight_userPages,
  pageWrapperSpacing_userPages,
} from "../../theme/breakpoints";
import {
  capitalizeFirstLetter,
  formatPriceToNaira,
  getDuration,
  truncateText,
} from "../../utils";
import { GetReviews } from "../../services/http/endpoints/reviews";
import Rated from "../../layouts/user/Rated";
export const CourseDetailsPage = () => {
  const appManager = useApp();
  const [preview, setPreview] = useState(false);
  const { id } = useParams();
  const [course, setCourse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const publicKey = "pk_test_d2ddf570dc83e76835d5f7359a2535e28267ab9c";
  const courseId = localStorage.getItem("course");
  const [reviews, setReviews] = useState("");
  const [isPurchasingFree, setIsPurchasingFree] = useState(false);
  const fetchReviews = async () => {
    const data = await GetReviews(courseId);
    setReviews(data);
  };
  useEffect(() => {
    fetchReviews();
  }, []);
  const email = appManager.state.user?.email;
  const name =
    appManager.state.user?.firstName + appManager.state.user?.lastName;
  const [phone, setPhone] = useState("");
  const [elegibility, setElegibility] = useState(false);

  const handleGoBack = useGoBack();
  const toast = useToast();

  const handleaddtocart = async () => {
    const path = "cart/add";
    var body = {
      courseId: course.id,
    };
    await http.post(path, body);
  };
  const { push } = useHistory();
  const handleFreeCourse = async () => {
    setIsPurchasingFree(true);
    try {
      const data = await userPurchaseFreeCourse({ courseId: course.id });
      push("/my-courses");
      toast({
        description: capitalizeFirstLetter(data?.message),
        position: "top",
        status: "success",
      });
      setIsPurchasingFree(false);
    } catch (err) {
      toast({
        description: capitalizeFirstLetter(err?.response?.data?.message),
        position: "top",
        status: "error",
      });
      setIsPurchasingFree(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    userGetCourseDetailsUser(id)
      .then((res) => {
        setCourse(res);
        setAmount(res.discountedPrice * 100);
      })
      .catch((err) => {
        setIsLoading(false);
        toast({
          description: capitalizeFirstLetter(err.message),
          position: "top",
          status: "error",
        });
      });

    checkUserErollmentElegibility(id)
      .then((res) => {
        if (res.success === true) {
          setElegibility(true);
        } else {
          setElegibility(false);
        }
        // setElegibility(res.success)
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setElegibility(false);
        setIsLoading(false);
      });
    //eslint-disable-next-line
  }, []);

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    color: blue,
    text: "Buy Now",
    onSuccess: () => enrollUser,
    onClose: () => alert("Wait! Don't leave :("),
  };

  const handleAddToCart = async () => {
    setIsCartLoading(true);
    const data = {
      resourceId: id,
      resourceType: "course",
    };
    try {
      await appManager.handleAddToCart(data);
      toast({
        description: "Resource added to cart",
        position: "top",
        status: "success",
      });
    } catch (err) {
      toast({
        description: capitalizeFirstLetter(err.message),
        position: "top",
        status: "error",
      });
    }
    setIsCartLoading(false);
  };

  // const enrollUserNow = () => {
  // 	enrollUser(id)
  // 		.then(res => {
  // 			if (res.success === true) {
  // 				toast.success('Enrolled Successfully');
  // 				setElegibility(true);
  // 			} else {
  // 				toast.error(res.message);
  // 			}
  // 		})
  // 		.catch(err => {
  // 			toast.error(err.message);
  // 		});
  // };

  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Flex>
        {isLoading === true ? (
          <Spinner />
        ) : course !== undefined ? (
          <>
            {" "}
            <Stack
              mr={4}
              pr={4}
              flex={1}
              spacing={10}
              height={mainAreaHeight_userPages}
              overflowY="scroll"
            >
              <Flex as="section">
                <Button asIcon mr={2} onClick={handleGoBack}>
                  <BiArrowBack />
                </Button>

                <Heading
                  as="h1"
                  fontSize="heading.h3"
                  d="flex"
                  alignItems="center"
                >
                  {course.title}
                </Heading>
              </Flex>

              <Stack spacing={5} as="section">
                <Box
                  overflow="hidden"
                  rounded="md"
                  width="100%"
                  height="450px"
                  pos="relative"
                >
                  {course.previewUrl && (
                    <video
                      title={course.title}
                      src={course.previewUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      autoPlay
                      controls
                    />
                  )}
                  {preview && (
                    <video
                      title={course.title}
                      src={course.preview}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      autoPlay
                      controls
                    />
                  )}

                  {!preview && (
                    <>
                      <Image
                        src={course.image}
                        alt={course.title}
                        width="100%"
                        height="100%"
                      />

                      <Flex
                        onClick={() => setPreview(true)}
                        pos="absolute"
                        top={0}
                        left={0}
                        alignItems="center"
                        justifyContent="center"
                        color="#fff"
                        bg="rgba(0, 0, 0, .5)"
                        width="100%"
                        height="100%"
                        flexDirection="column"
                        cursor="pointer"
                        sx={{
                          "svg, p": {
                            transition: "all .3s ease-in-out",
                          },
                          "&:hover svg": {
                            transform: "scale(1.2)",
                          },
                          "&:hover p": {
                            transform: "translateY(-10px)",
                          },
                        }}
                      >
                        <BsPlayFill fontSize="100px" />
                        <Text as="level1">Preview this course</Text>
                      </Flex>
                    </>
                  )}
                </Box>

                <Text opacity={0.8}>By {course.instructor.name}</Text>
                <Text>{truncateText(course.description, 200)}</Text>

                {/* <Flex alignItems="center">
                <Ratings value={course.ratingsAverage} />

                <Text as="level5" ml={2}>
                  ({course.ratingsCount})
                </Text>
              </Flex> */}
              </Stack>

              {/* <Section heading="What you’ll learn" showHeight="80px">
              <List spacing={3}>
                <Grid
                  gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                  gridGap={3}
                >
                  {course.whatYouLearn.map((item, index) => (
                    <ListItem key={index}>
                      <Text as="level5">
                        <ListIcon as={BsCheck} color="primary.base" />
                        {item}
                      </Text>
                    </ListItem>
                  ))}
                </Grid>
              </List>
            </Section> */}

              <Section heading="Description">
                <Box>
                  <Text>{course.description}</Text>
                </Box>
              </Section>

              <Section
                renderHeader={() => (
                  <Flex justifyContent="space-between">
                    <Heading fontSize="text.level2">
                      Curriculum({course.noofmodules} Modules)
                    </Heading>

                    <Flex alignItems="center" opacity={0.8}>
                      <AiOutlineClockCircle />

                      <Text>
                        {getDuration(course.totalDuration, true).combinedText}
                      </Text>
                    </Flex>
                  </Flex>
                )}
                w="500px"
                showHeight="200px"
              >
                {course.modules.map((module, moduleIndex) => (
                  <ModuleAccordion
                    key={module.id}
                    moduleIndex={moduleIndex}
                    module={module}
                    isOpen={moduleIndex === 0 && true}
                    courseId={course.id}
                  />
                ))}
              </Section>

              {course && (
                <StudentsAlsoBoughtSection currentCourseId={course.id} />
              )}

              <Section heading="Instructor" showHeight="130px">
                <Box w="fit-content" mb={5}>
                  <Link href={`/users/${course.instructor.id}`}>
                    <Flex
                      alignItems="center"
                      _hover={{
                        textDecoration: "underline",
                      }}
                    >
                      {/* <Image
                      src={course.instructor.photo}
                      alt={course.instructor.name}
                      height={{ base: "50px", tablet: "50px" }}
                      width={{ base: "50px", tablet: "50px" }}
                      flexShrink={0}
                      rounded="full"
                      mr={2}
                    /> */}

                      <Box>
                        <Text bold mb={1} as="level3">
                          {course.instructor.name}
                        </Text>
                        {/* <Text opacity={0.9}>{course.instructor.title}</Text> */}
                      </Box>
                    </Flex>
                  </Link>
                </Box>

                <Text>{course.instructor.name}</Text>

                <Box pt={5}>
                  <Button sm secondary link={`/users/${course.instructor.id}`}>
                    View Profile
                  </Button>
                </Box>
              </Section>
              {course.reviews.length > 0 ? (
                <>
                  <Section heading="Reviews" showMoreFeature={false}>
                    <Stack spacing={5}>
                      {/* <Flex gridGap={2} alignItems="flex-end">
                  <Text bold as="level2">
                    {course.ratingsAverage}
                  </Text>
                  <Text mb={1}>course rating</Text>
                </Flex> */}

                      {/* <Stack spacing={3} pb={5}>
                  {course.ratingOverview.map((peter) => (
                    <Flex key={peter.rating} gridGap={2}>
                      <Progress
                        h="20px"
                        value={peter.percentage}
                        colorScheme="blackAlpha"
                        flex={1}
                      />

                      <Ratings value={peter.rating} />

                      <Text>{peter.percentage}%</Text>
                    </Flex>
                  ))}
                </Stack> */}

                      <Box>
                        <Box mb={3} display={"flex"} gap={2}>
                          {reviews?.data?.map((dat, i) => (
                            <Box>
                              <Flex gap={4} mb={3}>
                                {`(${i + 1})`}
                                <Rated val={dat.reviewerRating} />
                              </Flex>
                              <Text as="level4">
                                {dat.reviewer.firstName} {dat.reviewer.lastName}{" "}
                                rated this course {dat.reviewerRating} out of 5
                              </Text>
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <Box>
                        <Button
                          sm
                          secondary
                          link={`/courses/${course.id}/reviews`}
                        >
                          See all Reviews
                        </Button>
                      </Box>
                    </Stack>
                  </Section>
                </>
              ) : (
                <></>
              )}
            </Stack>
            <Stack
              // pos="sticky"
              // top={0}
              as="aside"
              w="300px"
              h="fit-content"
              shadow="0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)"
              rounded="md"
              a={5}
              spacing={8}
              px={3}
              py={5}
            >
              {course.isPurchased ? (
                <Button disabled={true} w="100%" secondary>
                  Enrolled
                </Button>
              ) : elegibility === false ? (
                <>
                  <Stack spacing={3}>
                    <Text bold as="level2" color="secondary.8">
                      Billing Summary
                    </Text>

                    <Flex justifyContent="space-between">
                      <Text>Price</Text>
                      <Text>{formatPriceToNaira(+course.price)}</Text>
                    </Flex>

                    <Flex justifyContent="space-between">
                      <Text>Discount</Text>
                      <Text>
                        -
                        {formatPriceToNaira(
                          +course.price - +course.discountedPrice
                        )}
                      </Text>
                    </Flex>

                    <Flex justifyContent="space-between">
                      <Text>Amount to be paid</Text>
                      <Text>{formatPriceToNaira(+course.discountedPrice)}</Text>
                    </Flex>
                  </Stack>

                  {/* <Stack spacing={3}>
            <Text bold as="level2" color="secondary.8">
              Select Payment Method
            </Text>

            <ButtonGroup>
              <Button secondary leftIcon={<SiMastercard />}>
                Master Card
              </Button>
              <Button secondary leftIcon={<SiVisa />}>
                Visa Card
              </Button>
            </ButtonGroup>
          </Stack> */}

                  <Stack spacing={3}>
                    {course.price === "0.00" ? (
                      <Button
                        isLoading={isPurchasingFree}
                        disabled={isPurchasingFree}
                        onClick={handleFreeCourse}
                        w="100%"
                      >
                        Buy For Free
                      </Button>
                    ) : (
                      <Button link="/checkout" w="100%">
                        <PaystackButton text="Buy Now" {...componentProps} />
                      </Button>
                    )}

                    {course.price !== "0.00" && (
                      <Button
                        onClick={handleAddToCart}
                        isLoading={isCartLoading}
                        disabled={isCartLoading}
                        loadingText="Adding to cart..."
                        w="100%"
                        secondary
                      >
                        Add to cart
                      </Button>
                    )}

                    {/* <Button secondary>Add to Wishlist</Button> */}
                    {/* </ButtonGroup> */}
                  </Stack>
                </>
              ) : (
                <Text bold as="level2" color="secondary.8">
                  You are Not Eligible to Enroll for this Course
                </Text>
              )}
            </Stack>
          </>
        ) : (
          <Heading color="red.500">{"server error"}</Heading>
        )}
      </Flex>
    </Box>
  );
};

const ModuleAccordion = ({ moduleIndex, module, courseId, isOpen = false }) => {
  const [open, setOpen] = useState(isOpen);
  const handleToggle = () => setOpen((prev) => !prev);

  return (
    <Box p={5} key={module.id} pb={0}>
      <Box onClick={handleToggle}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          _hover={{ opacity: 0.8, cursor: "pointer" }}
        >
          <Box w="400px">
            <Heading as="h3" fontSize="text.level3">
              Module {moduleIndex + 1} - {module.name}
            </Heading>
          </Box>

          <Box color="primary.base">
            {open ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </Box>
        </Flex>

        <Flex gridGap={2} alignItems="center" mt={2}>
          <Text as="level5" opacity={0.8}>
            {getDuration(module.totalDuration).combinedText}
          </Text>

          <BsDot />

          {module.moduleLessons.length > 0 ? (
            <Text as="level5">{module.moduleLessons.length} lessons</Text>
          ) : (
            <Text as="level5">No lessons</Text>
          )}
        </Flex>
      </Box>

      {/* {
        module.lenght > 0 ? (<>{open && (
          <Box mt={3} p={2}>
            <OrderedList spacing={3}>
              {module.moduleLessons.map((lesson) => (
                <ListItem key={lesson.id}>
                  <Flex justifyContent="space-between">
                    <Text pl={3}>
                      Module {moduleIndex + 1} - {lesson.title}
                    </Text>

                    <ButtonGroup size="xs">
                      <Button asIcon link={`/my-courses/${courseId}/take`}>
                        <BiPlayCircle />
                      </Button>
                    </ButtonGroup>
                  </Flex>
                </ListItem>
              ))}
            </OrderedList>
          </Box>
        )}</>) : (<Text as="level5">No lessons</Text>)
      } */}
    </Box>
  );
};

const StudentsAlsoBoughtSection = ({ currentCourseId }) => {
  const {
    courseListing,
    // handleFetch
  } = useCourseListing({
    studentsAlsoBought: true,
    currentCourseId,
  });

  return (
    <Section heading="Students also bought" showMoreFeature={false}>
      <Flex gridGap="3" flexDirection="column" mb={5}>
        {courseListing.data?.map((course) => (
          <CourseProductCard key={course.id} course={course} listItem />
        ))}
      </Flex>

      <Box>
        <Button sm secondary>
          See all
        </Button>
      </Box>
    </Section>
  );
};

const Section = ({
  heading,
  renderHeader,
  children,
  showHeight = "100px",
  showMoreFeature = true,
  ...rest
}) => {
  const [showMore, setShowMore] = useState(false);
  const handleToggleVisibility = () => setShowMore((prev) => !prev);
  const contentWrapperRef = useRef();

  return (
    <Stack spacing={5} as="section" {...rest}>
      {renderHeader ? (
        renderHeader()
      ) : (
        <Heading fontSize="text.level2">{heading}</Heading>
      )}

      <Stack
        spacing={5}
        overflowY="hidden"
        transition=".3s"
        h={
          !showMoreFeature
            ? "auto"
            : showMore
            ? contentWrapperRef.current?.clientHeight + 10 + "px"
            : showHeight
        }
      >
        <Box ref={contentWrapperRef}>{children}</Box>
      </Stack>

      {showMoreFeature && (
        <Box transform="translateY(-10px)">
          <Button xs ghost onClick={handleToggleVisibility}>
            {showMore ? "show less" : "show more"}
            {showMore ? <AiOutlineUp /> : <AiOutlineDown />}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export const GeneralCourseDetailsPageRoute = ({ ...rest }) => {
  return (
    <Route {...rest} render={(props) => <CourseDetailsPage {...props} />} />
  );
};
