import { Avatar } from "@chakra-ui/avatar";
import { Box, Flex, Stack } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { AiOutlinePoweroff } from "react-icons/ai";
import { Button, Heading, Link, Text } from "../../../components";
import { useApp } from "../../../contexts";
import { userRoleName } from "../../../hooks/getValues";
import { links, settingsLinks, superAdminSettingsLinks } from "./links";
import SidebarLink from "./SidebarLink";
import { FiX } from "react-icons/fi";

const Sidebar = ({open, setOpen}) => {
  const { state, getOneMetadata, handleLogout } = useApp();

  const isSettingsPage = /settings/i.test(window.location.pathname);

  const role = userRoleName;

  const isSuperAdmin = /admin/i.test(role);

  return (
    <Flex
    
      flexDirection="column"
      as="aside"
      width="270px"
      h={"100%"}
      flexShrink={0}
      paddingRight={1}
      pos={{ lg: "static", sm: "fixed", md: "fixed", base: "fixed" }}
      left={open?"0":"-100%"}
      transitionDuration={"0.6s"}
      zIndex={320}
      top={0}
    >
     
      <Flex
        flexDirection="column"
        height="100%"
        backgroundColor="white"
        shadow="lg"
      >
        <Box display={{ lg: "none", sm: "block", md: "block", base: "block" }}>
        <FiX  color="#000" size={30} style={{
          position:"absolute",
          right:"13px",
          top:"7px",
          cursor:"pointer"
         }} onClick={()=>{
          setOpen(false)
         }}/>
        </Box>
        {isSettingsPage ? (
          <Box paddingTop={10} paddingX={5}>
            <Heading fontSize="heading.h3" paddingBottom={2}>
              Settings
            </Heading>
          </Box>
        ) : (
          <Box padding={5}>
            <Stack
              as="header"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              borderBottom="1px"
              borderColor="gray.200"
              height="200px"
              marginBottom={5}
              paddingBottom={5}
            >
              
              {!state.user ? (
                <Skeleton rounded="full" boxSize="100px" />
              ) : (
                <Avatar
                  name={state.user?.firstName + " " + state.user?.lastName}
                  borderRadius="100%"
                  width="100px"
                  height="100px"
                  src={state.user?.profilePics}
                />
              )}

              {state.user && (
                <>
                  <Link href={`/admin/users/details/${state.user.id}/profile`} >
                    <Text fontSize="heading.h3">
                      {state.user.firstName || "NotSet"} {state.user.lastName}
                    </Text>
                  </Link>

                  <Text color="gray.500" textTransform="capitalize">
                    {getOneMetadata("userRoles", state.user.userRoleId)?.name}
                  </Text>
                </>
              )}
            </Stack>
          </Box>
        )}

        <Box as="nav" padding={5} height="250px" overflowY="scroll">
          <Stack as="ul" spacing={2} listStyleType="none">
            {isSettingsPage
              ? isSuperAdmin
                ? superAdminSettingsLinks.map((link) => (
                    <SidebarLink key={link.text} link={link} />
                  ))
                : settingsLinks.map((link) => (
                    <SidebarLink key={link.text} link={link} />
                  ))
              : links.map((link) => (
                  <SidebarLink key={link.text} link={link} onClick={()=>{
                    setOpen(!open)
                  }}/>
                ))}
          </Stack>
        </Box>
        <Box padding={5}>
          <Button
            width="100%"
            onClick={handleLogout}
            ghost
            backgroundColor="secondary.05"
            leftIcon={<AiOutlinePoweroff />}
          >
            Logout
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
