import { Box } from "@chakra-ui/layout";
import { Switch } from "react-router-dom";
import OverviewPageRoute from "../pages/OverviewPage";
import QuestionsPageRoute from "../pages/QuestionsPage";

const MainArea = () => {
  return (
    <Box flex={1} overflowY="scroll">
      <Switch>
        <OverviewPageRoute path="/admin/courses/:id/assessment/:q/new/overview"/>
        <OverviewPageRoute path="/admin/courses/:id/assessment/:assessmentId/:quizId/overview" />
        <QuestionsPageRoute path="/admin/courses/:id/assessment/:assessmentId/:examId/questions/:questionId" />
      </Switch>
    </Box>
  );
};

export default MainArea;
