import axios from "axios";
import { http } from "../http";

/**
 * Endpoint to get cart for the current loggedIn user - (cart)
 */
export const getCart = async () => {
  const path = "/cart";

  const {
    data: { data },
  } = await http.get(path);
  // ;
  console.log(data);
  return { data };
};

/**
 * Endpoint for user to add a resource to cart - (cart)
 */
export const addToCart = async (body) => {
  const path = "/cart/add";

  const {
    data: { message },
  } = await http.post(path, body);

  return { message };
};

/**
 * Endpoint to remove a resource from cart to add to cart - (cart)
 */
export const removeFromCart = async (body) => {
  const path = "/cart/remove";
  console.log(body);
  const {
    data: { message },
  } = await http.delete(path, { data: body });

  return { message };
};
