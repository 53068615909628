import { IconButton } from "@chakra-ui/button";
import { Flex } from "@chakra-ui/layout";
import VisuallyHidden from "@chakra-ui/visually-hidden";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import { v4 as uuid } from "uuid";
import { Input } from "..";
import { useMyCourses } from "../../pages/user";
import "./style.css";

export const SearchBar = ({
  adminLayoutHeaderStyle,
  placeholder = "Search Courses",
  sm,
  fontSize,
  // onSearch,
  onClear,
  onClick,
  ...rest
}) => {
  adminLayoutHeaderStyle = adminLayoutHeaderStyle
    ? {
        backgroundColor: "secondary.7",
        textColor: "white",
        rounded: "2rem",
        border: "none",
      }
    : {};

  const inputId = uuid();

  const { myCourses, handleFetchMyCourses } = useMyCourses();
  const [value, setValue] = useState("");

  useEffect(() => { handleFetchMyCourses() },[handleFetchMyCourses] );
  const { register, handleSubmit } = useForm();

  const onSubmit = async ({ query }) => {
    onSearch?.(query);
  };

  const onSearch = (searchTerm) => {
    setValue(searchTerm);
    // our api to fetch the search result
  };
  const onChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Flex
    mt={4}
      as="form"
      border="1px"
      value = {value}
      backgroundColor="white"
      textColor="accent.3"

      rounded="full"
      overflow="hidden"
      height={sm ? "33px" : "auto"}
      onSubmit={handleSubmit(onSubmit)}
      {...adminLayoutHeaderStyle}
      {...rest}
    >
      <VisuallyHidden as="label" htmlFor="search">
        Search Courses
      </VisuallyHidden>
      <Input
        border="none"
        type="search"
        onChange={onChange} 
        onInput={(e) => e.target.value === "" && onClear?.()}
        onClick={onClick}
        placeholder={placeholder}
        paddingLeft={2}
        size={sm && "sm"}
        id={inputId}
        fontSize={fontSize}
        _focus={{
          transform: "scale(1.01)",
          "&::placeholder": {
            textColor: "black",
          },
        }}
        {...register("query", { validate: true })}
      />

      <IconButton
        size={sm && "sm"}
        _focus={{
          fontSize: "20px",
          textColor: "primary.base",
          backgroundColor: "secondary.1",
        }}
        _hover={
          adminLayoutHeaderStyle && {
            backgroundColor: "secondary.6",
            color: "white",
          }
        }
        rounded="none"
        variant="ghost"
        width="60px"
        order={-1}
        type="submit"
      >
        <BsSearch />
      </IconButton>

      <div className="dropdown">
        {myCourses?.data
          ?.filter((item) => {
            const searchTerm = value?.toLowerCase();
            const fullName = item?.title?.toLowerCase();

            return (
              searchTerm &&
              fullName.startsWith(searchTerm) &&
              fullName !== searchTerm
            );
          })
          .slice(0, 10)
          .map((item) => (
            <div
              onClick={() => onSearch(item.full_name)}
              className="dropdown-row"
              key={item.fullName}
            >
              {item.fullName}
            </div>
          ))}
      </div>

    </Flex>
  );
};

SearchBar.propTypes = {
  adminLayoutHeaderStyle: PropTypes.bool,
  placeholder: PropTypes.string,
  sm: PropTypes.bool,
  fontSize: PropTypes.string,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  onClick: PropTypes.func,
};
