import { useParams } from "react-router-dom";
import Icon from "@chakra-ui/icon";
import { Box, Flex, HStack } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/tooltip";
import { AiOutlineLeft } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { IoVideocam } from "react-icons/io5";
import { MdOutlinePending } from "react-icons/md";
import { VscFiles } from "react-icons/vsc";
import {
  Button,
  Heading,
  Link,
  SkeletonText,
  Text,
} from "../../../../components";
import colors from "../../../../theme/colors";

const Sidebar = ({ manager }) => {
  const { course, links, isLoading, sidebarLinkClickedState } = manager;

  const [, setSidebarLinkClicked] = sidebarLinkClickedState;
  const handleSidebarLinkClicked = () => setSidebarLinkClicked(true);

  const { module_id: moduleId } = useParams();

  const getStatusText = (link) => {
    if (link.hasCompleted) return "(Completed)";
    if (link.disabled || link.type === "assessment") return "";

    return "(Ongoing)";
  };

  const renderContent = (link, props) => (
    <Tooltip
      label={`${link.text} ${getStatusText(link)}`}
      aria-label={link.text}
    >
      <HStack spacing={2} padding={2} {...props}>
        <Icon opacity={link.disabled ? 0.5 : 1} fontSize="text.level1">
          {link.type !== "video" ? <VscFiles /> : <IoVideocam />}
        </Icon>

        <Text opacity={link.disabled ? 0.5 : 1} isTruncated flex={1}>
          {link.text}
        </Text>

        {link.hasCompleted && (
          <Icon fontSize="text.level2" color="accent.5">
            <FaCheck />
          </Icon>
        )}

        {link.disabled && (
          <Icon fontSize="heading.h3" color="secondary.6">
            <MdOutlinePending />
          </Icon>
        )}
      </HStack>
    </Tooltip>
  );

  return (
    <Flex
      flexDirection="column"
      as="aside"
      height="100vh"
      flexShrink={0}
      width="250px"
      borderRight="1px"
      borderColor="accent.1"
    >
      <Box as="header">
        <Flex
          justifyContent="space-between"
          borderBottom="1px"
          borderColor="accent.1"
        >
          <Button
            ghost
            leftIcon={<AiOutlineLeft />}
            flex={1}
            link={course?.id && `/modules/${course.id}`}
          >
            Back
          </Button>
          <Button ghost flex={1} link="/">
            Home
          </Button>
        </Flex>

        <Box paddingY={7} paddingX={2}>
          {isLoading ? (
            <SkeletonText />
          ) : (
            <>
              <Heading fontSize="h4">
                {
                  course?.purchasedCourseModules.find(
                    (pm) => pm.id === moduleId
                  )?.courseModule.name
                }
              </Heading>
            </>
          )}
        </Box>
      </Box>

      <Box as="nav" paddingBottom={16} flex={1} overflowY="auto">
        <Box as="ul" listStyleType="none" paddingRight={1}>
          {isLoading ? (
            <SkeletonText numberOfLines={10} height="37px" spacing={1} />
          ) : (
            links
              ?.filter((moduleLink) => moduleLink.id === moduleId)
              .map((ml) => (
                <div key={ml.id}>
                  {ml.lessonLinks.map((ll) => (
                    <li key={ll.id}>
                      {ll.disabled ? (
                        renderContent(ll, { cursor: "not-allowed" })
                      ) : (
                        <Link
                          navLink
                          href={ll.to}
                          onClick={handleSidebarLinkClicked}
                          exact={true}
                          style={{
                            display: "block",
                          }}
                          activeStyle={{
                            background: colors.primary.base,
                            color: colors.white,
                          }}
                        >
                          {renderContent(ll)}
                        </Link>
                      )}
                    </li>
                  ))}
                  {ml.examinationLink && (
                    <li key={ml.examinationLink.id}>
                      {ml.examinationLink.disabled ? (
                        renderContent(ml.examinationLink, {
                          cursor: "not-allowed",
                        })
                      ) : (
                        <Link
                          navLink
                          href={ml.examinationLink.to}
                          onClick={handleSidebarLinkClicked}
                          exact={true}
                          style={{
                            display: "block",
                          }}
                          activeStyle={{
                            background: colors.primary.base,
                            color: colors.white,
                          }}
                        >
                          {renderContent(ml.examinationLink)}
                        </Link>
                      )}
                    </li>
                  )}
                </div>
              ))
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default Sidebar;
