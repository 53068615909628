import { Box, Flex } from "@chakra-ui/react";
import { Fragment } from "react";
import { Route } from "react-router-dom";
import {
  useMyCourses,
  usePurchasedCourses,
  useCompletedCourses,
  useOnGoingCourses,
} from ".";
import { Button, CourseOverviewCard, Heading, Tabs } from "../../components";
import { CourseOverviewCard2 } from "../../components/Cards/CourseOverviewCard2";
import { EmptyState } from "../../layouts";
import { pageWrapperSpacing_userPages } from "../../theme/breakpoints";
import { createRange } from "../../utils";

const MyCoursesPage = () => {
  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Heading as="h1" fontSize="heading.h3" mb={5}>
        My Courses
      </Heading>

      <Tabs
        tabs={[
          {
            id: "all",
            label: "All Courses",
            content: <AllCourses />,
          },
          {
            id: "ongoing",
            label: "Ongoing",
            content: <OngoingCourses />,
          },
          {
            id: "completed",
            label: "Completed",
            content: <CompletedCourses />,
          },
          {
            id: "purchased",
            label: "Purchased",
            content: <PurchasedCourses />,
          },
        ]}
      />
    </Box>
  );
};

const AllCourses = () => {
  const { myCourses, handleFetchMyCourses } = useMyCourses({});

  return (
    <>
      <CourseListingGridLayout2
        courses={myCourses.data}
        err={myCourses.err}
        loading={myCourses.loading}
        onErrRetry={handleFetchMyCourses}
      />
    </>
  );
};

const OngoingCourses = () => {
  const { myCourses, handleFetchMyCourses } = useOnGoingCourses({
    ongoing: true,
  });

  return (
    <CourseListingGridLayout2
      courses={myCourses.data}
      err={myCourses.err}
      loading={myCourses.loading}
      onErrRetry={handleFetchMyCourses}
    />
  );
};

const CompletedCourses = () => {
  const { myCourses, handleFetchMyCourses } = useCompletedCourses({
    completed: true,
  });
  return (
    <>
      {myCourses?.data?.length === 0 ? (
        <EmptyState description={"You are yet to complete a course"} />
      ) : (
        <CourseListingGridLayout
          courses={myCourses?.data}
          err={myCourses?.err}
          loading={myCourses?.loading}
          onErrRetry={handleFetchMyCourses}
        />
      )}
    </>
  );
};

const PurchasedCourses = () => {
  const { myCourses, handleFetchMyCourses } = usePurchasedCourses({
    purchased: true,
  });

  return (
    <CourseListingGridLayout2
      courses={myCourses.data}
      err={myCourses.err}
      loading={myCourses.loading}
      onErrRetry={handleFetchMyCourses}
    />
  );
};

export const CourseListingGridLayout = ({
  courses,
  err,
  loading,
  onErrRetry,
  renderCard,
}) => (
  <div>
    <Flex gridGap={8} alignItems="flex-start" py={2} flexWrap={"wrap"}>
      {courses &&
        courses.map((course, index) => (
          <div>
            {renderCard ? (
              <Fragment key={course.id}>{renderCard(course)}</Fragment>
            ) : (
              <CourseOverviewCard
                key={course.id}
                course={course}
                controlledDimensions
              />
            )}
          </div>
        ))}

      {loading &&
        createRange(8).map((i) => (
          <CourseOverviewCard key={i} controlledDimensions isLoading />
        ))}
    </Flex>

    {err && (
      <EmptyState
        cta={<Button onClick={onErrRetry}>Try Again</Button>}
        heading="Oops An Error Occurred"
        description="An unexpected error occurred, please try again later"
      />
    )}
  </div>
);

export const CourseListingGridLayout2 = ({
  courses,
  err,
  loading,
  onErrRetry,
  renderCard,
}) => (
  <div>
    <Flex
      flexWrap={"wrap"}
      // gridTemplateColumns={
      //   "repeat(auto-fit, minmax(200px, 1fr))"
      // }
      // gridTemplateColumns={{
      //   base: "repeat(auto-fit, minmax(200px, 1fr))",
      //   tablet: "repeat(200, minmax(200px, 1fr))",
      //   laptop: "repeat(200, minmax(250px, 1fr))",
      // }}
      // gridGap={2}
      // alignItems="flex-start"
      py={2}
      gap={6}
    >
      {courses &&
        courses.map((course) => (
          <div key={course.id}>
            {renderCard ? (
              <Fragment key={course.id}>{renderCard(course)}</Fragment>
            ) : (
              <CourseOverviewCard2
                key={course.id}
                course={course}
                controlledDimensions
                layout2
              />
            )}
          </div>
        ))}

      {loading &&
        createRange(8).map((i) => (
          <CourseOverviewCard key={i} controlledDimensions isLoading />
        ))}
    </Flex>

    {err && (
      <EmptyState
        cta={<Button onClick={onErrRetry}>Try Again</Button>}
        heading="Oops An Error Occurred"
        description="An unexpected error occurred, please try again later"
      />
    )}
  </div>
);

export const MyCoursesPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <MyCoursesPage {...props} />} />;
};
