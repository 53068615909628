import { rest } from "msw";
import { getUrl } from "../../../http";
import { handleSuccessResponse } from "../../helpers";
import { userForumGetTagsRes, userForumCreateTagRes } from "./responses";

const userForumGetTags = rest.get(
  getUrl("/forum/tag4353y5"),
  handleSuccessResponse(userForumGetTagsRes)
);

const userForumCreateTag = rest.post(
  getUrl("/forum/tag/create3r55"),
  handleSuccessResponse(userForumCreateTagRes)
);

const forumTag = [userForumGetTags, userForumCreateTag];

export default forumTag;
