import React, { useCallback, useEffect, useState } from "react";
import { adminRequestModuleDetailsForModuleId } from "../services";
import { useParams } from "react-router-dom";

const useModule = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [moduleDetails, setModuleDetails] = useState({});
  const [module, setModule] = useState([]);
  const { moduleId, courseId } = useParams();
  console.log(moduleId);
  const isEditMode = moduleId && moduleId !== "new";
  const fetcher = useCallback(async () => {
    const { data: modules } =
      isEditMode &&
      (moduleId !== "new"
        ? await adminRequestModuleDetailsForModuleId(moduleId)
        : null);
    setModule(modules);
    setIsLoading(modules?.loading);
    setError(modules?.err);
    setModuleDetails(modules?.data);
    console.log(module);
  }, []);

  useEffect(() => {
    fetcher();
  }, []);
  return {
    isLoading,
    isError,
    moduleDetails,
    moduleId,
    courseId,
    module,
  };
};

export default useModule;
