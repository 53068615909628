import {
  Avatar,
  Box,
  Center,
  Flex,
  Grid,
  Text,
  Tooltip,
  background,
  position,
} from "@chakra-ui/react";
import React from "react";
import { Route } from "react-router-dom";
import {
  CourseOverviewCard,
  Heading,
  Image,
  Link,
  ScrollCarousel,
  Spinner,
} from "../../../components";
import Rated from "../../../layouts/user/Rated";
import { AiFillCaretDown, AiFillStar } from "react-icons/ai";
import { pageWrapperSpacing_userPages, sectionMaxWidth_userPages } from "../../../theme/breakpoints";
import mockImg from "../../../assets/images/onboarding1.png";
import { useState } from "react";
import { useApp } from "../../../contexts";
import { useQueryParams } from "../../../hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useMyCourses } from "../HomePage";
import { useEffect } from "react";
import { CourseListingGridLayout } from "../MyCoursesPage";
import ca from "date-fns/esm/locale/ca/index.js";
import { userGetCourseCategory } from "../../../services";
import { useCallback } from "react";
import { EmptyState } from "../Courses/Grades/GradesPage";
import { truncateText } from "../../../utils";

const MOCKCATEGORY = [
  {
    id: 1,
    cat: "General",
    img: mockImg,
    title: "Fundamentals of Deisgn princples for beginners",
    author: "Dr Joshua Yin",
    rating: "4.5",
    currentprice: "NGN 8,900.00",
    prevprice: "NGN 12,900.00",
  },
  {
    id: 2,
    cat: "Finance",
    img: mockImg,
    title: "Fundamentals of Deisgn princples for beginners",
    author: "Dr Joshua Yin",
    rating: "4.5",
    currentprice: "NGN 8,900.00",
    prevprice: "NGN 12,900.00",
  },
  {
    id: 3,
    cat: "Design",
    img: mockImg,
    title: "Fundamentals of Deisgn princples for beginners",
    author: "Dr Joshua Yin",
    rating: "4.5",
    currentprice: "NGN 8,900.00",
    prevprice: "NGN 12,900.00",
  },
  {
    id: 4,
    cat: "Finance",
    img: mockImg,
    title: "Fundamentals of Deisgn princples for beginners",
    author: "Dr Joshua Yin",
    rating: "4.5",
    currentprice: "NGN 8,900.00",
    prevprice: "NGN 12,900.00",
  },
  {
    id: 5,
    cat: "General",
    img: mockImg,
    title: "Fundamentals of Deisgn princples for beginners",
    author: "Dr Joshua Yin",
    rating: "4.5",
    currentprice: "NGN 8,900.00",
    prevprice: "NGN 12,900.00",
  },
  {
    id: 6,
    cat: "Design",
    img: mockImg,
    title: "Fundamentals of Deisgn princples for beginners",
    author: "Dr Joshua Yin",
    rating: "4.5",
    currentprice: "NGN 8,900.00",
    prevprice: "NGN 12,900.00",
  },
];

const Categories = () => {
  const categoryId = useQueryParams().get("categoryId");
  const [loading, setLoading] = useState(false);

  const appManager = useApp();
  const metadata = appManager.state.metadata;

  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      {/* <Box
          display="flex"
          border="1px solid #000"
          borderRadius="50px"
          alignItems="center"
          padding="8px"
          gap="5px"
          cursor="pointer"
          position="relative"
          onClick={() => setShowMenu((prev) => !prev)}
        >
          <p>All Categories</p>
          <AiFillCaretDown />

          <div
            className={`categories-dropdown_menu ${
              showMenu ? 'drop-active' : 'drop-inactive'
            }`}
          >
            {metadata?.courseCategory?.map((item) => (
              <p key={item?.id}>{item?.categoryName}</p>
            ))}
          </div>
        </Box> */}
      {/* <Grid
        templateColumns={{
          lg: 'repeat(4, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        gap={5}
        alignItems="flex-start"
        py={2}
      >
        {MOCKCATEGORY.map((item) => (
          <Box
            key={item.id}
            marginTop="30px"
            height="293px"
            width="270px"
            marginBottom="18px"
            borderRadius="8px"
            boxShadow="lg"
            border="2px solid #f5f5f5"
            background="#fff"
          >
            <Box
              height="139px"
              width="260px"
              marginLeft="auto"
              marginRight="auto"
              marginTop="5px"
              position="relative"
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                }}
                src={item.img}
                alt={item.author}
              />
              <div
                style={
                  item.cat === 'General'
                    ? {
                        background: '#6FC05A',
                        color: '#f5f5f5',
                        fontSize: '12px',
                        paddingTop: '3px',
                        paddingBottom: '3px',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                        borderRadius: '50px',
                        position: 'absolute',
                        top: '10px',
                        left: '8px',
                      }
                    : item.cat === 'Design'
                    ? {
                        background: '#880000',
                        color: '#f5f5f5',
                        fontSize: '12px',
                        paddingRight: '10px',
                        paddingTop: '3px',
                        paddingBottom: '3px',
                        paddingLeft: '10px',
                        borderRadius: '50px',
                        position: 'absolute',
                        top: '10px',
                        left: '8px',
                      }
                    : {
                        background: '#5A7DC0',
                        color: '#f5f5f5',
                        fontSize: '12px',
                        paddingRight: '10px',
                        paddingTop: '3px',
                        paddingBottom: '3px',
                        paddingLeft: '10px',
                        borderRadius: '50px',
                        position: 'absolute',
                        top: '10px',
                        left: '8px',
                      }
                }
              >
                {item.cat}
              </div>
              <Box display="flex" flexDirection="column" gap="3px">
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginTop: '5px',
                  }}
                >
                  {item.title}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {item.author}
                </p>
                <p
                  style={{
                    display: 'flex',
                    fontSize: '12px',
                    alignItems: 'center',
                    gap: '5px',
                    color: '#FFD700',
                  }}
                >
                  <AiFillStar /> {item.rating}
                </p>
              </Box>
              <Box
                display="flex"
                gap="30px"
                alignItems="center"
                marginTop="10px"
              >
                <p style={{ fontSize: '12px', fontWeight: 'bold' }}>
                  {item.currentprice}
                </p>
                <p style={{ fontSize: '10px', textDecoration: 'line-through' }}>
                  {item.prevprice}
                </p>
              </Box>
            </Box>
          </Box>
        ))}
      </Grid> */}
      {categoryId ? <CategoryDetail /> : <CategoryListing />}
    </Box>
  );
};

const CategoryListing = () => {
  const { push } = useHistory();

  const handleNavigation = (id) => {
    push(`/categories/?categoryId=${id}`);
  };

  const appManager = useApp();
  const metadata = appManager.state.metadata;
  console.log(metadata);

  return (
    <>
      <Box display="flex" alignItems="center" gap="20px">
        <Heading as="h1" fontSize="heading.h3">
          List of course category
        </Heading>
      </Box>

      <Box
        display={"grid"}
        gridTemplateColumns={{lg:"200px 200px 200px 200px", md:"200px 200px 200px 200px", base:"1fr"}}
        gap={10}
        padding={3}
        marginTop="50px"
        marginBottom="100px"
        height="350px"
        overflowX="auto"
      >
        {metadata?.courseCategory?.map((item) => (
          <Tooltip label={item?.categoryName} aria-label="A tooltip">
            <Box
              _hover={{
                backgroundColor: "secondary.base",
                color: "#fff",
              }}
              display={"flex"}
              alignItems={"center"}
              gap={4}
              p={3}
              height={"150px"}
              borderRadius={"md"}
              shadow={"0px 4px 10px rgba(0, 0, 0, 0.25)"}
              border
              cursor="pointer"
              key={item?.id}
              fontWeight={"550"}
              onClick={() => handleNavigation(item?.id)}
            >
              <Avatar name={item?.categoryName} boxSize="70px" />
              <Text> {item?.categoryName}</Text>
            </Box>
          </Tooltip>
        ))}
      </Box>
    </>
  );
};

const CategoryDetail = () => {
  const { myCourses, handleFetchMyCourses } = useMyCourses({});
  const [loading, setLoading] = useState(false);

  const [allCoursesData, setAllCoursesData] = useState();
  const categoryId = useQueryParams().get("categoryId");
  const appManager = useApp();
  const metadata = appManager.state.metadata;
  const catItems = metadata?.courseCategory;
  const fetcher = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await userGetCourseCategory(categoryId);
      setAllCoursesData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [categoryId]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  console.log(allCoursesData);

  return (
    <>
      {allCoursesData?.length === 0 ? (
        <EmptyState text={"Course not available for this category"} />
      ) : (
        <>
          {loading ? (
            <Center height="100%">
              <Spinner />
            </Center>
          ) : (
            <Box marginTop="20px" marginBottom="100px">
              <Heading as="h1" fontSize="heading.h3">
                <Text mb={2}>
                  Courses under
                  <span style={{ marginLeft: "6px" }}>
                    {catItems
                      ?.filter((cat) => cat.id === categoryId)
                      .map((cat) => cat.categoryName)}
                  </span>
                </Text>
              </Heading>
              <ScrollCarousel
            gridGap="8"
           
            width={sectionMaxWidth_userPages}
          >
              <Flex
                
                gap={5}
                alignItems="center"
                py={2}
              >
                {allCoursesData?.map((item) => (
                  <Box
                    key={item.id}
                    marginTop="30px"
                    height="293px"
                    width="270px"
                    marginBottom="18px"
                    borderRadius="8px"
                    shadow={"0px 4px 10px rgba(0, 0, 0, 0.25)"}
                    border="2px solid #f5f5f5"
                    background="#fff"
                  >
                    <Box
                      height="139px"
                      width="260px"
                      marginLeft="auto"
                      marginRight="auto"
                      marginTop="5px"
                      position="relative"
                    >
                      <Image
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                        src={item.thumbnailUrl}
                        alt={item.title}
                      />

                      <div
                        style={{
                          background: "#6FC05A",
                          color: "#f5f5f5",
                          fontSize: "12px",
                          paddingTop: "3px",
                          paddingBottom: "3px",
                          paddingRight: "10px",
                          paddingLeft: "10px",
                          borderRadius: "50px",
                          position: "absolute",
                          top: "10px",
                          left: "8px",
                        }}
                      >
                        {catItems
                          ?.filter((cat) => cat.id === categoryId)
                          .map((cat) => truncateText(cat.categoryName, 12))}
                      </div>
                      <Box display="flex" flexDirection="column" gap="3px">
                        <Link href={`/modules/${item?.id}`}>
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              marginTop: "10px",
                            }}
                          >
                            {item.title}
                          </p>
                        </Link>

                        <Link href={`/users/${item?.instructor?.id}`}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginBottom: "5px",
                            }}
                          >
                            {item?.instructor?.firstName +
                              " " +
                              item?.instructor?.lastName}
                          </p>
                        </Link>

                        <p
                          style={{
                            display: "flex",
                            fontSize: "12px",
                            alignItems: "center",
                            gap: "5px",
                            color: "#FFD700",
                          }}
                        >
                          <Rated val={item?.reviews[0]?.reviewerRating} />
                          {item?.reviews[0]?.reviewerRating}.5
                        </p>
                      </Box>
                      <Box
                        display="flex"
                        gap="30px"
                        alignItems="center"
                        marginTop="10px"
                      >
                        <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                          NGN {item.price}
                        </p>
                        <p
                          style={{
                            fontSize: "10px",
                            textDecoration: "line-through",
                          }}
                        >
                          NGN {item.discountedPrice}
                        </p>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Flex>
              </ScrollCarousel>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export const CategoriesPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <Categories {...props} />} />;
};
