import { Flex, Box } from "@chakra-ui/layout";
import { ButtonGroup } from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import { BrandLogo, Button } from "../../../components";
import { useApp } from "../../../contexts";
import NavBar from "./NavBar";

const Sidebar = () => {
  const {
    state: { userScreenSidebarIsOpen },
    handleUserScreenSidebarToggle,
  } = useApp();

  return (
    <>
      <Box
        pos={{ base: "fixed", md: "unset" }}
        top={0}
        left={0}
        zIndex={100}
        bg="rgba(0, 0, 0, .2)"
        w="100vw"
        h="100vh"
        onClick={handleUserScreenSidebarToggle}
        d={userScreenSidebarIsOpen ? "block" : "none"}
      />

      <Flex
        alignItems="center"
        justifyContent="space-between"
        minHeight="60px"
        flexDirection="column"
        bg="secondary.base"
        height="100vh"
        boxShadow="inset -10px 0 10px rgba(0, 0, 0, .08)"
        pos={{ base: "fixed", md: "unset" }}
        top={0}
        left={0}
        zIndex={100}
        // d={userScreenSidebarIsOpen ? "flex" : "none"}
        d={{ base: userScreenSidebarIsOpen ? "flex" : "none", md: "flex" }}
      >
        <Flex py={7} width="fit-content" mt="10px" transform="translateX(10px)">
          <BrandLogo marginRight={{ base: 2, laptop: 12 }} />
        </Flex>

        <ButtonGroup
          top={2}
          right={2}
          pos="absolute"
          size="sm"
          d={{ base: "flex", tablet: "none" }}
        >
          <Button onClick={handleUserScreenSidebarToggle} asIcon>
            <IoMdClose />
          </Button>
        </ButtonGroup>

        <NavBar flex={1} overflowY="auto" />
      </Flex>
    </>
  );
};

export default Sidebar;
