import { Box, Grid } from "@chakra-ui/react";
import { Fragment, useCallback } from "react";
import { Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Dropdown from "./Courses/Dropdown";
import {
  useMyCourses,
  usePurchasedCourses,
  useCompletedCourses,
  useOnGoingCourses,
} from ".";
import {
  Button,
  CourseBoxCard,
  CourseOverviewCard,
  Heading,
  Tabs,
} from "../../components";
import { CourseOverviewCard2 } from "../../components/Cards/CourseOverviewCard2";
import { CardGridLayout, EmptyState } from "../../layouts";
import { pageWrapperSpacing_userPages } from "../../theme/breakpoints";
import { createRange } from "../../utils";
import { userGetBookListing } from "../../services";
import { useFetchAndCache } from "../../hooks";

const MyLibraryPage = () => {
  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Heading as="h1" fontSize="heading.h3" mb={5}>
        My Courses
      </Heading>

      <Tabs
        tabs={[
          {
            id: "all",
            label: "All Courses",
            content: <AllCourses />,
          },
          {
            id: "ongoing",
            label: "Ongoing",
            content: <OngoingCourses />,
          },
          {
            id: "completed",
            label: "Completed",
            content: <CompletedCourses />,
          },
          {
            id: "book",
            label: "Book",
            content: <Book />,
          },
          // {
          //   id: "wishlist",
          //   label: "Wishlist",
          //   content: <WishlistCourses />,
          // },
        ]}
      />
    </Box>
  );
};

const AllCourses = () => {
  const { myCourses, handleFetchMyCourses } = useMyCourses({});
  const [selectedCat, setSelectedCat] = useState({
    id: "",
    name: "All Categories",
  });
  console.log(myCourses?.data);
  const [allCoursesData, setAllCoursesData] = useState([]);

  const courses = myCourses?.data;

  useEffect(() => {
    if (selectedCat.name === "All Categories") {
      setAllCoursesData(courses);
    } else {
      const filteredData = courses?.find(
        (item) => item?.categoryId === selectedCat.id
      );
      setAllCoursesData(filteredData);
    }
  }, [courses, selectedCat]);

  return (
    <>
      <Box>
        <Dropdown selectedCat={selectedCat} setSelectedCat={setSelectedCat} />
      </Box>
      <CourseListingGridLayout
        courses={myCourses.data}
        err={myCourses.err}
        loading={myCourses.loading}
        onErrRetry={handleFetchMyCourses}
      />
    </>
  );
};

const OngoingCourses = () => {
  const { myCourses, handleFetchMyCourses } = useOnGoingCourses({
    ongoing: true,
  });

  return (
    <CourseListingGridLayout2
      courses={myCourses.data}
      err={myCourses.err}
      loading={myCourses.loading}
      onErrRetry={handleFetchMyCourses}
    />
  );
};

const CompletedCourses = () => {
  const { myCourses, handleFetchMyCourses } = useCompletedCourses({
    completed: true,
  });

  return (
    <CourseListingGridLayout
      courses={myCourses.data}
      err={myCourses.err}
      loading={myCourses.loading}
      onErrRetry={handleFetchMyCourses}
    />
  );
};

const Book = () => {
  const useBooks = () => {
    const { resource: books, handleFetchResource } = useFetchAndCache();

    const fetcher = useCallback(async () => {
      const { books } = await userGetBookListing();
      return books;
    }, []);

    useEffect(() => {
      handleFetchResource({ cacheKey: "books", fetcher });
    }, [handleFetchResource, fetcher]);

    return {
      books,
    };
  };

  const { books } = useBooks();

  console.log(books);
  return (
    <>
      <CardGridLayout cardContents={books} />
    </>
  );
};

// const WishlistCourses = () => {
//   const { myCourses, handleFetchMyCourses } = useMyCourses({ wishlist: true });

//   return (
//     <CourseListingGridLayout
//       courses={myCourses.data}
//       err={myCourses.err}
//       loading={myCourses.loading}
//       onErrRetry={handleFetchMyCourses}
//     />
//   );
// };

export const CourseListingGridLayout = ({
  courses,
  err,
  loading,
  onErrRetry,
  renderCard,
}) => (
  <>
    <Grid
      gridTemplateColumns={{
        base: "repeat(auto-fit, minmax(200px, 1fr))",
        tablet: "repeat(200, minmax(200px, 1fr))",
        laptop: "repeat(200, minmax(250px, 1fr))",
      }}
      gridGap={8}
      alignItems="flex-start"
      py={2}
    >
      {courses &&
        courses.map((course, index) => (
          <>
            {renderCard ? (
              <Fragment key={course.id}>{renderCard(course)}</Fragment>
            ) : (
              <CourseOverviewCard
                key={course.id}
                course={course}
                controlledDimensions
              />
            )}
          </>
        ))}

      {loading &&
        createRange(8).map((i) => (
          <CourseOverviewCard key={i} controlledDimensions isLoading />
        ))}
    </Grid>

    {err && (
      <EmptyState
        cta={<Button onClick={onErrRetry}>Try Again</Button>}
        heading="Oops An Error Occurred"
        description="An unexpected error occurred, please try again later"
      />
    )}
  </>
);

export const CourseListingGridLayout2 = ({
  courses,
  err,
  loading,
  onErrRetry,
  renderCard,
}) => (
  <>
    <Grid
      gridTemplateColumns={{
        base: "repeat(auto-fit, minmax(200px, 1fr))",
        tablet: "repeat(200, minmax(200px, 1fr))",
        laptop: "repeat(200, minmax(250px, 1fr))",
      }}
      gridGap={8}
      alignItems="flex-start"
      py={2}
    >
      {courses &&
        courses.map((course) => (
          <>
            {renderCard ? (
              <Fragment key={course.id}>{renderCard(course)}</Fragment>
            ) : (
              <CourseOverviewCard2
                key={course.id}
                course={course}
                controlledDimensions
                layout2
              />
            )}
          </>
        ))}

      {loading &&
        createRange(8).map((i) => (
          <CourseOverviewCard key={i} controlledDimensions isLoading />
        ))}
    </Grid>

    {err && (
      <EmptyState
        cta={<Button onClick={onErrRetry}>Try Again</Button>}
        heading="Oops An Error Occurred"
        description="An unexpected error occurred, please try again later"
      />
    )}
  </>
);

export const MyLibraryPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <MyLibraryPage {...props} />} />;
};
