import { Box, Flex, Text, Grid } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { Route } from "react-router-dom";
import { Button, Heading } from "../../components";
import { useGoBack } from "../../hooks";
import { PageLoaderLayout, EmptyState } from "../../layouts";

import { http } from "../../services";
import cert from "../../assets/images/grooming-cert.png";
import { ReactComponent as NotFound } from "../../assets/images/page-not-found.svg";
import { truncateText } from "../../utils";
import { Certificate } from "../admin/users/CertTemplate/CertificateTemplate";

const MyCertificatesPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [certificates, setCertificates] = useState([]);

  const fetchCertificates = async () => {
    setIsLoading(true);
    await http
      .get("/certificates")
      .then((res) => {
        setCertificates(res?.data?.data);

        setIsLoading(false);
      })
      .catch((err) => {
        setHasError(true);
        setIsLoading(false);
      });
  };
  const handleGoBack = useGoBack();

  useEffect(() => {
    fetchCertificates();
  }, []);

  return (
    <Flex flexDirection={"column"} pt={16} minH={"100vh"} px={10}>
      <Heading mb="20px">Certificates</Heading>
      {isLoading ? (
        <PageLoaderLayout />
      ) : (
        <>
          {certificates.length === 0 ? (
            <EmptyState
              cta={
                <Button
                  leftIcon={<IoArrowBack />}
                  onClick={handleGoBack}
                  paddingY={4}
                >
                  Go Back
                </Button>
              }
              heading="No Certificates Found"
              illustration={<NotFound style={{ paddingBottom: 20 }} />}
            />
          ) : (
            <Grid gridTemplateColumns={"1fr 1fr"} gap={5}>
              {certificates.map((certi) => (
                <div
                  style={{
                    minHeight: "500px",
                    padding: "20px",
                    backgroundColor: "#fff",
                    boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                >
                  <Certificate
                    name={`${certi?.user?.firstName} ${certi?.user?.lastName}`}
                    title={certi?.course?.title}
                  />
                </div>
              ))}
            </Grid>
          )}
        </>
      )}
    </Flex>
  );
};

export const MyCertificatesPageRoute = ({ ...rest }) => {
  return (
    <Route {...rest} render={(props) => <MyCertificatesPage {...props} />} />
  );
};
