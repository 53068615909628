import { useToast } from "@chakra-ui/toast";
import { Flex, Grid, GridItem } from "@chakra-ui/layout";
import { useForm } from "react-hook-form";
import { Route } from "react-router-dom";
import {
  Input,
  Textarea,
  Select,
  Breadcrumb,
  Link,
  Spinner,
  Heading,
} from "../../../components";
import { CreatePageLayout } from "../../../layouts";
import { BreadcrumbItem, Box } from "@chakra-ui/react";
import { useApp, useCache } from "../../../contexts";
import {
  appendFormData,
  capitalizeFirstLetter,
  capitalizeWords,
} from "../../../utils";
import { useHistory, useParams } from "react-router";
import { adminCreateModule, adminEditModule } from "../../../services";
import { useEffect } from "react";
import { useViewModuleInfo } from "..";
import useModule from "../../../hooks/useModule";

const CreateModulePage = ({ metadata: propMetadata }) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const { push } = useHistory();
  const appManager = useApp();
  const metadata = propMetadata || appManager.state.metadata;
  const { isLoading, isError, moduleDetails, moduleId, courseId } = useModule();
  const isEditMode = moduleId && moduleId !== "new";

  const { handleDelete } = useCache();

  const onSubmit = async (data) => {
    console.log(data);
    try {
      data = {
        ...data,
        courseId,
      };

      const body = appendFormData(data);
      // body.appendData("courseId", courseId);

      const { modules, message } = await (isEditMode
        ? adminEditModule(moduleId, body)
        : adminCreateModule(body));

      if (isEditMode) handleDelete(moduleDetails?.id);

      toast({
        description: capitalizeFirstLetter(message),
        position: "top",
        status: "success",
      });

      push(`/admin/courses/details/${courseId}/modules?page=1&limit=10`);
    } catch (error) {
      toast({
        description: capitalizeFirstLetter(error.message),
        position: "top",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (isEditMode && moduleDetails) {
      setValue("name", moduleDetails?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, moduleDetails?.name]);

  useEffect(() => {
    if (isEditMode && moduleDetails) {
      setValue("description", moduleDetails.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, moduleDetails]);
  useEffect(() => {
    if (isEditMode && moduleDetails) {
      setValue("numberOfLessons", moduleDetails?.numberOfLessons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, moduleDetails]);
  // const populateSelectOptions = (data, filterBody = () => true) => {
  //   return data?.filter(filterBody)?.map((item) => ({
  //     label: capitalizeWords(item.name),
  //     value: item.id,
  //   }));
  // };

  const populateSelectOptions = (data, filterBody = () => true) => {
    return data?.filter(filterBody)?.map((item) => ({
      label: capitalizeWords(item.categoryName),
      value: item.id,
    }));
  };

  return isLoading || isError ? (
    <Flex
      // Make the height 100% of the screen minus the `height` of the Header and Footer
      height="calc(100vh - 200px)"
      justifyContent="center"
      alignItems="center"
    >
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Heading color="red.500">{isError}</Heading>
      ) : null}
    </Flex>
  ) : (
    <>
      <Box paddingLeft={6}>
        <Breadcrumb
          item2={
            <BreadcrumbItem>
              <Link href="/admin/courses">Courses</Link>
            </BreadcrumbItem>
          }
          item3={
            <BreadcrumbItem isCurrentPage>
              <Link href={`/admin/courses/details/${courseId}/modules`}>
                Modules
              </Link>
            </BreadcrumbItem>
          }
          item4={
            <BreadcrumbItem isCurrentPage>
              <Link href="#">{isEditMode ? "Edit" : "Create Modules"}</Link>
            </BreadcrumbItem>
          }
        />
      </Box>

      <CreatePageLayout
        title={isEditMode ? "Edit Module" : "Create Module"}
        submitButtonText={isEditMode ? "Update Module" : "Submit"}
        onSubmit={handleSubmit(onSubmit)}
        submitButtonIsLoading={isSubmitting}
      >
        {/* <Grid templateColumns="repeat(2, 1fr)" gap={10} marginBottom={10}> */}
        {/* Row 1 */}
        <Grid>
          <Input
            label="Module title"
            isRequired
            id="name"
            {...register("name", {
              required: "Title is required",
            })}
            error={errors.title?.message}
          />
        </Grid>
        {/* <GridItem>
            <Select
              isRequired
              label="Select CourseCatergory"
                options={populateSelectOptions(metadata?.courseCategory)}
                id="courseId"
                isLoading={!metadata?.courseCategory}
              {...register("departmentId", {
                required: "Please select a department",
              })}
              error={errors.departmentId?.message}
            />
          </GridItem> */}
        {/* </Grid> */}
        {/* Row 2 */}

        <Grid>
          <Input
            label="Number of lessons"
            isRequired
            id="numberOfLessons"
            {...register("numberOfLessons", {
              required: "numberOfLessons is required",
            })}
            error={errors.numberOfLessons?.message}
          />
        </Grid>
        <Grid marginBottom={10}>
          <Textarea
            minHeight="150px"
            label="Module description"
            id="description"
            isRequired
            {...register("description", {
              required: "Please add a description",
              maxLength: 1000,
            })}
            error={
              errors.description?.type === "maxLength"
                ? "Maximum length of 1000 characters"
                : errors.description?.message
            }
          />
        </Grid>
      </CreatePageLayout>
    </>
  );
};

export const CreateModulePageRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => <CreateModulePage {...props} />} />
  );
};
