import { http } from "../http";

/**
 * Endpoint for assessment listing
 * @param {object} params
 *
 * @returns {Promise<{ assessments: Array<{ id: string, name: string, createdAt: Date, noOfUsers: number }> }>}
 */
export const adminGetDepartmentListing = async (params) => {
  const path = `/category`;

  var {
    data: { data },
  } = await http.get(path, { params });

  data = data.filter((row) => row.categoryName !== "General");
  return {
    departments: data.map((department) => ({
      id: department.id,
      name: department.categoryName,
      active: department.active,
      createdAt: department.createdAt,
      updatedAt: department.updatedAt,
      noOfusers: department.categoryCourseLength,
    })),
    showingDocumentsCount: data.length,
    totalDocumentsCount: data.length,
  };
};

/**
 * Endpoint for department creation
 * @param {{ name: string, departmentId: string, }} body
 * @returns {Promise<{ message: string, department: { id: string } }>}
 */
export const adminCreateDepartments = async (body) => {
  const path = `/category/create`;

  const {
    data: { message, data },
  } = await http.post(path, body);

  const department = { id: data.id };

  return { message, department };
};

/**
 * Endpoint to for admin to create a department
 * @param {object} params
 *
 * @param {{ title: string, departmentId: string}}
 *
 * @returns {Promise<{ message: string, departments: Array<{ id: string, firstName: string, lastName: string, email: string, userRoleId: string,departmentId: string }>}>}
 */
export const adminGetDepartmentUsersListing = async (departmentId, params) => {
  const path = `/department/users/${departmentId}`;

  const {
    data: { message, data },
  } = await http.get(path, { params });

  return {
    message,
    users: data.rows.map((user) => ({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      userRoleId: user.userRoleId,
      departmentId: user.departmentId,
      userRoleName: user.userRole.name,
    })),
    showingDocumentsCount: data.rows.length,
    totalDocumentsCount: data.rows.length,
  };
};
