import { rest } from "msw";
import { getUrl } from "../../../http";
import { handleSuccessResponse } from "../../helpers";
import {
  userForumPublishQuestionRes,
  userForumGetCategoriesRes,
  userForumGetQuestionsRes,
  userForumGetQuestionDetailsRes_questionId_1,
  userForumGetQuestionDetailsRes_questionId_2,
  userForumGetQuestionDetailsRes_questionId_3,
  userForumEditQuestionRes,
  userForumDeleteQuestionRes,
} from "./responses";

const userForumGetQuestions = [
  rest.get(
    // TODO: change `method`
    getUrl("/forum/questionrw"), // TODO: change `path`
    handleSuccessResponse(userForumGetQuestionsRes)
  ),
  rest.get(
    // TODO: change `method`
    getUrl("/forum/question/mine/allrtrr"), // TODO: change `path`
    handleSuccessResponse(userForumGetQuestionsRes)
  ),
];

const userForumGetCategories = rest.get(
  // TODO: change `method`
  getUrl("/forum/categoryryr"), // TODO: change `path`
  handleSuccessResponse(userForumGetCategoriesRes)
);

const userForumPublishQuestion = rest.post(
  // TODO: change `method`
  getUrl("/forum/question/createrwyy"), // TODO: change `path`
  handleSuccessResponse(userForumPublishQuestionRes)
);

const userForumEditQuestion = [
  rest.patch(
    getUrl("/forum/question/questionId_1erwtw"),
    handleSuccessResponse(userForumEditQuestionRes)
  ),
  rest.patch(
    getUrl("/forum/question/questionId_2ewrtw"),
    handleSuccessResponse(userForumEditQuestionRes)
  ),
];
const userForumDeleteQuestion = [
  rest.delete(
    getUrl("/forum/question/questionId_1rqet"),
    handleSuccessResponse(userForumDeleteQuestionRes)
  ),
  rest.delete(
    getUrl("/forum/question/questionId_2wteter"),
    handleSuccessResponse(userForumDeleteQuestionRes)
  ),
];

const userForumGetQuestionDetails = [
  rest.get(
    // TODO: change `method`
    getUrl("/forum/question/questionId_1rwttr"), // TODO: change `path`
    handleSuccessResponse(userForumGetQuestionDetailsRes_questionId_1)
  ),
  rest.get(
    // TODO: change `method`
    getUrl("/forum/question/questionId_2rwhy"), // TODO: change `path`
    handleSuccessResponse(userForumGetQuestionDetailsRes_questionId_2)
  ),
  rest.get(
    // TODO: change `method`
    getUrl("/forum/question/questionId_3reyye"), // TODO: change `path`
    handleSuccessResponse(userForumGetQuestionDetailsRes_questionId_3)
  ),
];

const forumQuestion = [
  userForumGetCategories,
  userForumPublishQuestion,
  ...userForumGetQuestions,
  ...userForumGetQuestionDetails,
  ...userForumEditQuestion,
  ...userForumDeleteQuestion,
];

export default forumQuestion;
