import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useApp } from "../contexts";

export const useRedirectNonAuthUserToSigninPage = () => {
  const appManager = useApp();
  const { replace } = useHistory();

  useEffect(() => {
    if (
      !appManager.isAuthenticated &&
      !window.location.pathname.includes("auth")
    ) {
      replace("/home-preview");
    }
  }, [appManager.isAuthenticated, replace]);
};

const handleRedirectUserToRoleScreen = (appManager, replace) => {
  if (appManager.state.user && appManager.state.metadata) {
    const { userRoleId } = appManager.state.user;
    const role = appManager.getOneMetadata("userRoles", userRoleId);
    console.log(role.name);

    if (!/admin/i.test(role.name)) {
      return replace("/");
    }

    replace("/admin");
  }
};

export const useRedirectAuthUserToRoleScreens = (timeout = 0) => {
  // useRedirectNonAuthUserToSigninPage();

  const appManager = useApp();
  const { replace } = useHistory();

  useEffect(() => {
    const handleRedirect = () => {
      handleRedirectUserToRoleScreen(appManager, replace);
    };

    const timeoutId = setTimeout(handleRedirect, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    appManager,
    appManager.state.user,
    appManager.isAuthenticated,
    appManager.state.metadata,
    replace,
    timeout,
  ]);
};

export const useRedirectNewUserToNewPasswordPage = () => {
  const appManager = useApp();
  const { replace } = useHistory();

  useEffect(() => {
    if (appManager.state.user?.isInviteActive) {
      replace("/auth/new-password");
    }
  }, [appManager.state.user?.isInviteActive, replace]);
};

export const useBlockAuthenticatedUserFromPage = () => {
  const { replace } = useHistory();

  const appManager = useApp();

  useEffect(() => {
    if (appManager.isAuthenticated) {
      handleRedirectUserToRoleScreen(appManager, replace);
    }
  }, [
    replace,
    appManager,
    appManager.isAuthenticated,
    appManager.state.user,
    appManager.state.metadata,
  ]);
};
