import { Box, BreadcrumbItem } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { useForm } from "react-hook-form";
import { Route, useHistory, useParams } from "react-router";
import { Breadcrumb, Button, Input, Link } from "../../../components";
import { AdminMainAreaWrapper } from "../../../layouts/admin/MainArea/Wrapper";
import { adminCreateDepartments } from "../../../services";
import { appendFormData, capitalizeFirstLetter } from "../../../utils";

const CreateDepartmentPage = () => {
  const { id: departmentId } = useParams();
  const toast = useToast();

  const { push } = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      data = {
        ...data,
        departmentId,
      };
      var newdata = {
        title: data.title,
      };

      const body = appendFormData(newdata);

      const { message } = await adminCreateDepartments(body);

      toast({
        description: capitalizeFirstLetter(message),
        position: "top",
        status: "success",
      });

      push(`/admin/category`);
    } catch (error) {
      toast({
        description: capitalizeFirstLetter(error.message),
        position: "top",
        status: "error",
      });
    }
  };

  return (
    <AdminMainAreaWrapper>
      <Box paddingLeft={6}>
        <Breadcrumb
          item2={
            <BreadcrumbItem>
              <Link href="/admin/categories">Categories</Link>
            </BreadcrumbItem>
          }
          item3={
            <BreadcrumbItem isCurrentPage>
              <Link href="#">Create</Link>
            </BreadcrumbItem>
          }
        />
      </Box>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Box marginX={6} marginY={10} padding={10} backgroundColor="white">
          <Input
            label="Title"
            id="title"
            width="50%"
            {...register("title", {
              required: "Title is required",
            })}
            error={errors.name?.message}
          />
          <Button marginTop={10} isLoading={isSubmitting} type="submit">
            Create Course Category
          </Button>
        </Box>
      </Box>
    </AdminMainAreaWrapper>
  );
};

export const CreateDepartmentPageRoute = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route {...rest} render={(props) => <CreateDepartmentPage {...props} />} />
  );
};
