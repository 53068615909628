import { Avatar, Box, Flex, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { EmptyState } from "../../../layouts";

import { formatPriceToNaira } from "../../../utils";
import { Button, Heading } from "../../../components";
import { FiShoppingCart } from "react-icons/fi";
import { BiBookOpen, BiPlay } from "react-icons/bi";
import {LibrayDetails} from "./components/LibraryDetails"
import { useState } from "react";

export const Library = ({ data, isLoading, buttonTexts, toggleCartItem }) => {
  const [course, setCourse]=useState({})
  const { onClose, isOpen, onOpen } = useDisclosure();
  return (
    <div>
      <Box id="courses" mt={12}>
        {isLoading ? (
          <Box
            h={"300px"}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner />
          </Box>
        ) : !data?.rows || data?.rows?.length === 0 ? (
          <EmptyState
            heading={"No course found"}
            description={
              !data?.rows
                ? "Please check your internet connection or come back later"
                : "No library material found, please come back later"
            }
          />
        ) : (
          <Box>
            <Grid
              justifyContent={"center"}
              templateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr 1fr",
              }}
              gap={10}
            >
              {data?.rows?.map((course, i) => (
                <Box
                  cursor="pointer"
                  key={i}
                  minH="250px"
                  shadow="lg"
                  p={6}
                  onClick={()=>{
                    setCourse(course)
                    onOpen()
                  }}
                  rounded={"lg"}
                >
                  <Flex alignItems={"center"} gap={3}>
                    <Avatar
                      bgColor={"#800020"}
                      name={`${course?.resourceUploader?.firstName} ${course?.resourceUploader?.lastName}`}
                    />
                    <Text>{`${course?.resourceUploader?.firstName} ${course?.resourceUploader?.lastName}`}</Text>
                  </Flex>
                  <Heading textAlign="start" mt={4} size={"sm"}>
                    {course?.title}
                  </Heading>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={6}
                  >
                    <Text textAlign={"start"} mt={2}>
                      {formatPriceToNaira(course?.price)}
                    </Text>
                    <Button
                      display="flex"
                      disabled
                      mt={2}
                      justifyContent="start"
                      secondary
                      sm
                      leftIcon={
                        course?.fileExtension === "pdf" ? (
                          <BiBookOpen />
                        ) : course?.fileExtension === "mpeg" ? (
                          <BiPlay />
                        ) : (
                          <BiPlay />
                        )
                      }
                    >
                      {course?.fileExtension === "pdf"
                        ? "Read"
                        : course?.fileExtension === "mpeg"
                        ? "Listen"
                        : "Watch"}
                    </Button>
                  </Flex>
                  <Box>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCartItem(course);
                      }}
                      display="flex"
                      mt={6}
                      justifyContent="start"
                      secondary
                      sm
                      leftIcon={<FiShoppingCart />}
                    >
                      {buttonTexts[course?.id] || "Add to cart"}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Box>
        )}
                <Modal
          blockScrollOnMount={false}
          isOpen={isOpen}
          onClose={onClose}
          size={"full"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton/>
            <ModalBody mt={5} height={"600px"}>
              <LibrayDetails 
              buttonTexts={buttonTexts}
                toggleCartItem={toggleCartItem}
                library={course}/>


            </ModalBody>

            <ModalFooter>
              <Button secondary mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </div>
  );
};
