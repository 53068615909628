import { Box, Flex } from "@chakra-ui/layout";
import { IoArrowBack } from "react-icons/io5";
import { useParams } from "react-router";
import { Button, Link, Text } from "../../../../../components";
import { useQueryParams } from "../../../../../hooks";
import colors from "../../../../../theme/colors";
import { useHistory } from "react-router-dom";
import { adminGetAssessmentListing, adminGetExaminationListing } from "../../../../../services";
import { useEffect } from "react";
import { useState } from "react";
const links = [
  {
    matcher: (courseId, assessmentId, quizId) =>
      `/admin/courses/${courseId}/assessment/${assessmentId}/${quizId}/overview`,
    href: (courseId, assessmentId,quizId) =>
      `/admin/courses/${courseId}/assessment/${assessmentId}/${quizId}/overview/new`,
    text: "Overview",
  },
  {
    matcher: (courseId, assessmentId, quizId) =>
      `/admin/courses/${courseId}/assessment/${assessmentId}/${quizId}/questions`,
    href: (courseId, assessmentId, quizId) =>
      `/admin/courses/${courseId}/assessment/${assessmentId}/${quizId}/questions/new`,
    text: "Questions",
  },
];

const Header = () => {
  const { id: courseId, assessmentId } = useParams();
  const [quizId, setQuizId]=useState("")
      
  const examinationId = useQueryParams().get("examination");

  const isExamination = examinationId;
  const fetcher = async () => {
    const { data } = await adminGetAssessmentListing(assessmentId);
    setQuizId(data.id);
  };
  useEffect(() => {
    fetcher();
  }, []);
  const isActiveLink = (LinkMatcher) =>
    window.location.pathname.includes(LinkMatcher);
  const history = useHistory();
  return (
    <Flex
      alignItems="center"
      as="header"
      backgroundColor="white"
      shadow="0 2px 2px rgba(0, 0, 0, .05)"
      position="relative"
      zIndex={1}
      paddingX={12}
      paddingY={2}
    >
      <nav
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {/** Empty box */}
        <Box></Box>
        <Flex as="ul" listStyleType="none">
          {links.map((link) => (
            <li key={link.text}>
              <Link
                href={link.href(courseId, assessmentId, quizId)}
                style={{
                  color: isActiveLink(link.matcher(courseId, assessmentId, quizId))
                    ? colors.black
                    : colors.accent[2],
                  display: "block",
                }}
                disabled={assessmentId === "new"}
              >
                <Text paddingX={3}>{link.text}</Text>
              </Link>
            </li>
          ))}
        </Flex>
        <Flex justifyContent="end" width="180px">
          <p onClick={() => history.goBack()}>
            <Button width="100%" leftIcon={<IoArrowBack />}>
              {isExamination ? "Examination" : "Quiz"}
            </Button>
          </p>
        </Flex>
      </nav>
    </Flex>
  );
};

export default Header;
