import {
  Avatar,
  Box,
  Flex,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { EmptyState } from "../../../layouts";
import { formatPriceToNaira, truncateText } from "../../../utils";
import { Button } from "../../../components";
import { FiShoppingCart } from "react-icons/fi";
import { EventDetails } from "./components/EventDetails";
import { useState } from "react";

export const Events = ({ data, isLoading, buttonTexts, toggleCartItem }) => {
  const [event, setEvent] = useState({});
  const { onClose, isOpen, onOpen } = useDisclosure();
  return (
    <div>
      <Box id="courses" mt={12}>
        {isLoading ? (
          <Box
            minH={"250px"}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner />
          </Box>
        ) : !data || data?.length === 0 ? (
          <EmptyState
            heading={"No Event found"}
            description={
              !data
                ? "Please check your internet connection or come back later"
                : "No event found, please come back later"
            }
          />
        ) : (
          <Box>
            <Grid
              justifyContent={"center"}
              templateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr 1fr",
              }}
              gap={10}
            >
              {data?.map((course, i) => (
                <Box
                  cursor="pointer"
                  key={i}
                  onClick={() => {
                    setEvent(course);
                    onOpen();
                  }}
                  minH="350px"
                  shadow="lg"
                  rounded={"lg"}
                >
                  <Image
                    w={"full"}
                    loading="lazy"
                    h="150px"
                    objectFit={"cover"}
                    alt={`course-thumbnail-${i}`}
                    src={course?.coverImage}
                  />
                  <Box px={4} py={2}>
                    <Text fontWeight="bold" fontSize="16px" textAlign="start">
                      {truncateText(course?.title, 30)}
                    </Text>
                    <Flex alignItems={"center"} gap={"10px"} mt={1}>
                      <Text color="gray" textAlign="start">
                        {formatPriceToNaira(Number(course?.price))}
                      </Text>
                    </Flex>
                    <Text mt={1} textAlign="start">
                      {course?.location}
                    </Text>
                    {new Date() > new Date(event?.endTime) ? (
                      <Text color={"gray"} mt={1} textAlign="start">
                         Registration ended on {new Date(course?.startTime).toLocaleString()}
                      </Text>
                    ) : (
                      <Text mt={1} textAlign="start">
                        Registration deadline: Registration ends{" "}
                        {new Date(course?.startTime).toLocaleString()}
                      </Text>
                    )}

                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCartItem(course);
                      }}
                      display="flex"
                      mt={2}
                      justifyContent="start"
                      secondary
                      sm
                      leftIcon={<FiShoppingCart />}
                    >
                      {buttonTexts[course.id] || "Add to cart"}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Box>
        )}
        <Modal
          blockScrollOnMount={false}
          isOpen={isOpen}
          onClose={onClose}
          size={"full"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody mt={5} height={"600px"}>
              <EventDetails
                buttonTexts={buttonTexts}
                toggleCartItem={toggleCartItem}
                event={event}
              />
            </ModalBody>

            <ModalFooter>
              <Button secondary mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </div>
  );
};
