import { useEffect, useState } from "react";
import { landingGetCategoryListing } from "../../../../services";

export const useCategories=()=>{
    const [categories, setCategory] = useState([]);
    const [isLoadingCategories, setIsLoading] = useState(true);
    const fetchCategories = async () => {
        try {
          const data = await landingGetCategoryListing();
          setIsLoading(false);
          setCategory(data?.data);
        } catch {
          setIsLoading(false);
        }
      };
      useEffect(()=>{
        fetchCategories()
      },[])
      return {isLoadingCategories, categories}
}