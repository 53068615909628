import { Flex } from "@chakra-ui/layout";
import PropTypes from "prop-types";
import smLogo from "../../assets/images/main.png";
import lgLogo from "../../assets/images/main.png";
import { Image, Link } from "..";

export const Brand = ({ sm, xs, lg, href="/" }) => {
  return (
    <Link href={href}>
      <Flex justifyContent={"center"} alignItems="center">
        {/* <Logo sm={sm} lg={lg} xs={xs} withLongLogo /> */}
        <Logo sm={sm} lg={lg} xs={xs} />
      </Flex>
    </Link>
  );
};

export const BrandLogo = ({ sm, lg, href="/", ...rest }) => {
  return (
    <Link href={href}>
      <Logo sm={sm} lg={lg} {...rest} />
    </Link>
  );
};

const Logo = ({ sm, lg, xs, withLongLogo, ...rest }) => {
  return (
    <Image
      src={withLongLogo ? lgLogo : smLogo}
      w={
        withLongLogo
          ? "170px"
          : xs
          ? "32px"
          : sm
          ? "100px"
          : lg
          ? "150px"
          : "90px"
      }
      h={""}
      {...rest}
    />
  );
};

BrandLogo.propTypes = {
  sm: PropTypes.bool,
};
Brand.propTypes = {
  sm: PropTypes.bool,
  textColor: PropTypes.string,
};
