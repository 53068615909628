import { http } from "../http";

export const CreateReiews = async (body) => {
    const path = `/review/create`;
  
    const {
      data: { message, data },
    } = await http.post(path, body);
  
    const reviews = {
     
      courseId: data.courseId,
      reviewerComment: data.reviewerComment,
      reviewerRating: data.reviewerRating,
    };
    return { message, reviews };
  };
  export const GetReviews= async (courseId) => {
    const path = `/review/course/${courseId}`;
  
    const {
      data: { data },
    } = await http.get(path);
  
    return { data };
  };
   