import { Route } from 'react-router-dom';
import Comments from '../Comments/Comments';
import useYourAnswersPage from './hooks/useYourAnswersPage';
import AnswersList from './hooks/AnswersList';

const YourAnswersPage = () => {
  const { commentsManager } = useYourAnswersPage();
  console.log(commentsManager);

  return (
    <Comments commentsManager={commentsManager}>
      {({
        comments,
        handleAddReply,
        handleDeleteComment,
        handleEditComment,
        handleDeleteReply,
        handleEditReply,
        handleCommentExpression,
        deleteStatusIsLoading,
        expStatusIsLoading,
      }) => (
        <>
          <AnswersList
            data={comments.data}
            deleteStatusIsLoading={deleteStatusIsLoading}
            expStatusIsLoading={expStatusIsLoading}
            commentCardHandlers={{
              onCommentEditSuccess: handleEditComment,
              onCommentDelete: handleDeleteComment,
              onReplySuccess: handleAddReply,
              onReplyDeleteSuccess: handleDeleteReply,
              onReplyEditSuccess: handleEditReply,
              onCommentExpression: handleCommentExpression,
            }}
            replyCardHandlers={{
              onReplyDeleteSuccess: handleDeleteReply,
              onReplyEditSuccess: handleEditReply,
            }}
          />
        </>
      )}
    </Comments>
  );
};

export const YourAnswersPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <YourAnswersPage {...props} />} />;
};
