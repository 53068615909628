export const adminGetModuleListingRes_courseId_1 = {
  data: {
    rows: [
      {
        id: "moduleId_1",
        courseId: "courseId_1",
        title: "Introduction to GO",
        description: "Introduction to GO Concept",

        user: {
          firstName: "Shalom",
          lastName: "Brain",
        },
        active: true,
      },
    ],
  },
};

export const adminGetModuleListingRes_courseId_3 = {
  data: {
    rows: [
      {
        id: "moduleId_2",
        courseId: "courseId_3",
        title: "Advance GO",
        description: "Advance Go Concept",
        user: {
          firstName: "Shalom",
          lastName: "Brain",
        },
        active: false,
      },
    ],
  },
};

export const adminRequestModuleDetailsRes_moduleId_1 = {
  data: {
    id: "moduleId_1",
    title: "Module One",
    content: "Go Design Patterns",
    file: "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4 ",
    courseId: "courseId_1",
    departmentId: "departmentId_1",
    active: true,
  },
};

export const adminRequestModuleDetailsRes_moduleId_2 = {
  data: {
    id: "moduleId_2",
    title: "Module Two",
    content: "React Design Patterns",
    file: "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4 ",
    startTime: "2021-010-18 04:25 PM",
    endTime: "2021-12-21T12:25:00.000Z",
    courseId: "courseId_3",
    departmentId: "departmentId_2",
    active: true,
  },
};

export const adminDeleteModuleRes = {
  message: "deleted successfully",
};

export const adminCreateModuleRes = {
  message: "Module created successfully",
  data: {
    id: "moduleId_1",
  },
};

export const adminEditModuleRes_moduleId_1 = {
  message: "Module updated successfully",
  data: [
    {
      id: "moduleId_1",
    },
  ],
};

export const adminEditModuleRes_moduleId_2 = {
  message: "Module updated successfully",
  data: [
    {
      id: "moduleId_2",
    },
  ],
};
