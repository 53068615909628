import { Box, Stack } from "@chakra-ui/layout";
import { Route } from "react-router-dom";
import { Heading, Image, Link, Text } from "../../../components";
import coverImagePlaceholder from "../../../assets/images/events-banner.svg";
import { pageWrapperSpacing_userPages } from "../../../theme/breakpoints";
import { useEffect } from "react";
import { useState } from "react";
import {
  adminGetAssessmentListing,
  adminGetExaminationListing,
} from "../../../services";
import { useHistory } from "react-router-dom";
const QuizzesPage = () => {
  const [allPolls, setAllPolls] = useState("");
  const [courseId, setCourseId] = useState("");
  const fetcher = async () => {
    // 		{
    //     "id": "ebf0eb0a-4364-432c-803c-2a4b488c8c44",
    //     "title": "Dericks Quiz",
    //     "courseId": "bfd81505-f52f-45c4-9b45-e9112a4b738a",
    //     "duration": 1000,
    //     "startTime": "2022-09-07T01:36:00.000Z"
    // }
    if (localStorage.getItem("moduleId")) {
      setCourseId(localStorage.getItem("moduleId"));
      const assessments = await adminGetExaminationListing(
        localStorage.getItem("moduleId")
      );

      setAllPolls(assessments);
    }
  };
  // let count = 0;
  // let history = useHistory();
  useEffect(() => {
    fetcher();
  }, []);
  console.log(allPolls);
  return (
    <Box>
      <Box
        as="section"
        padding={10}
        marginBottom={10}
        color="white"
        position="relative"
      >
        <Image
          src={coverImagePlaceholder}
          width="100%"
          height="100%"
          top={0}
          left={0}
          position="absolute"
          alt="Course Header"
        />

        <Stack
          spacing={7}
          position="relative"
          // zIndex={1}
          {...pageWrapperSpacing_userPages}
        >
          <Heading>Quiz for module </Heading>
          <Text as="level2">Test your skills</Text>
        </Stack>
      </Box>
      <Box padding={'1rem'}>
        {allPolls &&
          allPolls.examinations.map((exam) => (
            <div key={exam.id}>
              <Link href={`/courses/take/${courseId}/assessment/${courseId}`}>
                <Text
                  _hover={{
                    textDecoration: "underline",
                  }}
                  as="level1"
                  bold
                  mb={{ base: 1, tablet: 2 }}
                >
                  {exam?.title}
                </Text>
              </Link>
            </div>
          ))}
      </Box>
    </Box>
  );
};

export const QuizzesPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <QuizzesPage {...props} />} />;
};
