import { Grid, GridItem } from "@chakra-ui/layout";
import { Fragment } from "react";
import { CourseBoxCard, Button } from "../../../components";
import { EmptyState } from "../../../layouts";

export const CardGridLayout = ({ cardContents, onRetry, renderCard, IsPdf, IsAudio, IsVideo }) => {
  const cardContentsIsEmpty =
    !cardContents.loading &&
    !cardContents.err &&
    cardContents.data &&
    !cardContents.data?.length;

  

  return (
    <>
      {cardContentsIsEmpty && (
        <EmptyState
          width="100"
          cta={<Button link="/home">Return to dashboard</Button>}
          heading={`No ${
            IsVideo ? "Videos" : IsAudio ? "Audio" : IsPdf ? "Books" : "Courses"
          } Available`}
          description={`${
            IsVideo
              ? "There are no videos in your library"
              : IsPdf
              ? "There are no books in your library"
              : IsAudio
              ? "There are no audio files in your library"
              : "Your department have no assigned courses just yet"
          }`}
        />
      )}

      <Grid
        className="card-grid-layout"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md:"repeat(2,1fr)",
          lg: "repeat(4, 1fr)",
        }}
        alignItems={"center"}
        overflowX={{
          base: "hidden",
          "mobile-m": "auto",
          "mobile-l": "hidden",
        }}
        overflowY="hidden"
        columnGap={{ base: "40px", laptop: "60px" }}
        rowGap={{ base: "40px", laptop: "50px" }}
        padding={1}
      >
        {cardContents.err && (
          <GridItem
            colSpan={6}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <EmptyState
              cta={onRetry && <Button onClick={onRetry}>Try Again</Button>}
              heading="Oops An Error Occurred"
              description="An unexpected error occurred, please try again later"
            />
          </GridItem>
        )}

        {cardContents.loading &&
          Array(8)
            .fill("")
            .map((_, index) => <CourseBoxCard key={index} isLoading />)}

        {cardContents.data?.map((cardContent, index) =>
          renderCard ? (
            <Fragment key={index}>{renderCard(cardContent)}</Fragment>
          ) : (
            <CourseBoxCard
            IsPdf={IsPdf}
            IsAudio={IsAudio}
            IsVideo={IsVideo}
              key={index}
              {...cardContent}
              isLoading={cardContent.loading}
            />
          )
        )}
      </Grid>
    </>
  );
};
