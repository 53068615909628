import { Box, Center, Flex } from "@chakra-ui/react";
import { EmptyState } from "../../Courses/Grades/GradesPage";
import {
  Heading,
  Image,
  Spinner,
  Text,
} from "../../../../components";

export const CategoryDetail = ({ cat, isCourseLoading, myCourses }) => {
  return (
    <>
      {myCourses?.courses?.length === 0 ? (
        <Box mt={"100px"}>
          <EmptyState text={"Course not available for this category"} />
        </Box>
      ) : (
        myCourses?.courses?.length !== 0 && (
          <>
            {isCourseLoading ? (
              <Center height="100%">
                <Spinner />
              </Center>
            ) : (
              <Box marginTop="20px" marginBottom="100px">
                <Heading as="h1" fontSize="heading.h3">
                  <Text mb={2}>
                    Courses under
                    <span style={{ marginLeft: "6px" }}>
                      {cat.categoryName}
                    </span>
                  </Text>
                </Heading>

                <Flex
                  gap={5}
                  alignItems="center"
                  justifyContent={"center"}
                  py={2}
                  flexWrap={"wrap"}
                >
                  {myCourses?.courses?.map((item) => (
                    <Box
                      key={item.id}
                      marginTop="30px"
                      height="293px"
                      width="270px"
                      marginBottom="18px"
                      borderRadius="8px"
                      shadow={"0px 4px 10px rgba(0, 0, 0, 0.25)"}
                      border="2px solid #f5f5f5"
                      background="#fff"
                    >
                      <Box
                        height="139px"
                        width="260px"
                        marginLeft="auto"
                        marginRight="auto"
                        marginTop="5px"
                        position="relative"
                      >
                        <Image
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                          }}
                          src={item.thumbnailUrl}
                          alt={item.title}
                        />

                        <div
                          style={{
                            background: "#6FC05A",
                            color: "#f5f5f5",
                            fontSize: "12px",
                            paddingTop: "3px",
                            paddingBottom: "3px",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            borderRadius: "50px",
                            position: "absolute",
                            top: "10px",
                            left: "8px",
                          }}
                        >
                          {cat.categoryName}
                        </div>
                        <Box display="flex" flexDirection="column" gap="3px">
                          <span href={`/modules/${item?.id}`}>
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginTop: "10px",
                              }}
                            >
                              {item.title}
                            </p>
                          </span>

                          <span href={`/users/${item?.instructor?.id}`}>
                            <p
                              style={{
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {item?.instructor?.firstName +
                                " " +
                                item?.instructor?.lastName}
                            </p>
                          </span>

                          <p
                            style={{
                              display: "flex",
                              fontSize: "12px",
                              alignItems: "center",
                              gap: "5px",
                              color: "#FFD700",
                            }}
                          ></p>
                        </Box>
                        <Box
                          display="flex"
                          gap="30px"
                          alignItems="center"
                          marginTop="10px"
                        >
                          <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                            NGN {item.price}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                              textDecoration: "line-through",
                            }}
                          >
                            NGN {item.discountedPrice}
                          </p>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Flex>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
