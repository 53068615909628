import { ongoingDates } from "../course/responses";

export const requestAssessmentDetailsRes_assessmentId_1 = {
  data: {
    startTime: ongoingDates.startTime,
    id: "assessmentId_1",
    title: "Html assessment (Ongoing)",
    duration: 20,
    amountOfQuestions: 3,
    courseId: "courseId_3",
    // assessmentScoreSheets: [{}],
    assessmentQuestions: [
      {
        id: "questionId_1",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Stye A div","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":12,"length":5,"style":"UNDERLINE"},{"offset":12,"length":5,"style":"BOLD"},{"offset":12,"length":5,"style":"ITALIC"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "93",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "33643 / 2",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "zero",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "null",
            optionIndex: "4",
          },
        ],
      },
      {
        id: "questionId_2",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Stye A div","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":12,"length":5,"style":"UNDERLINE"},{"offset":12,"length":5,"style":"BOLD"},{"offset":12,"length":5,"style":"ITALIC"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "me",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "we",
            optionIndex: "4",
          },
        ],
      },
      {
        id: "questionId_3",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Stye A div","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":12,"length":5,"style":"UNDERLINE"},{"offset":12,"length":5,"style":"BOLD"},{"offset":12,"length":5,"style":"ITALIC"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "asdfghjksdsd",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "qwerty",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "213456",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "zzz",
            optionIndex: "4",
          },
        ],
      },
    ],
  },
};
export const requestAssessmentDetailsRes_assessmentId_2 = {
  data: {
    id: "assessmentId_2",
    title: "CSS assessment",
    duration: 60,
    amountOfQuestions: 70,
    startTime: "2021-10-18T09:50:00.319Z",
    courseId: "courseId_3",
    assessmentQuestions: [
      {
        id: "questionId_1",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Stye A div","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":12,"length":5,"style":"UNDERLINE"},{"offset":12,"length":5,"style":"BOLD"},{"offset":12,"length":5,"style":"ITALIC"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "me",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "we",
            optionIndex: "4",
          },
        ],
      },
      {
        id: "questionId_2",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Create Me","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":7,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "me",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "we",
            optionIndex: "4",
          },
        ],
      },
    ],
  },
};
export const requestAssessmentDetailsRes_assessmentId_3 = {
  data: {
    id: "assessmentId_3",
    title: "CSS assessment",
    duration: 60,
    amountOfQuestions: 70,
    startTime: "2021-10-18T09:50:00.319Z",
    courseId: "courseId_3",
    assessmentQuestions: [
      {
        id: "questionId_1",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Stye A div","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":12,"length":5,"style":"UNDERLINE"},{"offset":12,"length":5,"style":"BOLD"},{"offset":12,"length":5,"style":"ITALIC"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "me",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "we",
            optionIndex: "4",
          },
        ],
      },
      {
        id: "questionId_2",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Create Me","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":7,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "me",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "we",
            optionIndex: "4",
          },
        ],
      },
    ],
  },
};

export const adminRequestAssessmentDetailsRes_assessmentId_1 = {
  data: {
    id: "assessmentId_2",
    title: "CSS assessment",
    duration: 60,
    amountOfQuestions: 70,
    startTime: "2021-10-18T09:50:00.319Z",
    courseId: "courseId_3",
    assessmentQuestions: [
      {
        id: "questionId_1",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Stye A div","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":12,"length":5,"style":"UNDERLINE"},{"offset":12,"length":5,"style":"BOLD"},{"offset":12,"length":5,"style":"ITALIC"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "me",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
            isAnswer: true,
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "we",
            optionIndex: "4",
          },
        ],
      },
      {
        id: "questionId_2",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Create Me","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":7,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "me",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "we",
            optionIndex: "4",
            isAnswer: true,
          },
        ],
      },
    ],
  },
};
export const adminRequestAssessmentDetailsRes_assessmentId_2 = {
  data: {
    id: "assessmentId_2",
    title: "CSS assessment",
    duration: 60,
    amountOfQuestions: 70,
    startTime: "2021-10-18T09:50:00.319Z",
    courseId: "courseId_3",
    assessmentQuestions: [
      {
        id: "questionId_1",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Stye A div","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":12,"length":5,"style":"UNDERLINE"},{"offset":12,"length":5,"style":"BOLD"},{"offset":12,"length":5,"style":"ITALIC"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "meeeeeeeeee!",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
            isAnswer: true,
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "we",
            optionIndex: "4",
          },
        ],
      },
      {
        id: "questionId_2",
        question: `{"blocks":[{"key":"bd4p2","text":"How to Create Me","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":7,"length":9,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}`,
        options: [
          {
            id: "optionId_1",
            name: "me",
            optionIndex: "1",
          },
          {
            id: "optionId_2",
            name: "you",
            optionIndex: "2",
          },
          {
            id: "optionId_3",
            name: "them",
            optionIndex: "3",
          },
          {
            id: "optionId_4",
            name: "weeeeeeeeeeeeeeeee!",
            optionIndex: "4",
            isAnswer: true,
          },
        ],
      },
    ],
  },
};

export const adminEditAssessmentRes_assessmentId_1 = {
  message: "Assessment updated successfully",
  data: [
    {
      id: "assessmentId_1",
    },
  ],
};
export const adminEditAssessmentRes_assessmentId_2 = {
  message: "Assessment updated successfully",
  data: [
    {
      id: "assessmentId_2",
    },
  ],
};

export const submitAssessmentRes = {
  message: "submit successful",
};

export const adminCreateAssessmentQuestionRes = {
  message: "assessment question created successfully",
};
export const adminEditAssessmentQuestionRes = {
  message: "question updated successfully",
};

export const adminGetAssessmentListingRes_courseId_1 = {
  data: [
    {
      id: "assessmentId_1",
      title: "Html assessment",
      courseId: "courseId_1",
      duration: 90,
      startTime: "2021-010-18 04:20 PM",
    },
  ],
};

export const adminGetAssessmentListingRes_courseId_3 = {
  data: [
    {
      id: "assessmentId_2",
      title: "CSS assessment",
      courseId: "courseId_3",
      duration: 90,
      startTime: "2021-010-18 04:20 PM",
    },
  ],
};

export const adminCreateAssessmentRes = {
  message: "Assessment created successfully",
  data: {
    id: "assessmentId_1",
  },
};
