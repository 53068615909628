import { ButtonGroup, IconButton } from "@chakra-ui/button";
import { Center, Flex, Box } from "@chakra-ui/layout";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { AiFillPlusCircle } from "react-icons/ai";
import { FiSettings, FiMenu } from "react-icons/fi";
import { MdNotificationsActive } from "react-icons/md";
import { Link } from "react-router-dom";
import { Brand, Button, SearchBar } from "../../components";

const Header = ({ open, setOpen }) => {
  return (
    <Flex
      as="header"
      minH="60px"
      paddingX={8}
      backgroundColor="others.3"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        alignItems="center"
        flex={0.8}
        maxWidth="1000px"
        color="white"
        gap={4}
      >
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <Brand sm textColor="white" />
        </div>
        <Box
          cursor="pointer"
          onClick={() => {
            setOpen(!open);
          }}
          display={{
            lg: "none",
            sm: "block",
            md: "block",
            base: "block",
          }}
          marginRight="30px"
        >
          <FiMenu
            style={{
              fontSize: "28px",
            }}
          />
        </Box>
        {/* <SearchBar marginLeft={10} adminLayoutHeaderStyle flex={1} /> */}
      </Flex>

      <ButtonGroup>
        <QuickAccess />

        <Button asIcon ghost reversePrimaryColor largeSize>
          <MdNotificationsActive />
        </Button>

        <Button
          link={`/admin/settings`}
          asIcon
          ghost
          reversePrimaryColor
          largeSize
        >
          <FiSettings />
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

const QuickAccess = () => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        isRound
        backgroundColor="others.3"
        _hover={{ backgroundColor: "others.3" }}
        _active={{ backgroundColor: "others.3" }}
      >
        <Center>
          <AiFillPlusCircle color="white" size="24px" />
        </Center>
      </MenuButton>

      <MenuList position="relative" zIndex={2}>
        <MenuGroup>
          <MenuItem as={Link} to="/admin/departments/create">
            Add Department
          </MenuItem>
          <MenuItem as={Link} to="/admin/users/edit/new">
            Add User
          </MenuItem>
          <MenuItem as={Link} to="/admin/courses/edit/new">
            Add Course
          </MenuItem>

          <MenuItem as={Link} to="/admin/polls/edit/new">
            Add Poll
          </MenuItem>

          <MenuItem as={Link} to="/admin/events/edit/new">
            Add Event
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default Header;
