export const userForumGetTagsRes = {
  data: [
    {
      id: "tagId_1",
      title: "Computer Science",
    },
    {
      id: "tagId_2",
      title: "Computer Engineering",
    },
  ],
};

export const userForumCreateTagRes = {
  data: {
    id: "tagId_3",
    title: "new tag",
  },
};
