import { Box } from "@chakra-ui/layout";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TawkTo from "tawkto-react";
import {
  ForumLayoutRoute,
  // ChatLayoutRoute
  TakeCourseLayoutRoute,
} from "../../layouts";
import { NotFoundPageRoute } from "../../pages/admin";
import {
  CheckoutPageRoute,
  CourseDetailsPageRoute,
  CourseTakePageRoute,
  EventsPageRoutes,
  GeneralCourseDetailsPageRoute,
  HelpPageRoute,
  HomePageRoute,
  LibraryPageRoutes,
  // ForumPageRoute,
  MyCoursesPageRoute,
  ReviewsPageRoute,
  MyCertificatesPageRoute,
  SettingsPageRoute,
  UserInfoPageRoute,
  CategoriesPageRoute,
} from "../../pages/user";
import { ModulesPageRoute } from "../../pages/user/Courses/CourseDetails/ModulesDetail";
import { NewModulesPageRoute } from "../../pages/user/Courses/CourseDetails/NewModulesDetail";
import { PollsPageRoute } from "../../pages/user/PollsPage/PollsPage";
import { PollsVotePageRoute } from "../../pages/user/PollsVotePage/PollsVotePage";
import { QuizzesPageRoute } from "../../pages/user/QuizzesPage/QuizzesPage";
import { mainAreaHeight_userPages } from "../../theme/breakpoints";
import { CreateReviewsPageRoute } from "../../pages/user/CreateReview";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import {
  InstructorPage,
  InstructorPageRoute,
} from "../../pages/user/Instructor/instructor";
import { MyLibraryPageRoute } from "../../pages/user/LibraryList";
import { LandingPageRoute } from "../../pages/user/LandingPage/LandingPage";
import { TestimonialsPageRoute } from "../../pages/user/LandingPage/Testimonials";

const MainArea = () => {
  const location = useLocation();
  const landing = location.pathname === "/home-preview";
  const testimonials=location.pathname==="/home-preview/testimonials"
  useEffect(() => {
    var tawk = new TawkTo("645b7fd56a9aad4bc579eeff", "1h02mmna3");

    tawk.onStatusChange((status) => {});
  }, []);
  return (
    <Box
      as="main"
      height={!landing && !testimonials ? mainAreaHeight_userPages:""}
      bg="accent.05"
      overflowX="hidden"
    >
      <Box
        mb={landing ? 0 : 20}
        width={
          landing
            ? "full"
            : { base: "calc(100vw - 5px)", tablet: "calc(100vw - 240px)" }
        }
        overflowX="hidden"
      >
        <Switch>
          <LandingPageRoute exact path="/home-preview" />
         < TestimonialsPageRoute exact path="/home-preview/testimonials" />
          <HomePageRoute path="/home" />
          <InstructorPageRoute path="/instructor" />
          <CourseTakePageRoute path="/my-courses/:id/take" />
          <MyCoursesPageRoute path="/my-courses" />
          <ReviewsPageRoute path="/courses/:id/reviews" />
          <CourseDetailsPageRoute exact path="/courses/:id" />
          <GeneralCourseDetailsPageRoute path="/general/courses/:id/" />
          <NewModulesPageRoute path="/modules/:id/" />
          <CreateReviewsPageRoute path="/rev/:id" />
          <UserInfoPageRoute path="/users/:id" />
          <MyCertificatesPageRoute path="/my-certificates" />
          <LibraryPageRoutes path="/library" />
          <MyLibraryPageRoute path="/lib" />
          <ForumLayoutRoute path="/forum" />
          <EventsPageRoutes exact path="/events" />
          <PollsPageRoute exact path="/polls" />
          <QuizzesPageRoute exact path="/courses/modules/:id/quizzes" />
          <PollsVotePageRoute exact path="/polls/:pollId/vote" />
          <SettingsPageRoute path="/settings" />
          <CategoriesPageRoute exact path="/categories" />
          <HelpPageRoute path="/help" />
          <CheckoutPageRoute path="/checkout" />
          <Redirect exact from="/" to="/home" />
          <Route render={(props) => <NotFoundPageRoute />} />
        </Switch>
      </Box>
      {<Footer />}
    </Box>
  );
};

export default MainArea;
