import { Box, Grid, GridItem } from "@chakra-ui/layout";
import { Fragment, useCallback } from "react";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import { useFetchAndCache } from "../../../../hooks";
import { EmptyState } from "../../../../layouts";
import { userGetBookListing, userGetVideoListing } from "../../../../services";

import { useAudio } from "./AudioPage";
import { useVideo } from "./VideoPage";
import { Button, CourseBoxCard, Spinner, Text } from "../../../../components";

const useBooks = () => {
  const { resource: books, handleFetchResource } = useFetchAndCache();

  const fetcher = useCallback(async () => {
    const { books } = await userGetBookListing();
    return books;
  }, []);

  useEffect(() => {
    handleFetchResource({ cacheKey: "books", fetcher });
  }, [handleFetchResource, fetcher]);

  return {
    books,
  };
};
const CardGridLayout = ({
  cardContents,
  onRetry,
  renderCard,
  isPurchased,
  IsAudio,
  IsPdf,
  IsVideo,
}) => {
  const cardContentsIsEmpty =
    !cardContents.loading &&
    !cardContents.err &&
    cardContents.data &&
    !cardContents.data?.length;

  return (
    <>
      {cardContentsIsEmpty && (
        <EmptyState
          width="100"
          cta={<Button link="/home">Return to dashboard</Button>}
          heading={`No ${
            IsVideo ? "Videos" : IsAudio ? "Audio" : IsPdf ? "Books" : "Courses"
          } Available`}
          description={`${
            IsVideo
              ? "There are no videos in your library"
              : IsPdf
              ? "There are no books in your library"
              : IsAudio
              ? "There are no audio files in your library"
              : "Your department have no assigned courses just yet"
          }`}
        />
      )}

      <Grid
        className="card-grid-layout"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2,1fr)",
          lg: "repeat(4, 1fr)",
        }}
        alignItems={"center"}
        overflowX={{
          base: "hidden",
          "mobile-m": "auto",
          "mobile-l": "hidden",
        }}
        overflowY="hidden"
        columnGap={{ base: "40px", laptop: "60px" }}
        rowGap={{ base: "40px", laptop: "50px" }}
        padding={1}
      >
        {cardContents.err && (
          <GridItem
            colSpan={6}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <EmptyState
              cta={onRetry && <Button onClick={onRetry}>Try Again</Button>}
              heading="Oops An Error Occurred"
              description="An unexpected error occurred, please try again later"
            />
          </GridItem>
        )}

        {cardContents.loading &&
          Array(8)
            .fill("")
            .map((_, index) => <CourseBoxCard key={index} isLoading />)}

        {cardContents?.map((cardContent, index) =>
          renderCard ? (
            <Fragment key={index}>{renderCard(cardContent)}</Fragment>
          ) : (
            <CourseBoxCard
              IsAudio={IsAudio}
              IsPdf={IsPdf}
              IsVideo={IsVideo}
              key={index}
              {...cardContent}
              isLoading={cardContent.loading}
            />
          )
        )}
      </Grid>
    </>
  );
};

const PurchasedPage = () => {
  const useVideo = () => {
    const { resource: videos, handleFetchResource } = useFetchAndCache();

    const fetcher = useCallback(async () => {
      const { videos } = await userGetVideoListing();
      return videos;
    }, []);

    useEffect(() => {
      handleFetchResource({ cacheKey: "videos", fetcher });
    }, [handleFetchResource, fetcher]);

    return {
      videos,
    };
  };
  const VideoPage = () => {
    const { videos } = useVideo();
    const main = videos?.data?.filter((dat) => dat.isPurchased === true);
    console.log(main);
    return (
      <div>
        <Text ml={4} my={4} fontWeight="bold" fontSize={"1.3rem"}>
          Purchased videos
        </Text>
        {videos.loading ? (
          <Spinner />
        ) : (
          <Box paddingX={2}>
            {!main || main?.length === 0 ? (
              <EmptyState description={"No purchased video material"} />
            ) : (
              <CardGridLayout IsVideo cardContents={main ?? []} />
            )}
          </Box>
        )}
      </div>
    );
  };
  const BookPage = () => {
    const { books } = useBooks();
    const main = books?.data?.filter((dat) => dat.isPurchased === true);
    console.log(main);

    return (
      <Box paddingX={2}>
        <div>
          <Text ml={4} my={4} fontWeight="bold" fontSize={"1.3rem"}>
            Purchased Books
          </Text>
          {books.loading ? (
            <Spinner />
          ) : (
            <Box paddingX={2}>
              {!main || main?.length === 0 ? (
                <EmptyState description={"No purchased book  material"} />
              ) : (
                <CardGridLayout cardContents={main ?? []} IsPdf />
              )}
            </Box>
          )}
        </div>
      </Box>
    );
  };
  const AudioPage = () => {
    const { audio } = useAudio();
    const main = audio?.data?.filter((dat) => dat.isPurchased === true);
    console.log(main);
    return (
      <div>
        <Text ml={4} my={4} fontWeight="bold" fontSize={"1.3rem"}>
          Purchased Audios
        </Text>
        {audio.loading ? (
          <Spinner />
        ) : (
          <Box paddingX={2}>
            {!main || main?.length === 0 ? (
              <EmptyState description={"No purchased audio material"} />
            ) : (
              <CardGridLayout IsAudio cardContents={main ?? []} />
            )}
          </Box>
        )}
      </div>
    );
  };
  return (
    <Box paddingX={10}>
      <VideoPage />
      <BookPage />
      <AudioPage />
    </Box>
  );
};

const PurchasedPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <PurchasedPage {...props} />} />;
};

export default PurchasedPageRoute;
