import { Avatar as AvatarImage } from "@chakra-ui/avatar";
import { ButtonGroup, IconButton } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/layout";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/menu";
import { FiShoppingCart } from "react-icons/fi";
import { HiOutlineMenu } from "react-icons/hi";
import { MdNotificationsActive } from "react-icons/md";
import { Link } from "react-router-dom";
import { BrandLogo, Button, SearchBar } from "../../../components";
import { useApp } from "../../../contexts";
import { pageWrapperSpacing_userPages } from "../../../theme/breakpoints";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AccountPage } from "../../../pages/admin";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../../utils";
import Badge1 from "../../../assets/images/badge-level-1.png";
import Badge2 from "../../../assets/images/badge-level-2.png";
import Badge3 from "../../../assets/images/badge-level-3.png";
import { useCompletedCourses } from "../../../pages/user";
import { http } from "../../../services";

const Header = () => {
  const {
    handleUserScreenSidebarToggle,
    state: { userScreenSidebarIsOpen },
    isAuthenticated,
    handleGetCart,
  } = useApp();
  const toast = useToast();
  const [badge, setBadge] = useState(null);

  const { myCourses } = useCompletedCourses({
    completed: true,
  });

  const handleBadge = () => {
    if (myCourses.data.length >= 2 && myCourses.data.length < 5) {
      setBadge(Badge1);
    } else if (myCourses.data.length >= 5 && myCourses.data.length < 8) {
      setBadge(Badge2);
    } else if (myCourses.data.length > 8) {
      setBadge(Badge3);
    } else {
      setBadge(null);
    }
  };

  useEffect(() => {
    if (myCourses.data) {
      handleBadge();
    }
    //eslint-disable-next-line
  }, [myCourses.data]);

  const handleGetUserCart = async () => {
    try {
      const res = await handleGetCart();
      toast({
        description: capitalizeFirstLetter(res.message),
        position: "top",
        status: "success",
      });
    } catch (error) {
      // toast({
      // 	description: "Error while fetching cart",
      // 	position: 'top',
      // 	status: 'error',
      // });
      console.log(error);
    }
  };

  useEffect(() => {
    //handleGetUserCart();
    //eslint-disable-next-line
  }, []);
  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const response = async () => {
    setIsLoading(true);
    await http
      .get("/cart")
      .then((res) => {
        setCart(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(true);
      });
  };
  console.log(cart, "cart");
  useEffect(() => {
    response();
  }, []);
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      {...pageWrapperSpacing_userPages}
      minHeight={{ base: "100px", md: "60px" }}
      py={2}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <ButtonGroup size={"sm"} d={{ base: "flex", tablet: "none" }}>
          <BrandLogo marginRight={2} />

          <Button asIcon onClick={handleUserScreenSidebarToggle}>
            <HiOutlineMenu />
          </Button>
        </ButtonGroup>
        <Box flex={1} mt={3}>
          <SearchBar
            width={{ tablet: "300px", laptop: "500px" }}
            display={{ base: "none", tablet: "flex" }}
          />
        </Box>
        {/* <NavBar
          display={{ base: "none", tablet: "flex" }}
          flex={1}
          marginRight={5}
        /> */}

        <ButtonGroup
          size={"sm"}
          pos="relative"
          spacing={{ base: 2, laptop: 5 }}
          d={{ base: "flex", tablet: "none" }}
        >
          <Box pos="relative">
            <Box
              pos="absolute"
              rounded="full"
              w={"20px"}
              h="20px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg={"#000"}
              color="#fff"
              zIndex={"100"}
              top={"-4"}
              fontSize="12px"
              right="0"
            >
              {cart?.cartItems?.length ?? "0"}
            </Box>
            <Button link="/checkout" asIcon>
              <FiShoppingCart />
            </Button>
          </Box>

          <Button asIcon>
            <MdNotificationsActive />
          </Button>
          <Avatar boxSize="32px" />
        </ButtonGroup>
        <ButtonGroup
          spacing={{ base: 2, laptop: 5 }}
          d={{ base: "none", tablet: "flex" }}
        >
          <Box pos="relative">
            <Box
              pos="absolute"
              rounded="full"
              w={"20px"}
              h="20px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg={"#000"}
              color="#fff"
              zIndex={"100"}
              top={"-4"}
              fontSize="12px"
              right="0"
            >
              {cart?.cartItems?.length ?? "0"}
            </Box>
            <Button link="/checkout" asIcon>
              <FiShoppingCart />
            </Button>
          </Box>

          {/* <Button asIcon>
            <MdNotificationsActive />
          </Button> */}

          {isAuthenticated ? (
            <Flex alignItems={"center"}>
              <Avatar />
              {badge && (
                <img
                  src={badge}
                  alt="badge"
                  style={{ marginLeft: "10px" }}
                  width={30}
                  height={30}
                />
              )}
            </Flex>
          ) : (
            <>
              <Button link="/auth/signin">Sign in</Button>

              <Button secondary link="/auth/signup" ml="-10px">
                Register
              </Button>
            </>
          )}
        </ButtonGroup>
      </Flex>

      <SearchBar
        // width={{
        sm
        display={{ base: "flex", tablet: "none" }}
      />
    </Flex>
  );
};

const Avatar = ({ boxSize = "40px" }) => {
  const { handleLogout, state, getOneMetadata } = useApp();

  const isAdmin = () => {
    const role = getOneMetadata("userRoles", state.user.userRoleId);

    if (/admin/i.test(role?.name)) return true;
  };

  return (
    <Menu>
      <MenuButton as={IconButton} isRound>
        <AvatarImage
          name={state.user?.firstName + " " + state.user?.lastName}
          rounded="full"
          boxSize={boxSize}
          src={state.user?.profilePics}
        />
      </MenuButton>
      <MenuList position="relative" zIndex={2}>
        <MenuGroup>
          <AccountMenuItem />
          {/* <MenuItem>My Account</MenuItem> */}
          {/* <MenuItem as={Link} to="/chats">
        
        Messages
          </MenuItem> */}
          <MenuItem as={Link} to="/my-certificates">
            Certificates
          </MenuItem>
          {/* <MenuItem>Examination</MenuItem> */}
          {/* <MenuItem as={Link} to="/courses/grade-overview">
            Grades
          </MenuItem> */}
          {state.user && isAdmin() && (
            <MenuItem as={Link} to="/admin">
              Admin Dashboard
            </MenuItem>
          )}
        </MenuGroup>
        <MenuDivider />
        <MenuItem onClick={handleLogout} color="secondary.6">
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

function AccountMenuItem() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <MenuItem onClick={onOpen}> My Account</MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>My Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AccountPage onCallToActionClick={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Header;
