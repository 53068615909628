import { Box, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { Route } from "react-router-dom";
import { Heading, ScrollCarousel, Tabs, Text } from "../../../components";
import { Header } from "./components/Header";
import banner from "../../../assets/images/study.jpg";
import { useEffect, useState } from "react";
import { userGetEventPreview, userGetLibraryPreview } from "../../../services";
import { capitalizeFirstLetter } from "../../../utils";
import { TestimonialCard } from "./components/TestimonialCard";
import { sectionMaxWidth_userPages } from "../../../theme/breakpoints";
import amara from "../../../assets/images/amara.jpg";
import man1 from "../../../assets/images/man1.jpg";
import man2 from "../../../assets/images/man2.jpg";
import man3 from "../../../assets/images/man3.jpg";
import { Courses } from "./Courses";
import { Library } from "./Library";
import { Events } from "./Events";
import { FeaturedCourses } from "./FeaturedCourses";
import { Categories } from "./Categories";
import { useCategories } from "./hooks/useCategories";
import { useCourses } from "./hooks/useCourses";
export const testimonial = [
  {
    name: "Ekemini Johnson",
    img: man1,
    quote:
      "Crem is fantastic! Easy to use, great courses, and I've learned a lot. Highly recommend!",
  },
  {
    name: "Amarachi Chimezie",
    img: amara,
    quote:
      "Love Crem! User-friendly, engaging content, and it's helped me grow.",
  },
  {
    name: "Opeyemi Joshua",
    img: man2,
    quote:
      "Crem rocks! Quality courses, practical skills, and it's been a game-changer for me.",
  },
  {
    name: "Samuel Adeyemi",
    img: man3,
    quote:
      "Crem is awesome! Top-notch courses, easy to navigate, and I've seen real progress.",
  },
];
const LandingPage = () => {
  const {
    isCourseLoading,
    myCourses,
    page,
    setPage,
    onSearch,
    setValue,
    value,
    setCourseLoading,
    params,
  } = useCourses();
  const [myLibrary, setLibrary] = useState([]);
  const [myEvents, setEvents] = useState([]);
  const { categories, isLoadingCategories } = useCategories();
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [buttonTexts, setButtonTexts] = useState({});
  const [existing, setExisting] = useState([]);
  const { onOpen } = useDisclosure();
  const [label, setLabel] = useState("");
  const toast = useToast();
  const [course, setCourse] = useState({});

  const FetchLibrary = async () => {
    try {
      const data = await userGetLibraryPreview();
      setIsLoading2(false);
      setLibrary(data?.data);
    } catch {
      setIsLoading2(false);
    }
  };

  const FetchEvents = async () => {
    try {
      const data = await userGetEventPreview();
      setIsLoading3(false);
      setEvents(data?.data);
    } catch {
      setIsLoading3(false);
    }
  };
  useEffect(() => {
    FetchLibrary();
  }, []);
  useEffect(() => {
    FetchEvents();
  }, []);
  const toggleCartItem = (course) => {
    const existingCartItems =
      JSON.parse(localStorage.getItem("cartItems")) || [];
    const index = existingCartItems.findIndex((item) => item.id === course.id);
    let newButtonTexts = { ...buttonTexts };

    if (index !== -1) {
      existingCartItems.splice(index, 1);
      newButtonTexts[course.id] = "Add to cart";
      showToast("Item removed successfully");
    } else {
      existingCartItems.push(course);
      newButtonTexts[course.id] = "Remove from cart";
      showToast("Item added successfully");
    }

    localStorage.setItem("cartItems", JSON.stringify(existingCartItems));
    localStorage.setItem("buttonTexts", JSON.stringify(newButtonTexts));
    setButtonTexts(newButtonTexts);
    setExisting(existingCartItems);
  };
  useEffect(() => {
    const storedButtonTexts = JSON.parse(localStorage.getItem("buttonTexts"));
    if (storedButtonTexts) {
      setButtonTexts(storedButtonTexts);
    }
  }, []);
  const showToast = (message) => {
    toast({
      description: capitalizeFirstLetter(message),
      position: "top",
      status: "success",
    });
  };
  const length = JSON.parse(localStorage.getItem("cartItems"));
  function capitalizeEachWord(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  useEffect(() => {
    document.title = "Grooming Centre LMS";
  }, []);
  return (
    <Box>
      <Header
        length={length?.length}
        onSearch={(e) => {
          onSearch(capitalizeEachWord(e?.target.value));
        }}
      />
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        pos="relative"
        zIndex="10"
        mt={20}
        backgroundImage={`url(${banner})`}
        backgroundSize="cover"
        backgroundPosition="center"
        minH="600px"
        gap={6}
        px={5}
      >
        <Heading size="2xl" color="#fff" textAlign="center" lineHeight={"60px"}>
          Explore online courses at
          <Box
            as="br"
            display={{ lg: "block", sm: "none", md: "none", base: "none" }}
          ></Box>
          <Box as="span" ml={{ lg: "0px", md: "7px", sm: "7px", base: "7px" }}>
            CREM Learning Center today!
          </Box>
        </Heading>
        <Text
          color="#fff"
          w={{
            base: "auto",
            lg: "400px",
            md: "400px",
          }}
          textAlign={"center"}
        >
          Your destination for top-notch online learning. Expert-led courses for
          all skill levels.Whether you're looking to advance your career,
          develop new skills, or pursue personal interests, CREM provides the
          tools and resources you need to succeed.
        </Text>

        <Box
          pos="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          bg={"#00000071"}
          zIndex={"-10"}
        ></Box>
      </Flex>
      <Box
        mt={10}
        px={{
          base: 6,
          md: 6,
          lg: 20,
        }}
        pt={20}
        pb={9}
        as="div"
        textAlign={"center"}
        id="courses"
      >
        <Heading color="#800020" size="lg" mb={6}>
          Learning Materials
        </Heading>
        <Tabs
          tabs={[
            {
              id: "courses",
              label: "Courses",
              content: (
                <div>
                  <FeaturedCourses
                    isLoading2={isLoadingCategories}
                    value={value}
                    setLabel={setLabel}
                    label={label}
                    isLoading={isCourseLoading}
                    category={categories}
                    setValue={setValue}
                    setLoading={setCourseLoading}
                    myCourses={myCourses}
                    onOpen={onOpen}
                    setCourse={setCourse}
                    setPage={setPage}
                    page={page}
                    toggleCartItem={toggleCartItem}
                    buttonTexts={buttonTexts}
                    params={params}
                  />
                  <Courses
                    isLoading2={isLoadingCategories}
                    value={value}
                    setLabel={setLabel}
                    label={label}
                    isLoading={isCourseLoading}
                    category={categories}
                    setValue={setValue}
                    setLoading={setCourseLoading}
                    myCourses={myCourses}
                    onOpen={onOpen}
                    setCourse={setCourse}
                    setPage={setPage}
                    page={page}
                    toggleCartItem={toggleCartItem}
                    buttonTexts={buttonTexts}
                    params={params}
                  />
                </div>
              ),
            },
            {
              id: "library",
              label: "Library materials",
              content: (
                <Library
                  data={myLibrary}
                  isLoading={isLoading2}
                  toggleCartItem={toggleCartItem}
                  buttonTexts={buttonTexts}
                />
              ),
            },
            {
              id: "events",
              label: "Events",
              content: (
                <Events
                  data={myEvents}
                  isLoading={isLoading3}
                  toggleCartItem={toggleCartItem}
                  buttonTexts={buttonTexts}
                />
              ),
            },
            {
              id: "Categories",
              label: "Categories",
              content: <Categories />,
            },
          ]}
        />
      </Box>

      {/* Testimonial */}
      <Box
        px={{
          base: 6,
          md: 6,
          lg: 20,
        }}
        pt={20}
        overflow={"hidden"}
        pb={5}
        mb={10}
      >
        <Heading color="#800020" textAlign="center" mb={"60px"} size="lg">
          What Our Users Say
        </Heading>
        {testimonial && (
          <ScrollCarousel gridGap="6" autoPlay>
            {testimonial.map((item, i) => (
              <TestimonialCard key={i} item={item} />
            ))}
          </ScrollCarousel>
        )}
      </Box>
    </Box>
  );
};

export const LandingPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <LandingPage {...props} />} />;
};
