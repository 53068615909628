import { Box, Flex, Grid, IconButton } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useInView } from "react-intersection-observer";

export const ScrollCarousel = ({ children, autoPlay, ...rest }) => {
  const [status, setStatus] = useState({
    hasReachedEnd: false,
    hasReachedStart: true,
    hasScrollBar: true,
  });

  const scrollRef = useRef(null);

  const handleScrollToLeft = () => {
    if (scrollRef.current) {
      const childWidth = scrollRef.current.children[0]?.clientWidth || 200;
      scrollRef.current.scrollLeft -= childWidth + childWidth / 2;
    }
  };

  const handleScrollToStart = () => {
    if (scrollRef.current) scrollRef.current.scrollLeft = 0;
  };

  const handleScrollToRight = () => {
    if (scrollRef.current) {
      const childWidth = scrollRef.current.children[0]?.clientWidth || 200;
      scrollRef.current.scrollLeft += childWidth + childWidth / 2;
    }
  };

  const { ref: wrapperRef, inView } = useInView({
    threshold: typeof autoPlay === "object" ? autoPlay?.threshold : 0.4,
    delay: typeof autoPlay === "object" ? autoPlay?.delay : 5000,
  });

  const interval = typeof autoPlay === "object" ? autoPlay?.interval : 3800;
  const intervalId = useRef();

  const [stop, setStop] = useState(true);

  useEffect(() => {
    if (autoPlay) {
      if (inView) setStop(false);
      else setStop(true);
    }
  }, [inView, autoPlay]);

  const handleAutoPlay = useCallback(() => {
    if (autoPlay && inView) {
      intervalId.current = setInterval(() => {
        if (status.hasReachedEnd) handleScrollToStart();
        else {
          handleScrollToRight();
        }
      }, interval);
    }
  }, [autoPlay, inView, interval, status.hasReachedEnd]);

  useEffect(() => {
    if (stop) clearInterval(intervalId.current);
    else handleAutoPlay();

    return () => {
      clearInterval(intervalId.current);
    };
  }, [handleAutoPlay, stop]);

  useEffect(() => {
    if (scrollRef.current) {
      for (let i = 0; i < scrollRef.current.children.length; i++) {
        const child = scrollRef.current.children[i];
        child.style.scrollSnapAlign = "center";
        child.style.flexShrink = "0";
      }
    }

    if (scrollRef.current) {
      if (!isOverflown(scrollRef.current))
        setStatus((prev) => ({ ...prev, hasScrollBar: false }));
    }
  }, []);

  const handleScroll = (e) => {
    // detect the beginning of a horizontal scroll in a div?
    if (e.currentTarget.scrollLeft === 0) {
      setStatus((prev) => ({
        ...prev,
        hasReachedStart: true,
      }));
    } else if (e.currentTarget.scrollLeft > 10) {
      setStatus((prev) => ({
        ...prev,
        hasReachedStart: false,
      }));
    }

    // detect the end of a horizontal scroll in a div?
    if (
      e.currentTarget.scrollLeft ===
      e.currentTarget.scrollWidth - e.currentTarget.clientWidth
    ) {
      setStatus((prev) => ({
        ...prev,
        hasReachedEnd: true,
      }));
    } else if (e.currentTarget.scrollLeft < e.currentTarget.scrollWidth - 10) {
      setStatus((prev) => ({
        ...prev,
        hasReachedEnd: false,
      }));

      // detect if there is horizontal scroll in a div?
    }
  };

  return (
    <Box
      ref={wrapperRef}
      pos="relative"
      onMouseEnter={() => setStop(true)}
      onMouseLeave={() => setStop(false)}
    >
      {!status.hasReachedStart && (
        <Grid
          h="100%"
          w={{ base: "80px", md: "100px" }}
          bgGradient="linear(to-l, transparent, rgba(0,0,0,0.5))"
          pos="absolute"
          left={0}
          top={0}
          zIndex={1}
          placeItems={"center"}
          pr={10}
        >
          <IconButton
            aria-label="scroll left"
            onClick={handleScrollToLeft}
            isRound
          >
            <AiOutlineLeft />
          </IconButton>
        </Grid>
      )}

      <Flex
        ref={scrollRef}
        {...rest}
        sx={{
          "&::-webkit-scrollbar": {
            width: "0",
            height: "0",
          },
          "&": {
            overflowX: "scroll",
            scrollSnapType: "x proximity",
            scrollBehavior: "smooth",
          },
        }}
        onScroll={handleScroll}
      >
        {children}
      </Flex>

      {!status.hasReachedEnd && status.hasScrollBar && (
        <Grid
          h="100%"
          w={{ base: "80px", md: "100px" }}
          bgGradient="linear(to-r, transparent, rgba(0,0,0,0.5))"
          pos="absolute"
          right={"-4px"}
          top={0}
          zIndex={1}
          placeItems={"center"}
          pl={10}
        >
          <IconButton
            aria-label="scroll right"
            onClick={handleScrollToRight}
            isRound
          >
            <AiOutlineRight />
          </IconButton>
        </Grid>
      )}
    </Box>
  );
};

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
