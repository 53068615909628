import { Box, Flex, Spinner, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { BsLock } from "react-icons/bs";
import { Route } from "react-router-dom";
import { Button, CourseProductCard, Heading, Text } from "../../components";
import { useGoBack, useQueryParams } from "../../hooks";
import { checkout, enrollUser, http } from "../../services";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  mainAreaHeight_userPages,
  pageWrapperSpacing_userPages,
} from "../../theme/breakpoints";
import { formatPriceToNaira } from "../../utils";
import { BiTrash } from "react-icons/bi";
import { CourseProductCardCart } from "../../components/Cards/CourseProductCardCart";
import { PaystackButton } from "react-paystack";
import { useApp } from "../../contexts";
import { blue } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";

const CheckoutPage = ({ location }) => {
  const handleGoBack = useGoBack();
  const queryParams = useQueryParams();
  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const history = useHistory();

  const appManager = useApp();

  const response = async () => {
    setIsLoading(true);
    await http
      .get("/cart")
      .then((res) => {
        setCart(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    response();
  }, []);

  useEffect(() => {
    if (cart) {
      setAmount(cart.totalPrice);
    }
  }, [cart]);

  console.log(cart);
  const email = appManager?.state.user?.email;
  const name =
    appManager.state.user?.firstName + appManager.state.user?.lastName;
  const [phone, setPhone] = useState("");
  const publicKey = "pk_live_9352154b985ec480e0071bd9e5dbe5deea55b6ee";
  // const publicKey = "pk_test_d8783d339fcbc2f219dbfd23fa5e3026a9f88faa";
  const componentProps = {
    email,
    amount: amount * 100,
    metadata: {
      name,
      phone,
    },
    publicKey,
    color: blue,
    text: "Complete Payment",
    onSuccess: (reference) => {
      checkout(reference, amount);
      history.push("/home");
    },
    onClose: () => alert("Wait! Don't leave :("),
  };
  useEffect(() => {
    const courseParam = queryParams.get("my-courses");
    if (courseParam) {
      // Check if course exists in localStorage and add it to the cart
      const storedCartItems =
        JSON.parse(localStorage.getItem("cartItems")) || [];
      const existingCourse = storedCartItems.find(
        (item) => item.id === courseParam
      );
      if (existingCourse) {
        setCart([...cart, existingCourse]);
      }
      console.log(existingCourse);
    }
  }, [queryParams]);
  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      {isLoading ? (
        <Flex
          // Make the height 100% of the screen minus the `height` of the Header and Footer
          height="calc(100vh - 200px)"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="lg" />
        </Flex>
      ) : (
        // <Center w='40px' h='40px' bg='tomato' color='white'>

        <>
          <Flex>
            <Stack
              mr={4}
              pr={4}
              flex={1}
              spacing={10}
              height={mainAreaHeight_userPages}
              overflowY="scroll"
            >
              <Flex as="section">
                <Button asIcon mr={2} onClick={handleGoBack}>
                  <BiArrowBack />
                </Button>

                <Heading
                  as="h1"
                  fontSize="heading.h3"
                  d="flex"
                  alignItems="center"
                >
                  Checkout
                </Heading>
              </Flex>

              <Flex gridGap="3" flexDirection="column">
                {/* <Stack> */}
                {!isLoading && cart?.length === 0 ? (
                  <>No items in your cart</>
                ) : (
                  cart?.cartItems?.map((item) => (
                    <CourseProductCardCart
                      key={item?.id}
                      course={item}
                      listItem
                      cart={cart}
                      setCart={setCart}
                    />
                  ))
                )}
                {/* </Stack> */}
              </Flex>
            </Stack>

            <Stack
              as="aside"
              w="300px"
              h="fit-content"
              shadow="0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)"
              rounded="md"
              a={5}
              spacing={10}
              px={3}
              py={5}
            >
              <Stack spacing={3}>
                <Text bold as="level2" color="secondary.8">
                  Billing Summary
                </Text>

                <Flex justifyContent="space-between">
                  <Text>Price</Text>
                  <Text>
                    {formatPriceToNaira(cart?.totalPrice ? Number(amount) : 0)}
                  </Text>
                </Flex>
                {/* 
								<Flex justifyContent='space-between'>
									<Text>Discount</Text>
									<Text>
										-
										{formatPriceToNaira(
											cart?.totalPrice
												? Number(cart.totalPrice) - Number(cart?.discountedPrice)
												: 0
										)}
									</Text>
								</Flex> */}

                <Flex justifyContent="space-between">
                  <Text>Amount to be paid</Text>
                  <Text>
                    {formatPriceToNaira(cart?.totalPrice ? Number(amount) : 0)}
                  </Text>
                </Flex>
              </Stack>

              {/* <Stack spacing={3}>
            <Text bold as="level2" color="secondary.8">
              Select Payment Method
            </Text>

            <ButtonGroup>
              <Button secondary leftIcon={<SiMastercard />}>
                Master Card
              </Button>
              <Button secondary leftIcon={<SiVisa />}>
                Visa Card
              </Button>
            </ButtonGroup>
          </Stack> */}

              <Stack spacing={8}>
                <Button link="/checkout" w="100%">
                  <PaystackButton text="Complete Payment" {...componentProps} />
                </Button>

                <Flex
                  justifyContent="center"
                  alignItems="center"
                  color="accent.3"
                >
                  <BsLock />

                  <Text ml={2}>Secure Checkout</Text>
                </Flex>
              </Stack>
            </Stack>
          </Flex>
        </>
      )}
    </Box>
  );
};

export const CheckoutPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <CheckoutPage {...props} />} />;
};
