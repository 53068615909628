import { Route } from "react-router-dom";
import { AdminMainAreaWrapper, EmptyState } from "../../../layouts";
import { useEffect, useState } from "react";
import {useHistory} from "react-router-dom"
import { http } from "../../../services";
import { Box, Flex } from "@chakra-ui/react";
import { Button, Heading,Spinner, Text } from "../../../components";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import CoursesPagination from "../../../components/CoursesPagination/CoursesPagination";
import SearchFilter from "../../../components/SearchBar/SearchFilter";

const TotalCartItem = () => {
  const [data, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage]=useState(1)
  const [value, setValue]=useState("")
  const [isError, setError] = useState(false);
  const limit=10
  const response = async () => {
    setIsLoading(true);
    await http
      .get(`/cart/admin?page=${page}&limit=${limit}&search=${value}`)
      .then((res) => {
        setCart(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    response();
  }, [page,value]);
  const getSerialNumber = (index) => (page - 1) * limit + index + 1;

  const {push}=useHistory()
  return (
    <div>
      <AdminMainAreaWrapper>
        <Flex mt={14} justifyContent={"space-between"} alignItems={"center"}>
          <Heading size={"md"}>Total Cart Item</Heading>

          <Button onClick={()=>{
            push("/admin/")
          }}>Go Back</Button>
        </Flex>
        <SearchFilter value={value} setValue={setValue} placeholder="Search cart item by resource type" w={"400px"} onSearch={response}
        />
        {isLoading ? (
          <Box h="100px" display="flex" justifyItems="center" alignItems="center">
            <Spinner/>
          </Box>
        ) :data?.items?.length===0|| !data?<EmptyState description={"No cart item found check back later"}/>: (
          <Box mt={6} overflow="hidden" overflowX="auto">
            <Table minWidth="full" bg={"#fff"}>
              <Thead>
                <Tr>
                  <Th
                    pl={5}
                    py={3}
                    textAlign="left"
                    fontWeight="bold"
                    color="gray.800"
                    textTransform="uppercase"
                    letterSpacing="wider"
                    fontSize="12px"
                  >
                    S/N
                  </Th>
                  <Th
                    pl={5}
                    py={3}
                    textAlign="left"
                    fontWeight="bold"
                    color="gray.800"
                    textTransform="uppercase"
                    letterSpacing="wider"
                    fontSize="12px"
                  >
                    Resource Type
                  </Th>
                  <Th
                    pl={5}
                    py={3}
                    textAlign="left"
                    fontWeight="bold"
                    color="gray.800"
                    textTransform="uppercase"
                    letterSpacing="wider"
                    fontSize="12px"
                  >
                    Resource ID
                  </Th>
                  <Th
                    pl={5}
                    py={3}
                    textAlign="left"
                    fontWeight="bold"
                    color="gray.800"
                    textTransform="uppercase"
                    letterSpacing="wider"
                    fontSize="12px"
                  >
                    User
                  </Th>
                  <Th
                    pl={5}
                    py={3}
                    textAlign="left"
                    fontWeight="bold"
                    color="gray.800"
                    textTransform="uppercase"
                    letterSpacing="wider"
                    fontSize="12px"
                  >
                    Time updated
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.items?.map((cart, i) => (
                  <Tr key={i}>
                    <Td
                      pl={5}
                      py={6}
                      fontSize="13px"
                      whiteSpace="nowrap"
                      color="#6E6B7B"
                      borderBottom="1px solid #f3ceaa"
                    >
                      <Text fontSize="14px">{getSerialNumber(i)}</Text>
                    </Td>
                    <Td
                      pl={5}
                      py={6}
                      fontSize="13px"
                      whiteSpace="nowrap"
                      color="#6E6B7B"
                      borderBottom="1px solid #f3ceaa"
                    >
                      <Text fontSize="14px">{cart?.resourceType}</Text>
                    </Td>
                    <Td
                      pl={5}
                      py={6}
                      fontSize="13px"
                      whiteSpace="nowrap"
                      color="#6E6B7B"
                      borderBottom="1px solid #f3ceaa"
                    >
                      <Text fontSize="14px">{cart?.resourceId}</Text>
                    </Td>
                    <Td
                      pl={5}
                      py={6}
                      fontSize="13px"
                      whiteSpace="nowrap"
                      color="#6E6B7B"
                      borderBottom="1px solid #f3ceaa"
                      textTransform="capitalize"
                    >
                      <Text fontSize="14px">{`${cart?.user?.firstName} ${cart?.user?.lastName}`}</Text>
                    </Td>

                    <Td
                      pl={5}
                      py={6}
                      fontSize="13px"
                      whiteSpace="nowrap"
                      color="#6E6B7B"
                      borderBottom="1px solid #f3ceaa"
                    >
                      <Text>{new Date(cart?.updatedAt).toLocaleString()}</Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
         <CoursesPagination
              itemsPerPage={limit}
              pageLength={data?.totalItems}
              setCurrentPage={setPage}
              currentPage={page}
              npages={data?.totalPages}
            />
      </AdminMainAreaWrapper>
    </div>
  );
};
export const TotalCartItemRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <TotalCartItem {...props} />} />;
};
