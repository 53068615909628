import { useEffect, useState } from "react";
import { userGetCoursePreview } from "../../../../services";

export const useCourses = () => {
  const [page, setPage] = useState(1);
  const [search, onSearch] = useState(undefined);
  const [myCourses, setCourses] = useState([]);
  const [isCourseLoading, setCourseLoading] = useState(true);
  const [value, setValue] = useState("");
  const params = {
    page: page,
    limit: 8,
    search: search,
    categoryId: value,

  };
  const fetchCourses = async () => {
    try {
      const data = await userGetCoursePreview(params);
      setCourseLoading(false);
      setCourses(data?.data);
    } catch {
      setCourseLoading(false);
    }
  };
  useEffect(() => {
    fetchCourses();
  }, [page, search, value]);
  return { isCourseLoading, myCourses, page, setPage, onSearch, setValue, setCourseLoading, value, params };
};
