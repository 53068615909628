import { rest } from "msw";
import { getUrl } from "../../http";
import { handleSuccessResponse } from "../helpers";
import { userGetCategoryListingRes } from "./responses";

const userGetCategoryListing = rest.get(
  getUrl("/categories"),
  handleSuccessResponse(userGetCategoryListingRes)
);

const category = [userGetCategoryListing];

export default category;
