import { Box, HStack } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { Link } from "../../components";
import { useQueryParams } from "../../hooks";

export const Tabs = ({ tabs }) => {
  let currentTab = useQueryParams().get("tab");
  currentTab = currentTab || tabs[0].id;

  const [activeTab, setActiveTab] = useState(tabs[0].id);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <Box>
      <Box
        overflowX="auto"
        overflowY="hidden"
        mb={5}
        sx={{
          "&::-webkit-scrollbar": {
            width: 0,
            height: 0,
          },
        }}
      >
        <Box
          borderBottom="2px"
          w="fit-content"
          borderColor={{ base: "transparent", md: "gray.200" }}
          spacing={{ base: 3, md: 5 }}
          display="flex"
          flexDirection={{lg:"row", base:"column", sm:"column", md:"column"}}
          alignItems={{lg:"center", base:"start", md:"start", sm:"start"}}
          gap={{lg:12, md:6 ,sm:6 ,base:6}}
        >
          {tabs.map((tab) => (
            <Link key={tab.id} href={`?tab=${tab.id}`}>
              <Box
              textAlign={"start"}
                w="fit"
                transform="translateY(2.5px)"
                cursor="pointer"
                fontWeight="semibold"
                fontSize={{ base: "sm", md: "md" }}
                pb={{ base: 2, md: 3 }}
                borderBottom="3px solid"
                borderColor={
                  activeTab === tab.id ? "primary.base" : "transparent"
                }
                color={activeTab === tab.id ? "black" : "gray.500"}
              >
                {tab.label}
              </Box>
            </Link>
          ))}
        </Box>
      </Box>

      <Box>{tabs?.find((tab) => tab.id === activeTab).content}</Box>
    </Box>
  );
};
