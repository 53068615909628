import {
  Avatar,
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Button, Spinner, Text } from "../../../components";
import { useCategories } from "./hooks/useCategories";
import { CategoryDetail } from "./components/CategoryDetail";
import { useCourses } from "./hooks/useCourses";
import { useState } from "react";

export const Categories = () => {
  const { categories, isLoadingCategories } = useCategories();
  const { setValue, myCourses, isCourseLoading,setCourseLoading} = useCourses();
  const [cat, setCat] = useState({});
  const { onClose, isOpen, onOpen } = useDisclosure();
  return (
    <>
      <Box display="flex" alignItems="center" gap="20px">
        <Text fontSize="20px" mt="20px">
          Course Categories
        </Text>
      </Box>

      {isLoadingCategories ? (
        <Spinner />
      ) : (
        <Box
          display={"grid"}
          gridTemplateColumns={{
            lg: "1fr 1fr 1fr",
            md: "1fr 1fr 1fr",
            base: "1fr",
          }}
          gap={10}
          padding={3}
          marginTop="20px"
          marginBottom="100px"
        >
          {categories?.map((item, i) => (
            <Tooltip key={i} label={item?.categoryName} aria-label="A tooltip">
              <Box
                _hover={{
                  backgroundColor: "secondary.base",
                  color: "#fff",
                }}
                onClick={() =>{
                  setCat(item)
                  setValue(item?.id)
                  onOpen()
                  setCourseLoading(true)
                }}
                display={"flex"}
                alignItems={"center"}
                gap={4}
                p={3}
                height={"150px"}
                borderRadius={"md"}
                shadow={"0px 4px 10px rgba(0, 0, 0, 0.25)"}
                border
                cursor="pointer"
                key={item?.id}
                fontWeight={"550"}
              >
                <Avatar name={item?.categoryName} boxSize="70px" />
                <Text> {item?.categoryName}</Text>
              </Box>
            </Tooltip>
          ))}
        </Box>
      )}
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt={5} height={"600px"}>
            <CategoryDetail
              isCourseLoading={isCourseLoading}
              myCourses={myCourses}
              cat={cat}
            />
          </ModalBody>

          <ModalFooter>
            <Button secondary mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
