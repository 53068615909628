import { Box, Flex } from "@chakra-ui/layout";
import { Switch } from "react-router-dom";
import {
  CourseListingPageRoute,
  // CoursesPageRoute,
  CreateCoursePageRoute,
  CreateLessonPageRoute,
  CreateUserPageRoute,
  DashboardPageRoute,
  NotFoundPageRoute,
  UserInfoPageRoute,
  ViewCourseInfoPageRoute,
  ViewLessonInfoPageRoute,
  UserListingPageRoute,
  AssessmentPageRoute,
  DepartmentListingPageRoute,
  CreateDepartmentPageRoute,
  ViewDepartmentPageRoute,
  RolesPageRoute,
  SettingsPageRoute,
  EventsPageRoute,
  GradeCriteriaPageRoute,
  SecurityPageRoute,
  CreateEventPageRoute,
  LibraryListingPageRoute,
  CreateLibraryFilePageRoute,
  ViewFileDetailsPageRoute,
  CreateModulePageRoute,
  StandaloneExaminationListingPageRoute,
  StandaloneExaminationAllParticipantsPageRoute,
} from "../../../pages/admin";
import { ViewModuleInfoPageRoute } from "../../../pages/admin/courses/ViewCourseInfoPage/pages/ModuleInfo";
import { CreatePollsPageRoute } from "../../../pages/admin/polls/CreatePollsPage";
import { PollsListingPageRoute } from "../../../pages/admin/polls/PollsPage";
import { ViewPollsInfoPageRoute } from "../../../pages/admin/polls/layout/ViewPollsInfoPage";
import { CreateOptionsPageRoute } from "../../../pages/admin/polls/CreateOptionsPage";
import { CertpageRoute } from "../../../components/CertTemplate/CertificateTemplate";
import AssessmentListingPageRoute from "../../../pages/admin/courses/ViewCourseInfoPage/pages/AssessmentListingPage";
import OverviewPageRoute from "../../../pages/admin/courses/AssessmentPage/pages/OverviewPage";
import EditAssessmentPage from "../../../pages/admin/courses/AssessmentPage/pages/EditAssessmentPage";
import {
  TotalCartItem,
  TotalCartItemRoute,
} from "../../../pages/admin/TotalCartItem/TotalCartItem";

const MainArea = () => {
  return (
    <Box as="main" flex={1} overflowY="auto" flexBasis="1px">
      <Switch>
        <DashboardPageRoute exact path="/admin" />
        <CourseListingPageRoute exact path="/admin/courses" />
        <CreateCoursePageRoute exact path="/admin/courses/edit/:id" />
        <CreateModulePageRoute
          exact
          path="/admin/courses/:courseId/modules/edit/:moduleId"
        />

        <CreateLessonPageRoute
          exact
          path="/admin/courses/:courseId/lessons/edit/:lessonId"
        />
        <AssessmentPageRoute path="/admin/courses/:id/assessment/:assessmentId" />
        <ViewCourseInfoPageRoute path="/admin/courses/details/:id" />
        <ViewModuleInfoPageRoute path="/admin/courses/:courseId/module/:moduleId/view" />
        <AssessmentListingPageRoute path="/admin/courses/:courseId/module/:moduleId/exam" />
        <EditAssessmentPage path="/admin/courses/:courseId/module/:moduleId/edit" />
        <ViewLessonInfoPageRoute
          exact
          path="/admin/courses/:courseId/lesson/:lessonId/view"
        />
        <UserListingPageRoute exact path="/admin/users" />
        <CreateUserPageRoute exact path="/admin/users/edit/:id" />
        <UserInfoPageRoute path="/admin/users/details" />

        <DepartmentListingPageRoute exact path="/admin/category" />
        <DepartmentListingPageRoute exact path="/admin/category" />
        <CreateDepartmentPageRoute exact path="/admin/category/create" />
        <TotalCartItemRoute exact path="/admin/cart-items" />
        <ViewDepartmentPageRoute
          exact
          path="/admin/departments/details/:departmentId/info"
        />
        <RolesPageRoute exact path="/admin/role" />
        <StandaloneExaminationListingPageRoute
          exact
          path="/admin/standalone-exams"
        />
        <StandaloneExaminationAllParticipantsPageRoute
          exact
          path="/admin/standalone-exams/:examinationId/:examinationName"
        />

        <PollsListingPageRoute exact path="/admin/polls" />
        <CreatePollsPageRoute exact path="/admin/polls/edit/:id" />
        <ViewPollsInfoPageRoute path="/admin/polls/details/:id" />
        <CreateOptionsPageRoute
          exact
          path="/admin/polls/:pollId/options/edit/:optionId"
        />

        <CreateEventPageRoute path="/admin/events/edit/:eventId" />
        <EventsPageRoute path="/admin/events" />

        <LibraryListingPageRoute exact path="/admin/library" />
        <CreateLibraryFilePageRoute exact path="/admin/library/edit/:id" />
        <ViewFileDetailsPageRoute exact path="/admin/library/details/:id" />

        <SecurityPageRoute path="/admin/settings/security" />
        <GradeCriteriaPageRoute path="/admin/settings/grade-criteria" />
        <SettingsPageRoute path="/admin/settings" />
        <NotFoundPageRoute />
      </Switch>
    </Box>
  );
};

export default MainArea;
