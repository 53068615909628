import { useState } from "react";
import Star from "./Star";
import { Flex } from "@chakra-ui/react";
function Rated({val}) {
  const [rating, setRating] = useState(0);
//   const changeRating = (newRating) => {
//     setRating(newRating);
//     onChange?.(newRating);
//   };
  const testData = { bgcolor: "red", completed: rating * 20 };

  return (
    <>
      <Flex alignItems="center" gap={1}>
        {[1, 2, 3, 4, 5].map((value) => (
          <Star
            key={value}
            filled={value <= val}
          />
        ))}
      </Flex>
    </>
  );
}
export default Rated;