import { Box } from '@chakra-ui/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';
import { useApp } from '../../../contexts';

const Dropdown = ({ selectedCat, setSelectedCat }) => {
  const [showMenu, setShowMenu] = useState(false);

  const appManager = useApp();
  const metadata = appManager.state.metadata;
  const catItems = metadata?.courseCategory;

  return (
    <Box
      display="flex"
      border="1px solid #000"
      borderRadius="50px"
      alignItems="center"
      padding="8px"
      width="160px"
      justifyContent="center"
      gap="5px"
      cursor="pointer"
      position="relative"
      marginBottom="20px"
      onClick={() => setShowMenu((prev) => !prev)}
    >
      <p>{selectedCat.name}</p>
      <AiFillCaretDown />

      <div
        className={`categories-dropdown_menu ${
          showMenu ? 'drop-active' : 'drop-inactive'
        }`}
      >
        <p
          onClick={() => setSelectedCat({ name: 'All Categories', id: 'noId' })}
        >
          All Categories
        </p>
        {catItems?.map((item) => (
          <p
            onClick={() =>
              setSelectedCat({ id: item?.id, name: item?.categoryName })
            }
            key={item?.id}
          >
            {item?.categoryName}
          </p>
        ))}
      </div>
    </Box>
  );
};

export default Dropdown;
