export const userBookListingRes = {
  data: [
    {
      id: "bookId_1",
      description: "Learn HTML and CSS for free today",
      title: "Web dev 2021 (Pdf)",
      file: "http://res.cloudinary.com/horlar995/image/upload/v1638801369/grooming-centre/Event-Cover-Image/Here%20are%20some%20app%20ideas%20you%20can%20build%20to%20level%20up%20your%20coding%20skills.pdf",
      fileExtension: "pdf",
      user: {
        id: "userId_2",
        profilePics:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        firstName: "jane",
        lastName: "bar",
        title: "Senior Facility Manager",
      },
    },
    {
      id: "bookId_3",
      description: "React Design Patters",
      title: "Become A React Guru (Pdf)",
      file: "http://res.cloudinary.com/horlar995/image/upload/v1638801369/grooming-centre/Event-Cover-Image/Here%20are%20some%20app%20ideas%20you%20can%20build%20to%20level%20up%20your%20coding%20skills.pdf",
      fileExtension: "pdf",
      user: {
        id: "userId_3",
        profilePics:
          "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80",
        firstName: "Richcode",
        lastName: "dart",
        title: "Junior Developer",
      },
    },
    {
      id: "bookId_2",
      description: "Nodejs Advanced Concept",
      title: "Backend Development (Pdf)",
      file: "http://res.cloudinary.com/horlar995/image/upload/v1638801369/grooming-centre/Event-Cover-Image/Here%20are%20some%20app%20ideas%20you%20can%20build%20to%20level%20up%20your%20coding%20skills.pdf",
      fileExtension: "pdf",
      user: {
        id: "userId_1",
        profilePics:
          "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80",
        firstName: "john",
        lastName: "doe",
        title: "Team Lead",
      },
    },
  ],
};

export const userAudioListingRes = {
  data: [
    {
      id: "audioId_1",
      description: "Learn HTML and CSS for free today",
      title: "Web dev 2021 (Audio)",
      file: "https://cvws.icloud-content.com/B/AfDRbOnwgfE7s-XV6vIyqZsj0KngAQRXi-XuXItDO49nw1XlT0o4pbWb/0001.+TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp3?o=AmNB-9OTpxmSQiI5PICdLVyaflxVBPOKbYA3xZgOye2H&v=1&x=3&a=CAog_M8mUiVuHsUQNYE2Wpd1sgpNKed5qvRlPgw1t3uqkZESbxDrifv91y8Yi4Gy_tcvIgEAUgQj0KngWgQ4pbWbaif6mwafoSiss8GYs7yAE48Us6r3JOinq7plAX67crW1nZzaxxhUp_RyJ9Z9QGVewy0F7_ZdSFmBUvED86mN9rei_FV4xQR0gdGbVBTB8GtIrg&e=1638526648&fl=&r=262bf809-9f5d-4265-a4fa-1549ffeafebb-1&k=W4-oikIi-qd6uNW1zF3Tkw&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=51&s=B58ZZE_mZBYVSrCqy3je73lyWj4&cd=i",
      fileExtension: "mpeg",
      user: {
        id: "userId_2",
        profilePics:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        firstName: "jane",
        lastName: "bar",
        title: "Senior Facility Manager",
      },
    },
    {
      id: "audioId_3",
      description: "React Design Patters",
      title: "Become A React Guru (Audio)",
      file: "https://cvws.icloud-content.com/B/AfDRbOnwgfE7s-XV6vIyqZsj0KngAQRXi-XuXItDO49nw1XlT0o4pbWb/0001.+TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp3?o=AmNB-9OTpxmSQiI5PICdLVyaflxVBPOKbYA3xZgOye2H&v=1&x=3&a=CAog_M8mUiVuHsUQNYE2Wpd1sgpNKed5qvRlPgw1t3uqkZESbxDrifv91y8Yi4Gy_tcvIgEAUgQj0KngWgQ4pbWbaif6mwafoSiss8GYs7yAE48Us6r3JOinq7plAX67crW1nZzaxxhUp_RyJ9Z9QGVewy0F7_ZdSFmBUvED86mN9rei_FV4xQR0gdGbVBTB8GtIrg&e=1638526648&fl=&r=262bf809-9f5d-4265-a4fa-1549ffeafebb-1&k=W4-oikIi-qd6uNW1zF3Tkw&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=51&s=B58ZZE_mZBYVSrCqy3je73lyWj4&cd=i",
      fileExtension: "mpeg",
      user: {
        id: "userId_3",
        profilePics:
          "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80",
        firstName: "Richcode",
        lastName: "dart",
        title: "Junior Developer",
      },
    },
    {
      id: "audioId_2",
      description: "Nodejs Advanced Concept",
      title: "Backend Development (Audio)",
      file: "https://cvws.icloud-content.com/B/AfDRbOnwgfE7s-XV6vIyqZsj0KngAQRXi-XuXItDO49nw1XlT0o4pbWb/0001.+TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp3?o=AmNB-9OTpxmSQiI5PICdLVyaflxVBPOKbYA3xZgOye2H&v=1&x=3&a=CAog_M8mUiVuHsUQNYE2Wpd1sgpNKed5qvRlPgw1t3uqkZESbxDrifv91y8Yi4Gy_tcvIgEAUgQj0KngWgQ4pbWbaif6mwafoSiss8GYs7yAE48Us6r3JOinq7plAX67crW1nZzaxxhUp_RyJ9Z9QGVewy0F7_ZdSFmBUvED86mN9rei_FV4xQR0gdGbVBTB8GtIrg&e=1638526648&fl=&r=262bf809-9f5d-4265-a4fa-1549ffeafebb-1&k=W4-oikIi-qd6uNW1zF3Tkw&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=51&s=B58ZZE_mZBYVSrCqy3je73lyWj4&cd=i",
      fileExtension: "mpeg",
      user: {
        id: "userId_1",
        profilePics:
          "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80",
        firstName: "john",
        lastName: "doe",
        title: "Team Lead",
      },
    },
  ],
};

export const userVideoListingRes = {
  data: [
    {
      id: "videoId_1",
      description: "Learn HTML and CSS for free today",
      title: "Web dev 2021 (Video)",
      file: "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4",
      fileExtension: "mp4",
      user: {
        id: "userId_2",
        profilePics:
          "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
        firstName: "jane",
        lastName: "bar",
        title: "Senior Facility Manager",
      },
    },
    {
      id: "videoId_3",
      description: "React Design Patters",
      title: "Become A React Guru (Video)",
      file: "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4 ",
      fileExtension: "mp4",
      user: {
        id: "userId_3",
        profilePics:
          "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80",
        firstName: "Richcode",
        lastName: "dart",
        title: "Junior Developer",
      },
    },
    {
      id: "videoId_2",
      description: "Nodejs Advanced Concept",
      title: "Backend Development (Video)",
      file: "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4 ",
      fileExtension: "mp4",
      user: {
        id: "userId_1",
        profilePics:
          "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80",
        firstName: "john",
        lastName: "doe",
        title: "Team Lead",
      },
    },
  ],
};

export const adminLibraryListingRes = {
  data: {
    rows: [
      {
        id: "bookId_1",
        title: "Web dev 2021",
        description: "Learn Web dev for free today",
        department: {
          id: "departmentId_1",
          name: "Accounting",
        },
        libraryType: {
          id: "libraryTypeId_1",
          name: "pdf",
        },
        file: "http://res.cloudinary.com/horlar995/image/upload/v1638801369/grooming-centre/Event-Cover-Image/Here%20are%20some%20app%20ideas%20you%20can%20build%20to%20level%20up%20your%20coding%20skills.pdf",
        fileExtension: "pdf",
        user: {
          id: "userId_1",
          profilePics:
            "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          firstName: "jane",
          lastName: "bar",
          title: "Senior Facility Manager",
        },
      },
      {
        id: "videoId_1",
        title: "Web dev 2021(Video)",
        description: "Learn Web dev for free today",
        department: {
          id: "departmentId_2",
          name: "Software development",
        },
        libraryType: {
          id: "libraryTypeId_2",
          name: "video",
        },
        file: "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4",
        fileExtension: "mp4",
        user: {
          id: "userId_2",
          profilePics:
            "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          firstName: "shalom",
          lastName: "brain",
          title: "Senior Facility Manager",
        },
      },
      {
        id: "audioId_1",
        title: "Web dev 2021(Audio)",
        description: "Learn Web dev for free today",
        department: {
          id: "departmentId_3",
          name: "computer science",
        },

        libraryType: {
          id: "libraryTypeId_3",
          name: "audio",
        },
        file: "https://cvws.icloud-content.com/B/AfDRbOnwgfE7s-XV6vIyqZsj0KngAQRXi-XuXItDO49nw1XlT0o4pbWb/0001.+TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp3?o=AmNB-9OTpxmSQiI5PICdLVyaflxVBPOKbYA3xZgOye2H&v=1&x=3&a=CAog_M8mUiVuHsUQNYE2Wpd1sgpNKed5qvRlPgw1t3uqkZESbxDrifv91y8Yi4Gy_tcvIgEAUgQj0KngWgQ4pbWbaif6mwafoSiss8GYs7yAE48Us6r3JOinq7plAX67crW1nZzaxxhUp_RyJ9Z9QGVewy0F7_ZdSFmBUvED86mN9rei_FV4xQR0gdGbVBTB8GtIrg&e=1638526648&fl=&r=262bf809-9f5d-4265-a4fa-1549ffeafebb-1&k=W4-oikIi-qd6uNW1zF3Tkw&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=51&s=B58ZZE_mZBYVSrCqy3je73lyWj4&cd=i",
        fileExtension: "mpeg",
        user: {
          id: "userId_2",
          profilePics:
            "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
          firstName: "stanley",
          lastName: "ogada",
          title: "Senior Frontend Engineer",
        },
      },
    ],
  },
};

export const requestLibraryFileDetailsRes_bookId_1 = {
  data: {
    id: "bookId_1",
    description: "Learn HTML and CSS for free today",
    title: "Web dev 2021 (Pdf)",
    department: {
      id: "departmentId_1",
      name: "Accounting",
    },
    libraryTypeId: "libraryTypeId_1",
    libraryType: {
      id: "libraryTypeId_1",
      name: "pdf",
    },
    file: "http://res.cloudinary.com/horlar995/image/upload/v1638801369/grooming-centre/Event-Cover-Image/Here%20are%20some%20app%20ideas%20you%20can%20build%20to%20level%20up%20your%20coding%20skills.pdf",
    fileExtension: "pdf",
    user: {
      id: "userId_1",
      profilePics:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      firstName: "jane",
      lastName: "bar",
      title: "Senior Facility Manager",
    },
  },
};

export const requestLibraryFileDetailsRes_videoId_1 = {
  data: {
    id: "videoId_1",
    description: "Learn HTML and CSS for free today",
    title: "Web dev 2021 (Video)",
    department: {
      id: "departmentId_3",
      name: "computer science",
    },
    libraryTypeId: "libraryTypeId_2",
    libraryType: {
      id: "libraryTypeId_2",
      name: "video",
    },
    file: "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4",
    fileExtension: "mp4",
    user: {
      id: "userId_2",
      profilePics:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      firstName: "shalom",
      lastName: "brain",
      title: "Senior Facility Manager",
    },
  },
};

export const requestLibraryFileDetailsRes_audioId_1 = {
  data: {
    id: "audioId_1",
    description: "Learn HTML and CSS for free today",
    title: "Web dev 2021 (Audio)",
    department: {
      id: "departmentId_2",
      name: "Software development",
    },
    libraryTypeId: "libraryTypeId_3",
    libraryType: {
      id: "libraryTypeId_3",
      name: "audio",
    },
    file: "https://cvws.icloud-content.com/B/AfDRbOnwgfE7s-XV6vIyqZsj0KngAQRXi-XuXItDO49nw1XlT0o4pbWb/0001.+TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp3?o=AmNB-9OTpxmSQiI5PICdLVyaflxVBPOKbYA3xZgOye2H&v=1&x=3&a=CAog_M8mUiVuHsUQNYE2Wpd1sgpNKed5qvRlPgw1t3uqkZESbxDrifv91y8Yi4Gy_tcvIgEAUgQj0KngWgQ4pbWbaif6mwafoSiss8GYs7yAE48Us6r3JOinq7plAX67crW1nZzaxxhUp_RyJ9Z9QGVewy0F7_ZdSFmBUvED86mN9rei_FV4xQR0gdGbVBTB8GtIrg&e=1638526648&fl=&r=262bf809-9f5d-4265-a4fa-1549ffeafebb-1&k=W4-oikIi-qd6uNW1zF3Tkw&ckc=com.apple.clouddocs&ckz=com.apple.CloudDocs&p=51&s=B58ZZE_mZBYVSrCqy3je73lyWj4&cd=i",
    fileExtension: "mpeg",
    user: {
      id: "userId_2",
      profilePics:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
      firstName: "stanley",
      lastName: "ogada",
      title: "Senior Frontend Engineer",
    },
  },
};

export const adminUploadLibraryFileRes = {
  message: "File uploaded successfully",
  data: {
    id: "bookId_1",
  },
};
export const adminEditLibraryFileRes_videoId_1 = {
  message: "File updated successfully",
  data: [
    {
      id: "videoId_1",
    },
  ],
};
export const adminEditLibraryFileRes_audioId_1 = {
  message: "File updated successfully",
  data: [
    {
      id: "audioId_1",
    },
  ],
};
export const adminEditLibraryFileRes_bookId_1 = {
  message: "File updated successfully",
  data: [
    {
      id: "bookId_1",
    },
  ],
};
