import { getDurationBetweenStartTimeAndEndTime } from "../../../utils";
import { http } from "../http";

/**
 * Endpoint to get `course-listing`
 *  @param {object} params
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const adminGetCourseListing = async (params) => {
  const path = `/course/allcourses`;

  const {
    data: { data },
  } = await http.get(path, { params });
  return {
    courses: data.map((course) => ({
      id: course.id,
      title: course.title,
      instructor: {
        firstName: course.instructor.firstName,
        lastName: course.instructor.lastName,
      },
      price: course.price.toString(),
      discountedPrice: course.discountedPrice,
      // startDate: course.lesson[0] ? course.lesson[0].startTime : "not set",
      isPublished: course.isPublished,
    })),
    showingDocumentsCount: data.length,
    totalDocumentsCount: data.length,
  };
};

/**
 * Endpoint for course editing/modification
 * @param {string} courseId
 * @param {object} body
 * @returns {Promise<{ message: string, course: { id: string }}>}
 */
export const adminEditCourse = async (courseId, body) => {
  const path = `/course/edit/${courseId}`;

  const {
    data: { message, data },
  } = await http.patch(path, body);

  const course = {
    id: data[0].id,
  };

  return { message, course };
};

/**
 * Endpoint for course creation
 * @param {{ title: string, thumbnail: File, certificate: File, description: string, departmentId: string, }} body
 * @returns {Promise<{ message: string, course: { id: string } }>}
 */
export const adminCreateCourse = async (body, onProgressCallback) => {
  const path = "/course/create";
  let uploadProgress = 0;

  const config = {
    onUploadProgress: (progressEvent) => {
      uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );

      onProgressCallback(uploadProgress);
    },
  };

  try {
    const {
      data: { message, data },
    } = await http.post(path, body, config);

    const course = { id: data.id };
    console.log(data);
    return { message, course };
  } catch (error) {
    console.error("Error creating course:", error);
    throw error;
  }
};

/**
 * Endpoint to get user `course-listing`
 *
 *  @param {object} params
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const adminGetUserCourseListing = async (userId, params) => {
  const path = `/admin/courses/${userId}`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    courses: data.map((course) => ({
      id: course.id,
      title: course.title,
      image: course.thumbnailUrl,
      preview: course.previewUrl,
      description: course.description,
      noofmodules: course.noOfModules,
      isPublished: course.isPublished,
      categoryId: course.categoryId,
      active: course.active,
      modules: course.modules,
      reviews: course.reviews,
      instructor: {
        id: course.instructor.id,
        name: course.instructor.firstName + " " + course.instructor.lastName,
        photo: course.instructor.profilePics,
      },
      ratingsAverage: course.ratingsAverage,
      ratingsCount: course.ratingsCount,
      price: course.price,
      discountedPrice: course.discountedPrice,
      status: course.progressPercentage,
      // active: course.active,
    })),
    showingDocumentsCount: data.length,
    totalDocumentsCount: data.length,
  };
};

/**
 * Endpoint to get `course-details` for a user
 * @param {string} id - courseId
 *
 * @returns {Promise<{ course: Course }>}
 */
export const userGetCourseDetails = async (id) => {
  const path = `/course/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  // let lessons = data.lesson.map((l) => ({
  //   ...l,
  //   hasCompleted: l.lessonTracking?.[0]?.isCompleted ? true : false,
  //   duration: getDurationBetweenStartTimeAndEndT
  //   ime(l.startTime, l.endTime),
  // }));

  // let assessments = data.assessment.map((a) => ({
  //   ...a,
  //   hasCompleted: a.assessmentScoreSheets?.[0] ? true : false,
  //   endTime: getEndTime(a.startTime, a.duration),
  // }));

  // lessons = sortByMostRelevantDate(lessons);
  // assessments = sortByMostRelevantDate(assessments);

  return data;
  // return {

  //   // course: {
  //   //   // TODO: remove lazy mapping
  //   //   ...data, // TODO: remove lazy mapping
  //   //   // lessons, // TODO: remove lazy mapping
  //   //   // assessments, // TODO: remove lazy mapping
  //   //   // examination: data.examination
  //   //   //   ? {
  //   //   //     ...data.examination,
  //   //   //     hasCompleted: data.examination.examinationScoreSheets?.[0]
  //   //   //       ? true
  //   //   //       : false,
  //   //   //     endTime: getEndTime(
  //   //   //       data.examination.startTime,
  //   //   //       data.examination.duration
  //   //   //     ),
  //   //   //   }
  //   //   //   : null,
  //   //   startTime: data.lesson[0]?.startTime,
  //   //   endTime: data?.lesson[data.lesson.length - 1]?.endTime,
  //   // }, // TODO: remove lazy mapping
  // };
};

/**
 * Endpoint to get `course-details` for a user
 * @param {string} id - courseId
 *
 * @returns {Promise<{ course: Course }>}
 */
export const userGetModuleDetails = async (id) => {
  const path = `/course/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  // let lessons = data.lesson.map((l) => ({
  //   ...l,
  //   hasCompleted: l.lessonTracking?.[0]?.isCompleted ? true : false,
  //   duration: getDurationBetweenStartTimeAndEndT
  //   ime(l.startTime, l.endTime),
  // }));

  // let assessments = data.assessment.map((a) => ({
  //   ...a,
  //   hasCompleted: a.assessmentScoreSheets?.[0] ? true : false,
  //   endTime: getEndTime(a.startTime, a.duration),
  // }));

  // lessons = sortByMostRelevantDate(lessons);
  // assessments = sortByMostRelevantDate(assessments);

  return data;
  // return {

  //   // course: {
  //   //   // TODO: remove lazy mapping
  //   //   ...data, // TODO: remove lazy mapping
  //   //   // lessons, // TODO: remove lazy mapping
  //   //   // assessments, // TODO: remove lazy mapping
  //   //   // examination: data.examination
  //   //   //   ? {
  //   //   //     ...data.examination,
  //   //   //     hasCompleted: data.examination.examinationScoreSheets?.[0]
  //   //   //       ? true
  //   //   //       : false,
  //   //   //     endTime: getEndTime(
  //   //   //       data.examination.startTime,
  //   //   //       data.examination.duration
  //   //   //     ),
  //   //   //   }
  //   //   //   : null,
  //   //   startTime: data.lesson[0]?.startTime,
  //   //   endTime: data?.lesson[data.lesson.length - 1]?.endTime,
  //   // }, // TODO: remove lazy mapping
  // };
};

/**
 * Endpoint to get `course-details` for a user
 * @param {string} id - courseId
 *
 * @returns {Promise<{ course: Course }>}
 */
export const userGetlesssonDetails = async (id) => {
  const path = `/lesson/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  let lessons = data.map((l) => ({
    ...l,
    hasCompleted: l.lessonTracking?.[0]?.isCompleted ? true : false,
    duration: getDurationBetweenStartTimeAndEndTime(l.startTime, l.endTime),
  }));

  // let assessments = data.assessment.map((a) => ({
  //   ...a,
  //   hasCompleted: a.assessmentScoreSheets?.[0] ? true : false,
  //   endTime: getEndTime(a.startTime, a.duration),
  // }));

  // lessons = sortByMostRelevantDate(lessons);
  // assessments = sortByMostRelevantDate(assessments);

  // return data
  return {
    course: {
      // TODO: remove lazy mapping
      ...data, // TODO: remove lazy mapping
      lessons, // TODO: remove lazy mapping
      // assessments, // TODO: remove lazy mapping
      // examination: data.examination
      //   ? {
      //     ...data.examination,
      //     hasCompleted: data.examination.examinationScoreSheets?.[0]
      //       ? true
      //       : false,
      //     endTime: getEndTime(
      //       data.examination.startTime,
      //       data.examination.duration
      //     ),
      //   }
      //   : null,
      // startTime: data.lesson[0]?.startTime,
      // endTime: data?.lesson[data.lesson.length - 1]?.endTime,
    }, // TODO: remove lazy mapping
  };
};
/**
 * Endpoint to get `course-details` for a user
 * @param {string} id - courseId
 *
 * @returns {Promise<{ course: Course }>}
 */
export const userGetCourseHDetails = async (id) => {
  const path = `/lesson/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  // let lessons = data.lesson.map((l) => ({
  //   ...l,
  //   hasCompleted: l.lessonTracking?.[0]?.isCompleted ? true : false,
  //   duration: getDurationBetweenStartTimeAndEndT
  //   ime(l.startTime, l.endTime),
  // }));

  // let assessments = data.assessment.map((a) => ({
  //   ...a,
  //   hasCompleted: a.assessmentScoreSheets?.[0] ? true : false,
  //   endTime: getEndTime(a.startTime, a.duration),
  // }));

  // lessons = sortByMostRelevantDate(lessons);
  // assessments = sortByMostRelevantDate(assessments);

  return data;
  // return {

  //   // course: {
  //   //   // TODO: remove lazy mapping
  //   //   ...data, // TODO: remove lazy mapping
  //   //   // lessons, // TODO: remove lazy mapping
  //   //   // assessments, // TODO: remove lazy mapping
  //   //   // examination: data.examination
  //   //   //   ? {
  //   //   //     ...data.examination,
  //   //   //     hasCompleted: data.examination.examinationScoreSheets?.[0]
  //   //   //       ? true
  //   //   //       : false,
  //   //   //     endTime: getEndTime(
  //   //   //       data.examination.startTime,
  //   //   //       data.examination.duration
  //   //   //     ),
  //   //   //   }
  //   //   //   : null,
  //   //   startTime: data.lesson[0]?.startTime,
  //   //   endTime: data?.lesson[data.lesson.length - 1]?.endTime,
  //   // }, // TODO: remove lazy mapping
  // };
};

/**
 * Endpoint to publish course
 * @param {string} id - courseId
 *
 * @returns {Promise<{ course: Course }>}
 */
export const adminPublishCourse = async (id) => {
  const path = `/course/publish/${id}`;

  await http.patch(path);
};

/**
 * Endpoint to delete course
 * @param {string} id - courseId
 *
 * @returns {Promise<{ course: Course }>}
 */
export const adminDeleteCourse = async (id) => {
  const path = `/course/delete/${id}`;

  await http.delete(path);
};
/**
 * Endpoint to delete multiple course
 *
 * @returns {Promise<{ course: Course }>}
 */
export const adminDeleteMultipleCourses = async (ids) => {
  const path = `/course/delete/multiple`;
  let formattedIds = [];
  for (let i = 0; i < ids.length; i++) {
    formattedIds.push(ids[i].id);
  }
  const body = { courseIds: formattedIds };
  await http.delete(path, { data: body });
};

/**
 * Endpoint to delete multiple categories
 *
 * @returns {Promise<{ course: Course }>}
 */
export const adminDeleteMultipleCategories = async (ids) => {
  const path = `/category/delete/multiple`;
  let formattedIds = [];
  for (let i = 0; i < ids.length; i++) {
    formattedIds.push(ids[i].id);
  }
  const body = { categoryIds: formattedIds };
  await http.delete(path, { data: body });
};

/**
 * Endpoint to unpublish course
 * @param {string} id - id
 *
 * @returns {Promise<{ course: Course }>}
 */
export const adminUnpublishCourse = async (id) => {
  const path = `/course/unpublish/${id}`;

  await http.patch(path);
};

// // PUBLIC API

/**
 * Endpoint to get user `course-listing`
 *
 *  @param {object} params
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const userGetAllCourses = async (params) => {
  const path = `/course/allcourses`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    courses: data.map((course) => ({
      id: course.id,
      title: course.title,
      image: course.thumbnailUrl,
      preview: course.previewUrl,
      description: course.description,
      noofmodules: course.noOfModules,
      isPublished: course.isPublished,
      categoryId: course.categoryId,
      active: course.active,
      modules: course.modules,
      reviews: course.reviews,
      instructor: {
        id: course.instructor.id,
        name: course.instructor.firstName + " " + course.instructor.lastName,
        photo: course.instructor.profilePics,
      },
      ratingsTotal: course.reviews.length
        ? course.reviews.map((r) => r.reviewerRating).reduce((a, b) => a + b)
        : 0,
      ratingsCount: course.reviews.length,
      price: course.price,
      discountedPrice: course.discountedPrice,
    })),
  };
};

export const userGetCoursePreview = async (params) => {
  try {
    const path = `/course/landing/preview`;

    const { data } = await http.get(path, { params });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const userGetCourseCategory = async (id) => {
  const path = `/course/category/${id}`;

  const {
    data: { data },
  } = await http.get(path);
  return {
    data,
  };
};
/**
 * Endpoint to get user `course-listing`
 *
 *  @param {object} params
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const userGetMyCourseListing = async (params) => {
  // const path = `/course/userpurchasedcourses`;
  const path = `/course/allcourses`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    courses: data.map((course) => ({
      id: course.id,
      title: course.title,
      image: course.thumbnailUrl,
      preview: course.previewUrl,
      description: course.description,
      objectives: course.objectives,
      noofmodules: course.noOfModules,
      isPublished: course.isPublished,
      categoryId: course.categoryId,
      active: course.active,
      modules: course.modules,
      reviews: course.reviews,
      instructor: {
        id: course.instructor.id,
        name: course.instructor.firstName + " " + course.instructor.lastName,
        photo: course.instructor.profilePics,
      },
      ratingsTotal: course.reviews.length
        ? course.reviews.map((r) => r.reviewerRating).reduce((a, b) => a + b)
        : 0,
      ratingsCount: course.reviews.length,
      price: course.price,
      discountedPrice: course.discountedPrice,
    })),
  };
};

/**
 * Endpoint to get ongoing course
 *
 *  @param {object} params
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const userGetOngoingCourses = async (params) => {
  const path = `/course/userongoingcourses`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    courses: data.map((data) => ({
      id: data.course.id,
      title: data.course.title,
      image: data.course.thumbnailUrl,
      preview: data.course.previewUrl,
      description: data.course.description,
      noofmodules: data.course.noOfModules,
      isPublished: data.course.isPublished,
      categoryId: data.course.categoryId,
      active: data.course.active,
      modules: data.purchasedCourseModules,
      progress: data.progress,
      reviews: data.reviews,
      instructor: {
        id: data.course.instructor.id,
        name:
          data.course.instructor.firstName +
          " " +
          data.course.instructor.lastName,
        photo: data.course.instructor.profilePics,
      },
      ratingsTotal: data.course.reviews.length
        ? data.course.reviews
            .map((r) => r.reviewerRating)
            .reduce((a, b) => a + b)
        : 0,
      ratingsCount: data.course.reviews.length,
      price: data.course.price,
      discountedPrice: data.course.discountedPrice,
    })),
  };
};
/**
 * Endpoint to get ongoing course
 *
 *  @param {object} params
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const userGetPurchasedCourses = async (params) => {
  const path = `/course/userpurchasedcourses`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    courses: data.map((data) => ({
      id: data.course.id,
      title: data.course.title,
      image: data.course.thumbnailUrl,
      preview: data.course.previewUrl,
      description: data.course.description,
      noofmodules: data.course.noOfModules,
      isPublished: data.course.isPublished,
      categoryId: data.course.categoryId,
      active: data.course.active,
      modules: data.purchasedCourseModules,
      progress: data.progress,
      reviews: data.course.reviews,
      instructor: {
        id: data.course.instructor.id,
        name:
          data.course.instructor.firstName +
          " " +
          data.course.instructor.lastName,
        photo: data.course.instructor.profilePics,
      },
      ratingsTotal: data.course.reviews.length
        ? data.course.reviews
            .map((r) => r.reviewerRating)
            .reduce((a, b) => a + b)
        : 0,
      ratingsCount: data.course.reviews.length,
      price: data.course.price,
      discountedPrice: data.course.discountedPrice,
    })),
  };
};
/**
 * Endpoint to get ongoing course
 *
 *  @param {object} params
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const userGetCompletedCourses = async (params) => {
  const path = `/course/usercompletedcourses`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    courses: data.map((data) => ({
      id: data.course.id,
      title: data.course.title,
      image: data.course.thumbnailUrl,
      preview: data.course.previewUrl,
      description: data.course.description,
      noofmodules: data.course.noOfModules,
      isPublished: data.course.isPublished,
      categoryId: data.course.categoryId,
      active: data.course.active,
      modules: data.purchasedCourseModules,
      progress: data.progress,
      reviews: data.reviews,
      instructor: {
        id: data.course.instructor.id,
        name:
          data.course.instructor.firstName +
          " " +
          data.course.instructor.lastName,
        photo: data.course.instructor.profilePics,
      },
      ratingsTotal: data.course.reviews.length
        ? data.course.reviews
            .map((r) => r.reviewerRating)
            .reduce((a, b) => a + b)
        : 0,
      ratingsCount: data.course.reviews.length,
      price: data.course.price,
      discountedPrice: data.course.discountedPrice,
    })),
  };
};

/**
 * Endpoint to get user `course-listing`
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const userGetCourseListing = async (params) => {
  const path = `/course/allcourses`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    courses: data.map((course) => ({
      id: course.id,
      title: course.title,
      image: course.thumbnailUrl,
      preview: course.previewUrl,
      description: course.description,
      noofmodules: course.noOfModules,
      isPublished: course.isPublished,
      categoryId: course.categoryId,
      active: course.active,
      modules: course.modules,
      reviews: course.reviews,
      instructor: {
        id: course.instructor.id,
        name: course.instructor.firstName + " " + course.instructor.lastName,
        photo: course.instructor.profilePics,
      },
      ratingsTotal: course.reviews.length
        ? course.reviews.map((r) => r.reviewerRating).reduce((a, b) => a + b)
        : 0,
      ratingsCount: course.reviews.length,
      price: course.price,
      discountedPrice: course.discountedPrice,
    })),
  };
};

/**
 * Endpoint to get user `course-details`
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const userGetCourseDetailsUser = async (id) => {
  const path = `/course/${id}`;

  const {
    data: { data },
  } = await http.get(path);
  return data;
};

export const userGetPurcasedCourseDetails = async (id) => {
  const path = `/course/userpurchasedcourse/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  console.log(data);

  return {
    ...data.course,
    purchasedCourseId: data.id,
    progress: data.progress,
    purchasedCourseModules: data.purchasedCourseModules,
  };
};

/**
 * Endpoint to get add to cart`
 *
 * @returns {Promise<{ courses: CourseListArray }>}
 */
export const addToCart = async (id) => {
  const path = `/cart/add`;
  var body = { courseId: id };

  const {
    data: { data },
  } = await http.post(path, body);
  return data;
};

/**
 * Endpoint to get cart`
 *
 * @returns {Promise<{ courses: getCourseListArray }>}
 */
export const getCart = async (id) => {
  const path = `/cart`;
  // var body = { 'courseId': id }

  const {
    data: { data },
  } = await http.get(path);
  return data;
};

/**
 * Endpoint to delete item from cart`
 *
 * @returns {Promise<{ courses: getCourseListArray }>}
 */
export const deleteCartItem = async (id) => {
  const path = `/cart/add`;
  var body = { courseId: id };

  const {
    data: { data },
  } = await http.delete(path, body);
  return data;
};

/**
 * Endpoint to check erollment elegibility`
 *
 * @returns {Promise<{ courses: getCourseListArray }>}
 */
export const checkUserErollmentElegibility = async (id) => {
  const path = `/course/enroll/eligibility`;
  const body = { courseId: id };

  const {
    data: { data },
  } = await http.post(path, body);
  return data;
};

/**
 * Endpoint to check enroll user for a course`
 *
 * @returns {Promise<{ courses: getCourseListArray }>}
 */
export const enrollUser = async (id) => {
  const path = `/course/enroll`;
  var body = { courseId: id };

  const {
    data: { data },
  } = await http.post(path, body);
  return data;
};

/**
 * Endpoint to checkout`
 *
 */
export const checkout = async (reference, totalPrice) => {
  const path = `/cart/checkout`;
  const body = { totalPrice, reference: reference.reference };

  const {
    data: { data },
  } = await http.post(path, body);
  console.log(data, "666");
  return data;
};

// /**
//  * Endpoint to delete item from cart`
//  *
//  * @returns {Promise<{ courses: getCourseListArray }>}
//  */
// export const deleteCartItem = async (id) => {
//   const path = `/cart/add`;
//   var body = { 'courseId': id }

//   const {
//     data: { data, },
//   } = await http.post(path, body);
//   return data

// };

/**
 * Endpoint to get `course-details` for a user
 * @param {string} id - courseId
 *
 * @returns {Promise<{ course: Course }>}
 */
export const userGetLessonDetailsTake = async (id) => {
  const path = `/lesson/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  // let lessons = data.lesson.map((l) => ({
  //   ...l,
  //   hasCompleted: l.lessonTracking?.[0]?.isCompleted ? true : false,
  //   duration: getDurationBetweenStartTimeAndEndT
  //   ime(l.startTime, l.endTime),
  // }));

  // let assessments = data.assessment.map((a) => ({
  //   ...a,
  //   hasCompleted: a.assessmentScoreSheets?.[0] ? true : false,
  //   endTime: getEndTime(a.startTime, a.duration),
  // }));

  // lessons = sortByMostRelevantDate(lessons);
  // assessments = sortByMostRelevantDate(assessments);

  return data;
  // return {

  //   // course: {
  //   //   // TODO: remove lazy mapping
  //   //   ...data, // TODO: remove lazy mapping
  //   //   // lessons, // TODO: remove lazy mapping
  //   //   // assessments, // TODO: remove lazy mapping
  //   //   // examination: data.examination
  //   //   //   ? {
  //   //   //     ...data.examination,
  //   //   //     hasCompleted: data.examination.examinationScoreSheets?.[0]
  //   //   //       ? true
  //   //   //       : false,
  //   //   //     endTime: getEndTime(
  //   //   //       data.examination.startTime,
  //   //   //       data.examination.duration
  //   //   //     ),
  //   //   //   }
  //   //   //   : null,
  //   //   startTime: data.lesson[0]?.startTime,
  //   //   endTime: data?.lesson[data.lesson.length - 1]?.endTime,
  //   // }, // TODO: remove lazy mapping
  // };
};
export const userPurchaseFreeCourse = async (payload) => {
  try {
    const path = `/course/purchase/freecourse`;

    const { data } = await http.post(path, payload);

    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
