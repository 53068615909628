import { Box, Button, Flex, Stack } from "@chakra-ui/react";
import { Text, Image, Link } from "../../components";
import { formatPriceToNaira, truncateText } from "../../utils";
import Rated from "../../layouts/user/Rated";
import { useApp } from "../../contexts";

export const GeneralCourseProductCard = ({ course, listItem, w }) => {
  const appManager = useApp();
  const metadata = appManager.state.metadata;
  const catItems = metadata?.courseCategory;

  const renderContent = () => (
    <div>
      <Text
        bold
        mb={1}
        _hover={{
          textDecoration: "underline",
        }}
      >
        <Link href={`/general/courses/${course.id}`}>
          <Box as="span" fontSize={19} fontWeight={"bold"}>
            {course.title}
          </Box>
        </Link>
      </Text>
      {course.instructor && (
        <Box w="fit-content">
          <Link href={`/users/${course.instructor.id}`}>
            <Flex
              alignItems="center"
              _hover={{
                textDecoration: "underline",
              }}
            >
              {/*<Image*/}
              {/*  src={course.instructor.photo}*/}
              {/*  alt={course.instructor.name}*/}
              {/*  height={{ base: "30px", tablet: "30px" }}*/}
              {/*  width={{ base: "30px", tablet: "30px" }}*/}
              {/*  flexShrink={0}*/}
              {/*  rounded="full"*/}
              {/*  mr={2}*/}
              {/*/>*/}

              <Text opacity={0.8} isTruncated>
                By {course.instructor.name}
              </Text>
            </Flex>
          </Link>
        </Box>
      )}

      <Flex>
        <Box w="fit-content" mt={3}>
          {course.reviews.length === 0 ? (
            <Box
              _hover={{
                textDecoration: "underline",
              }}
            >
              <Link
                href={`/rev/${course.id}`}
                onClick={localStorage.setItem("course", course.id)}
              >
                <Text as="level4" bold mb={{ base: 1, tablet: 2 }}>
                  Rate this course
                </Text>
              </Link>
            </Box>
          ) : (
            <Flex gap={2}>
              <Rated val={course.ratingsTotal / course.ratingsCount} />
              <Text level="level1" color="orange">
                {course.ratingsTotal / course.ratingsCount}
              </Text>
              <Text level="level1">({course.ratingsCount})</Text>
            </Flex>
          )}
        </Box>
      </Flex>

      <Flex alignItems="center" gridGap={5}>
        <Text bold as={course.description ? "level2" : "level3"}>
          ₦{formatPriceToNaira(course?.discountedPrice)}
        </Text>

        {course.price && (
          <Text textDecoration="line-through" as="level5">
            ₦{formatPriceToNaira(course?.price)}
          </Text>
        )}
      </Flex>
    </div>
  );

  return (
    <Stack
      pos={"relative"}
      p={{ base: 1, tablet: 2 }}
      m={{ base: 1, tablet: 2 }}
      spacing={listItem ? 0 : 2}
      w={
        w ||
        (listItem
          ? "auto"
          : { base: "290px", tablet: "290px", laptop: "290px" })
      }
      //   maxW={listItem && { base: "200px", tablet: "400px", laptop: "700px" }}
      minH={!listItem && { base: "250px", laptop: "280px" }}
      bg="white"
      rounded="md"
      shadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
      flexDirection={listItem ? "row" : "column"}
    >
      <Image
        src={course.image}
        alt={course.title}
        w={course.description ? "270px" : listItem ? "270px" : "270px"}
        h={
          course.description
            ? "160px"
            : listItem
            ? ""
            : { base: "160px", tablet: "160px", laptop: "160px" }
        }
        rounded="md"
        flexShrink={0}
      />

      {listItem ? <Stack pl={5}>{renderContent()}</Stack> : renderContent()}

      {catItems
        ?.filter((cat) => cat.id === course.categoryId)
        .map((cat) => (
          <div key={cat.id}>
            <Button
              backgroundColor={"#5E0101"}
              outline={"2px solid #fff"}
              color={"#fff"}
              pos={"absolute"}
              top={"10"}
              left={6}
              _hover={{ backgroundColor: "green" }}
              borderRadius={7}
            >
              {truncateText(cat.categoryName, 12)}
            </Button>
          </div>
        ))}
    </Stack>
  );
};
