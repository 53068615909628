import { Box, Flex } from "@chakra-ui/react";
import { Skeleton } from "@chakra-ui/skeleton";
import { useCallback, useEffect } from "react";
import { Route } from "react-router-dom";
import {
  Button,
  CourseOverviewCard,
  Heading,
  ScrollCarousel,
  Text,
} from "../../components";
import { GeneralCourseProductCard } from "../../components/Cards/GeneralCourseCard";
import { useApp } from "../../contexts";
import { useFetch } from "../../hooks";
import {
  userGetCompletedCourses,
  userGetCourseListing,
  userGetMyCourseListing,
  userGetOngoingCourses,
  userGetPurchasedCourses,
} from "../../services";
import {
  pageWrapperSpacing_userPages,
  sectionMaxWidth_userPages,
} from "../../theme/breakpoints";
import { createRange } from "../../utils";

// export const useGetallCouses = (
//   params = {
//     ongoing: true,
//     limit: 100,
//   }
// ) => {
//   const { resource: myCourses, handleFetchResource } = useFetch();

//   const handleFetch = useCallback(async () => {
//     handleFetchResource({
//       fetcher: async () => {
//         const { courses } = await userGetAllCourses(params);

//         return courses;
//       },
//     });

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [handleFetchResource]);

//   useEffect(() => {
//     handleFetch();
//   }, [handleFetch]);

//   return {
//     myCourses,
//     handleFetchMyCourses: handleFetch,
//   };

// };

export const useMyCourses = (
  params = {
    ongoing: true,
    limit: 100,
  }
) => {
  const { resource: myCourses, handleFetchResource } = useFetch();

  const handleFetch = useCallback(async () => {
    handleFetchResource({
      fetcher: async () => {
        const { courses } = await userGetMyCourseListing(params);
        const publishedCourses = courses.filter(
          (course) => course.isPublished === true
        );

        return publishedCourses;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchResource]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return {
    myCourses,
    handleFetchMyCourses: handleFetch,
  };
};
export const useOnGoingCourses = (
  params = {
    ongoing: true,
    limit: 100,
  }
) => {
  const { resource: myCourses, handleFetchResource } = useFetch();

  const handleFetch = useCallback(async () => {
    handleFetchResource({
      fetcher: async () => {
        const { courses } = await userGetOngoingCourses(params);
        const publishedCourses = courses.filter(
          (course) => course.isPublished === true
        );

        return publishedCourses;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchResource]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return {
    myCourses,
    handleFetchMyCourses: handleFetch,
  };
};
export const usePurchasedCourses = (
  params = {
    ongoing: true,
    limit: 100,
  }
) => {
  const { resource: myCourses, handleFetchResource } = useFetch();

  const handleFetch = useCallback(async () => {
    handleFetchResource({
      fetcher: async () => {
        const { courses } = await userGetPurchasedCourses(params);
        const publishedCourses = courses.filter(
          (course) => course.isPublished === true
        );

        return publishedCourses;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchResource]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return {
    myCourses,
    handleFetchMyCourses: handleFetch,
  };
};
export const useCompletedCourses = (
  params = {
    completed: true,
    limit: 100,
  }
) => {
  const { resource: myCourses, handleFetchResource } = useFetch();

  const handleFetch = useCallback(async () => {
    handleFetchResource({
      fetcher: async () => {
        const { courses } = await userGetCompletedCourses(params);
        const publishedCourses = courses.filter(
          (course) => course.isPublished === true
        );

        return publishedCourses;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchResource]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return {
    myCourses,
    handleFetchMyCourses: handleFetch,
  };
};

export const useCourseListing = (
  params
  // path // TODO: might add path to the same service, since the response signature is same
) => {
  const { resource: courseListing, handleFetchResource } = useFetch();

  const handleFetch = useCallback(async () => {
    handleFetchResource({
      fetcher: async () => {
        const { courses } = await userGetCourseListing(params);

        return courses;
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFetchResource]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return {
    courseListing,
    handleFetchCourseListing: handleFetch,
  };
};

const HomePage = () => {
  const {
    state: { user },
  } = useApp();

  const { myCourses, handleFetchMyCourses } = usePurchasedCourses();
  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Heading regular as="h1" fontSize="heading.h2" mb={5}>
        Hello <b>{user?.firstName}</b>
      </Heading>

      <Section
        renderHeading={() => (
          <Heading fontSize="heading.h4" regular>
            Pick up where you left off
          </Heading>
        )}
        seeAllLink={"/my-courses"}
      >
        {myCourses.data && (
          <ScrollCarousel
            gridGap="8"
            autoPlay
            width={sectionMaxWidth_userPages}
          >
            {myCourses.data.map((course) =>
              course.isPublished === true ? (
                <CourseOverviewCard key={course.id} course={course} />
              ) : null
            )}
          </ScrollCarousel>
        )}

        {myCourses.loading && (
          <ScrollCarousel
            gridGap="8"
            autoPlay={{ interval: 1500 }}
            width={sectionMaxWidth_userPages}
          >
            {createRange(10).map((i) => (
              <Skeleton
                key={i}
                w={{ base: "200px", tablet: "300px" }}
                h={{ base: "240px", tablet: "180px" }}
                rounded="md"
              />
            ))}
          </ScrollCarousel>
        )}

        {myCourses.error && (
          <SectionErrorView handleRetry={handleFetchMyCourses} />
        )}
      </Section>

      <SectionCourseListing
        headingText="Recommended for you"
        requestParams={{ recommended: true }}
      />

      <SectionCourseListing
        headingText="Trending courses"
        requestParams={{ trending: true, design: true }}
      />
    </Box>
  );
};

const SectionErrorView = ({ handleRetry }) => (
  <Flex
    flexDirection="column"
    height="200px"
    alignItems="center"
    justifyContent="center"
    bg="accent.1"
  >
    <Text as="level1" bold mb={5}>
      Something went wrong
    </Text>

    <Button onClick={handleRetry}>Try again</Button>
  </Flex>
);

const SectionCourseListing = ({
  headingText,
  seeAllLink,
  requestParams,
  ...rest
}) => {
  const { courseListing, handleFetch } = useCourseListing(requestParams);

  return (
    <Section headingText={headingText} seeAllLink={seeAllLink} pb={5} {...rest}>
      {courseListing.data && (
        <ScrollCarousel gridGap="6" autoPlay width={sectionMaxWidth_userPages}>
          {courseListing.data.map((course) =>
            course.isPublished === true ? (
              <GeneralCourseProductCard key={course.id} course={course} />
            ) : null
          )}
        </ScrollCarousel>
      )}

      {courseListing.loading && (
        <ScrollCarousel
          gridGap={{ base: 5, md: "6" }}
          autoPlay={{ interval: 1500 }}
          width={sectionMaxWidth_userPages}
        >
          {createRange(10).map((i) => (
            <Skeleton
              key={i}
              w={{ base: "200px", tablet: "225px", laptop: "270px" }}
              h={{ base: "250px", laptop: "280px" }}
              m={2}
              rounded="md"
            />
          ))}
        </ScrollCarousel>
      )}

      {courseListing.error && <SectionErrorView handleRetry={handleFetch} />}
    </Section>
  );
};

const Section = ({
  headingText,
  renderHeading,
  seeAllLink,
  children,
  ...rest
}) => (
  <Box as="section" mb={10} {...rest}>
    <Flex justifyContent="space-between" mb={5} alignItems="center" as="header">
      {renderHeading ? (
        renderHeading()
      ) : (
        <Heading fontSize="heading.h4">{headingText}</Heading>
      )}

      {seeAllLink && (
        <Box>
          <Button
            link={seeAllLink}
            ghost
            sm
            d={{ base: "none", tablet: "flex" }}
          >
            SEE ALL
          </Button>
          <Button
            link={seeAllLink}
            ghost
            xs
            d={{ base: "flex", tablet: "none" }}
          >
            SEE ALL
          </Button>
        </Box>
      )}
    </Flex>

    {children}
  </Box>
);

export const HomePageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <HomePage {...props} />} />;
};
