import { Route, Switch, useLocation } from "react-router-dom";
import { OnBoardingLayoutRoute } from "..";
import Header from "./Header/Header";
import MainArea from "./MainArea";
import { Box, Flex } from "@chakra-ui/layout";
import breakpoints from "../../theme/breakpoints";
import {
  usePageRefreshAfterLogin,
  useRedirectNewUserToNewPasswordPage,
  useRedirectNonAuthUserToSigninPage,
} from "../../hooks";
import Sidebar from "./Sidebar/Sidebar";
import { PageLoaderLayout } from "../global";

const UserLayout = () => {
  const hasInitRefreshed = usePageRefreshAfterLogin();
  useRedirectNonAuthUserToSigninPage();
  useRedirectNewUserToNewPasswordPage();
  const location = useLocation();
  const landing = location.pathname === "/home-preview";
  const testimonials=location.pathname==="/home-preview/testimonials"
  return (
    <>
      {!landing&& !testimonials ? (
        <Box overflowX="hidden" maxWidth={!landing && breakpoints["4k"]}>
          <Flex>
            <Sidebar />

            <Flex flexDirection="column" flex={1}>
              <Header />
              <div>
                {!hasInitRefreshed ? <PageLoaderLayout /> : <MainArea />}
              </div>
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Box>
          <MainArea />
        </Box>
      )}
    </>
  );
};

const UserLayoutRouter = () => {
  return (
    <Switch>
      <OnBoardingLayoutRoute path="/auth" />
      <Route path="/" render={(props) => <UserLayout {...props} />} />
    </Switch>
  );
};

export const UserLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => <UserLayoutRouter {...props} />} />
  );
};
