import { useToast } from "@chakra-ui/toast";
import { useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useApp } from "../contexts";
import {
  AdminLayoutRoute,
  AssessmentLayoutRoute,
  TakeCourseLayoutRoute,
  UserLayoutRoute,
} from "../layouts";
import "../styles/course-box-card.scss";
import "../styles/courses-row-layout.scss";
import "../styles/globalStyles.scss";
import "../styles/month-schedule.scss";
import "../styles/user-forum-sidebar-link.scss";
import "../styles/user-header-nav-link.scss";
import GlobalProviders from "./GlobalProviders";

function App() {
  return (
    <GlobalProviders>
      <Router>
        <AppConfig />
      </Router>
    </GlobalProviders>
  );
}

export const useAppConfig = () => {
  const appManager = useApp();

  const {
    fetchMetadata,
    fetchCurrentUser,
    handleSetToken,
    handleGetTokenFromClientStorage,
  } = appManager;

  useEffect(() => {
    fetchMetadata();
    const token = handleGetTokenFromClientStorage();
    handleSetToken(token);

    if (token) fetchCurrentUser();
  }, [
    fetchMetadata,
    fetchCurrentUser,
    handleGetTokenFromClientStorage,
    handleSetToken,
  ]);

  useEffect(() => {
    if (appManager.state.user) {
      let DateNow = localStorage.getItem("DateNow");
      if (DateNow)
        setInterval(() => {
          DateNow = +localStorage.getItem("DateNow") + 1000;
          localStorage.setItem("DateNow", DateNow);
        }, 1000);
    }
  }, [appManager.state.user]);

  const toast = useToast();

  useEffect(() => {
    if (
      appManager.state.errorStatuses.metadata ||
      appManager.state.errorStatuses.user
    ) {
      toast.closeAll();
      toast({
        description: "Something went wrong, please refresh the page",
        position: "top",
        status: "error",
        duration: 120000,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appManager.state.errorStatuses.metadata,
    appManager.state.errorStatuses.user,
  ]);
};

function AppConfig() {
  useAppConfig();

  return (
    <Switch>
      <AdminLayoutRoute path="/admin" />
      <AssessmentLayoutRoute
        exact
        path="/courses/take/:course_id/assessment/start/:assessment_id"
      />
      <TakeCourseLayoutRoute path="/courses/take/:course_id/module/:module_id/" />
      <UserLayoutRoute path="/" />
    </Switch>
  );
}

export default App;
