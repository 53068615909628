export const requestCertificateDetailsRes_CourseId_1 = {
  data: {
    courseId: "courseId_1",
    title: "Web Design & Development Crash Course 2021",
    name: "Stanley Chinedu",
    endDate: "May 2021",
  },
};

export const requestCertificateDetailsRes_CourseId_2 = {
  data: {
    courseId: "courseId_2",
    title: "Web Design & Development Crash Course 2021",
    name: "Stanley Chinedu",
    endDate: "April 2021",
  },
};

export const requestCertificateDetailsRes_CourseId_3 = {
  data: {
    courseId: "courseId_3",
    title: "Web Design & Development Crash Course 2021",
    name: "Stanley Chinedu",
    endDate: "June 2021",
  },
};
