import { getEndTime } from "../../../utils";
import { http } from "../http";

/**
 * Endpoint to get `examination-details`
 * @param {string} id - courseId
 *
 * @returns {Promise<{ examination: Examination }>}
 */
export const requestExaminationDetails = async (id, forAdmin) => {
  const path = `/examination${forAdmin ? "/admin" : ""}/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  const examination = {
    id: data.id,
    courseId: data.courseId,
    topic: data.title,
    duration: data.duration,
    questionCount: data.amountOfQuestions,
    endTime: getEndTime(data.startTime, data.duration),
    hasCompleted: data.examinationScoreSheets?.[0] ? true : false,
    minimumPercentageScoreToEarnABadge:
      data.minimumPercentageScoreToEarnABadge || 30, // TODO: remove hard coded data
    questions: data?.examinationQuestions?.map((q, index) => ({
      id: q.id,
      question: q.question,
      file: q.file,
      questionIndex: +q.questionIndex || index,
      options: q.options.map((opt) => ({
        id: opt.id,
        isAnswer: opt.isAnswer,
        name: opt.name,
        optionIndex: +opt.optionIndex,
      })),
    })),
  };
  console.log(data);

  return { examination };
};
/**
 * Endpoint for examination creation
 * @param {{ title: string, courseId: string, duration: number, amountOfQuestions: string, startTime: string }} body
 * @returns {Promise<{ message: string, examination: { id: string } }>}
 */
export const adminCreateExamination = async (body) => {
  const path = `/examination/create`;

  const {
    data: { message, data },
  } = await http.post(path, body);

  const examination = {
    id: data.id,
    title: data.title,
    courseId: data.courseId,
    moduleId: data.moduleId,
    amountOfQuestions: data.amountOfQuestions,
    duration: data.duration,
  };
  console.log(data);
  return { message, examination };
};

/**
 * Endpoint for examination listing
 * @param {string} courseId
 *
 * @returns {Promise<{ examinations: Array<{ id: string, examinationId: string, title: string,  startTime: Date, duration: number }> }>}
 */
export const adminGetExaminationListing = async (courseId) => {
  const path = `/examination/${courseId}`;

  const {
    data: { data },
  } = await http.get(path);

  const examinations = [];
  const exam = {
    id: data.id,
    title: data.title,
    examinationId: data.examinationId,
    duration: data.duration,
    startTime: data.startTime,
  };

  if (!Array.isArray(data)) examinations.push(exam);

  return { examinations };
};

/**
 * Endpoint to submit an `examination`
 * @param {object} body - answers
 *
 * @returns {Promise<{ message: string }>}
 */
export const submitExamination = async (body) => {
  const path = `/examination/scoresheet/create`;

  const {
    data: { message, data },
  } = await http.post(path, body);

  return { message, data };
};

/**
 * Endpoint for examination question creation
 * @param {object} body
 * @returns {Promise<{ message: string }>}
 */
export const adminCreateExaminationQuestion = async (body) => {
  const path = "/examination/question/create";
  console.log(body);
  const {
    data: { message },
  } = await http.post(path, body);

  return { message };
};

/**
 * Endpoint to for admin to edit a examination
 * @param {{ title: ?string, duration: number, amountOfQuestions: number, startTime: ?Date, courseId: string }} body
 *
 * @returns {Promise<{ message: string, examination: { id: string } }>}
 */
export const adminEditExamination = async (examinationId, body) => {
  const path = `/examination/edit/${examinationId}`;

  const {
    data: { message, data },
  } = await http.patch(path, body);

  const examination = {
    id: data[0].id,
  };

  return { message, examination };
};

/**
 * Endpoint for examination modification/update
 * @param {object} body
 * @returns {Promise<{ message: string }>}
 */
export const adminEditExaminationQuestion = async (body) => {
  const path = `/examination/question/edit`;

  const {
    data: { message },
  } = await http.patch(path, body);

  return { message };
};
