import { Box, Flex } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { Route } from "react-router-dom";
import {
  Brand,
  Button,
  Checkbox,
  Heading,
  Input,
  Link,
  PasswordInput,
  Text,
} from "../../../components";
import { useApp } from "../../../contexts";
import {
  useRemoveRefresh,
  useBlockAuthenticatedUserFromPage,
} from "../../../hooks";
import { OnBoardingFormLayout } from "../../../layouts";
import { capitalizeFirstLetter } from "../../../utils";
import crem from "../../../assets/images/crem.jpg";
const SigninPage = () => {
  useRemoveRefresh();
  useBlockAuthenticatedUserFromPage();

  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
  } = useForm();
  const appManager = useApp();

  const onSubmit = async (data) => {
    try {
      await appManager.handleLogin(data);
      // reset();
    } catch (err) {
      console.log(err);

      toast({
        description: capitalizeFirstLetter(err.message),
        position: "top",
        status: "error",
      });

      if (err.message === "Kindly verify email") {
        await appManager.handleResendVerificationEmail(data);
        toast({
          description: capitalizeFirstLetter("verification email sent"),
          position: "top",
          status: "error",
        });

        reset();
      }
    }
  };

  const shouldDisableSubmit = isSubmitting || appManager.loginSuccess;

  return (
    <OnBoardingFormLayout
      renderHeader={() => (
        <>
          <img src={crem} width="120px" />

          <Heading as="h1" fontSize="heading.h3" mt={10}>
            Welcome
          </Heading>
        </>
      )}
      renderInputs={() => (
        <>
          <Input
            id="email"
            type="email"
            label="Email"
            {...register("email", {
              required: "Email can't be empty",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Enter a valid e-mail address",
              },
            })}
            error={errors.email?.message}
          />

          <PasswordInput
            id="password"
            label="Password"
            {...register("password", {
              required: "Password can't be empty",
              minLength: {
                value: 3,
                message: "Password should not be less than 3 characters",
              },
            })}
            error={errors.password?.message}
          />
        </>
      )}
      onSubmit={handleSubmit(onSubmit)}
      renderSubmit={(props) => (
        <Button
          {...props}
          isLoading={isSubmitting}
          disabled={shouldDisableSubmit}
          loadingText="Signing in..."
        >
          Sign in
        </Button>
      )}
      renderFooter={() => (
        <Flex flexDirection="column">
          <Flex justifyContent="space-between">
            <Checkbox label="Remember me" />

            <Link href="/auth/forgot-password">
              <Text color="primary.base">Forgot Password?</Text>
            </Link>
          </Flex>

          <Text pt={3} pb={6} textAlign="center" as="level1" fontWeight="500">
            <i>or</i>
          </Text>

          {/* <Button secondary disabled>
            <Box mr={3}>
              <FcGoogle />
            </Box>
            Sign in with Goggle
          </Button> */}

          <Flex pt={5} justifyContent="center" alignItems="flex-end">
            <Text>Already have an account?</Text>

            <Text color="primary.base" ml={2}>
              <Link href="/auth/signup">Create one</Link>
            </Text>
          </Flex>
        </Flex>
      )}
    />
  );
};

export const SigninPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <SigninPage {...props} />} />;
};
