export const requestMetadataRes = {
  data: {
    userRoles: [
      {
        id: "userRoleId_1",
        name: " super admin",
        createdAt: "2021-09-17T12:48:00.989Z",
        updatedAt: "2021-09-17T12:48:00.989Z",
      },
      {
        id: "userRoleId_2",
        name: "admin",
        createdAt: "2021-09-17T12:48:00.989Z",
        updatedAt: "2021-09-17T12:48:00.989Z",
      },
      {
        id: "userRoleId_3",
        name: "user",
        createdAt: "2021-09-17T12:48:00.989Z",
        updatedAt: "2021-09-17T12:48:00.989Z",
      },
    ],
    departments: [
      {
        id: "departmentId_1",
        name: "Accounting",
        active: true,
        createdAt: "2021-09-17T12:48:01.319Z",
        updatedAt: "2021-09-17T12:48:01.319Z",
      },
      {
        id: "departmentId_2",
        name: "Software development",
        active: true,
        createdAt: "2021-09-17T12:48:01.319Z",
        updatedAt: "2021-09-17T12:48:01.319Z",
      },
      {
        id: "departmentId_3",
        name: "computer science",
        active: true,
        createdAt: "2021-09-20T10:29:27.158Z",
        updatedAt: "2021-09-20T10:29:27.158Z",
      },
    ],
    lessonType: [
      {
        id: "lessonTypeId_1",
        name: "pdf",
        createdAt: "2021-09-17T12:48:01.373Z",
        updatedAt: "2021-09-17T12:48:01.373Z",
      },
      {
        id: "lessonTypeId_2",
        name: "video",
        createdAt: "2021-09-17T12:48:01.373Z",
        updatedAt: "2021-09-17T12:48:01.373Z",
      },
    ],
    libraryType: [
      {
        id: "libraryTypeId_1",
        name: "pdf",
        createdAt: "2021-09-17T12:48:01.373Z",
        updatedAt: "2021-09-17T12:48:01.373Z",
      },
      {
        id: "libraryTypeId_2",
        name: "video",
        createdAt: "2021-09-17T12:48:01.373Z",
        updatedAt: "2021-09-17T12:48:01.373Z",
      },
      {
        id: "libraryTypeId_3",
        name: "audio",
        createdAt: "2021-09-17T12:48:01.373Z",
        updatedAt: "2021-09-17T12:48:01.373Z",
      },
    ],
  },
};
