import { Box, Image, Stack } from "@chakra-ui/react";
import { Text } from "../../../../components";
import { GoQuote } from "react-icons/go";

export const TestimonialCard = ({ item, listItem, w }) => {
  return (
    <Stack
      pos={"relative"}
      m={{ base: 1, tablet: 2 }}
      spacing={listItem ? 0 : 2}
      w={
        w ||
        (listItem
          ? "auto"
          : { base: "400px", tablet: "400px", laptop: "400px" })
      }
      //   maxW={listItem && { base: "200px", tablet: "400px", laptop: "700px" }}
      minH={!listItem && { base: "250px", laptop: "280px" }}
      bg="white"
      rounded="md"
      roundedBottomLeft={"40px"}
      shadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
      flexDirection={listItem ? "row" : "column"}
    >
      <Box p={6} mb={5}>
        <Text>
          <GoQuote color="#800020" variant="Bold" size={"25px"} />
        </Text>
        <Text mt={2}>{item?.quote}</Text>
      </Box>

      <Box
        display="flex"
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        bg={"#800020"}
        h="full"
        w="full"
        pos="relative"
        roundedBottomLeft={"40px"}
        roundedTopRight={"40px"}
      >
        <Box
          border={"3px solid white"}
          w="80px"
          h="80px"
          rounded="full"
          overflow="hidden"
          mt="-60px"
        >
          <Image alt="" src={item?.img} w="full" h="full" objectFit="cover" />
        </Box>
        <Text fontStyle="italic" color="#fff">
          {item?.name}
        </Text>
      </Box>
    </Stack>
  );
};
