import { Box, Flex } from "@chakra-ui/react";
import { pageWrapperSpacing_userPages } from "../../../../theme/breakpoints";
import { Button, Heading, Text } from "../../../../components";
import { FiShoppingCart } from "react-icons/fi";
import { formatPriceToNaira } from "../../../../utils";
import { EmptyState } from "../../Courses/Grades/GradesPage";
export const LibrayDetails = ({ library, buttonTexts, toggleCartItem }) => {
  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Flex>
        <>
          <Box>
            <Box
              display={"grid"}
              gap={10}
              gridTemplateColumns={{
                base: "1fr",
                lg: "1fr 1fr",
                md: "1fr",
                sm: "1fr",
              }}
            >
              <Box h="fit-content" rounded="md" a={5} spacing={8} px={3} py={5}>
                <Heading size="md" mb={4}>
                  {library?.title}
                </Heading>
                <Text>{library?.description}</Text>

                <Flex gap={"10px"} mt={3}>
                  <Text color="gray" textAlign="start">
                    {formatPriceToNaira(Number(library?.price))}
                  </Text>
                </Flex>
                <Text mt={1} textAlign="start">
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "3px",
                    }}
                  >
                    {" "}
                    Instructor:
                  </span>
                  {library?.resourceUploader?.firstName}
                  {library?.resourceUploader?.lastName}
                </Text>
                <Text mt={1} textAlign="start">
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "3px",
                    }}
                  >
                    Last Updated:
                  </span>
                  {new Date(library?.updatedAt).toLocaleString()}
                </Text>
                <Box>
                  <Button
                    onClick={() => {
                      toggleCartItem(library);
                    }}
                    display="flex"
                    my={5}
                    justifyContent="start"
                    secondary
                    leftIcon={<FiShoppingCart />}
                  >
                    {buttonTexts[library?.id] || "Add to cart"}
                  </Button>
                </Box>
              </Box>
              {library?.previewFile ? (
                <Box
                  overflow="hidden"
                  rounded="md"
                  mb={{ base: "30px", md: "30px", lg: "0px", sm: "30px" }}
                  minH="450px"
                  pos="relative"
                  border={"10px solid #800020"}
                >
                  {library?.previewFile && (
                    <iframe
                      title={library?.title}
                      src={library?.previewFile}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      controls
                    />
                  )}
                </Box>
              ) : (
                <EmptyState text={"Oops no library preview found"} />
              )}
            </Box>
          </Box>
        </>
      </Flex>
    </Box>
  );
};
