export const courses = [
  {
    progress: 0,
    coverImage: null,
    title: "Web Design",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
  {
    progress: 90,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
  {
    progress: 23,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
  {
    progress: 40,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
  {
    progress: 100,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },

  {
    progress: 40,
    disabled: true,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
  {
    progress: 40,
    disabled: true,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
  {
    progress: 40,
    disabled: true,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
  {
    progress: 40,
    disabled: true,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
  {
    progress: 40,
    disabled: true,
    coverImage: null,
    title: "Web Development & Development Crash Course 2021",
    lessonCount: 24,
    duration: 610,
    instructor: {
      image: null,
      name: "John Doe",
      role: "Senior Facility Manager",
    },
  },
];
