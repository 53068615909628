import { http } from "../http";

// // PUBLIC API

export const userGetCategoryListing = async (params) => {
  const path = `/categories`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    categories: data.map((category) => ({
      id: category.id,
      name: category.title,
    })),
  };
};
export const landingGetCategoryListing = async () => {
  const path = `/category`;

  try {
    const { data } = await http.get(path);
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
