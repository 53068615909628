import { Route, useHistory, useParams } from "react-router-dom";
import { Flex, Box } from "@chakra-ui/layout";
import { Stack, StackDivider } from "@chakra-ui/react";
import { BreadcrumbItem } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { EmptyState } from "../../../../../layouts";
import {
  Button,
  Heading,
  Table,
  Breadcrumb,
  Link,
  Text,
} from "../../../../../components";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { AdminMainAreaWrapper } from "../../../../../layouts/admin/MainArea/Wrapper";
import {
  adminDeleteMultipleCourses,
  adminGetAssessmentListing,
} from "../../../../../services";
import { useTableRows } from "../../../../../hooks";
import { useEffect } from "react";
import { useState } from "react";
import assessment from "../../../../../mocks/server/controllers/assessment/endpoints";

// const tableProps = {
//   filterControls: [
//     {
//       triggerText: "Sort",
//       queryKey: "sort",
//       triggerIcon: <FaSortAmountUpAlt />,
//       width: "200px",
//       position: "right-bottom",
//       // noFilterTags: true,
//       body: {
//         radios: [
//           {
//             label: "Alphabetically: ascending",
//             queryValue: "asc",
//             additionalParams: { date: false },
//           },
//           {
//             label: "Alphabetically: descending",
//             queryValue: "desc",
//             additionalParams: { date: false },
//           },
//           {
//             label: "Date: ascending",
//             queryValue: "asc",
//             additionalParams: { date: true },
//           },
//           {
//             label: "Date: descending",
//             queryValue: "desc",
//             additionalParams: { date: true },
//           },
//         ],
//       },
//     },
//   ],

//   columns: [
//     {
//       id: "2",
//       key: "title",
//       text: "Quiz Title",
//       fraction: "2fr",
//       renderContent: (data) => (
//         <Link
//           href={`/admin/courses/${data.courseId}/assessment/${data.assessmentId}/overview`}
//         >
//           <Text>{data.text}</Text>
//         </Link>
//       ),
//     },

//     {
//       id: "5",
//       key: "duration",
//       text: "Duration",
//       fraction: "150px",
//     },
//   ],

//   options: {
//     action: [
//       {
//         text: "Edit",
//         link: (assessment) =>
//           `/admin/courses/${assessment.courseId}/assessment/${assessment.id}/overview`,
//       },
//       {
//         isDelete: true,
//       },
//     ],
//     selection: true,
//     multipleDeleteFetcher: async (selectedAssessments) => {
//       await adminDeleteMultipleCourses();
//     },
//     pagination: false,
//   },
// };

const AssessmentListingPage = () => {
  const navigate = useHistory();
  const { courseId, moduleId, assessmentId } = useParams();
  const [details, setDetails] = useState(null);
  const fetcher = async () => {
    console.log("hello there");
    const { data } = await adminGetAssessmentListing(moduleId);
    setDetails(data);
    console.log(data);
  };
  useEffect(() => {
    console.log("useEffect ran");
    fetcher();
  }, []);
  return (
    <>
      <AdminMainAreaWrapper>
        <Breadcrumb
          item2={
            <BreadcrumbItem isCurrentPage>
              <Link href="/admin/courses">Courses </Link>
            </BreadcrumbItem>
          }
          item3={
            <BreadcrumbItem isCurrentPage>
              <Link href="#">Quiz</Link>
            </BreadcrumbItem>
          }
        />

        <Flex
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px"
          borderColor="accent.2"
          paddingBottom={5}
          marginBottom={5}
        >
          <Heading as="h1" fontSize="heading.h3">
            Quiz{" "}
          </Heading>

          <Button link={details?.length === 0 ?`/admin/courses/${courseId}/assessment/${moduleId}/new/overview`:`/admin/courses/${courseId}/assessment/${moduleId}/${details?.id}/overview`}>
           {details?.length === 0? "Add Quiz" :"Edit quiz"} 
          </Button>
        </Flex>
        {details?.length === 0 ? (
          <EmptyState heading="No quiz created for this module yet" />
        ) : (
          <Box as="div" backgroundColor="#fff" w={"700px"} p={5}>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size={"md"}>Quiz Description </Heading>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <span style={{ cursor: "pointer" }}>
                      <MenuButton
                        isActive={isOpen}
                        as={"span"}
                        fontWeight="bold"
                        cursor="pointer"
                      >
                        Action
                      </MenuButton>
                    </span>
                    <MenuList>
                      <MenuItem>
                        <Link
                          href={`/admin/courses/${courseId}/assessment/${moduleId}/${details?.id}/overview`}
                        >
                          Edit
                        </Link>
                      </MenuItem>
                      <MenuItem>Delete</MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Flex>
            <Stack divider={<StackDivider />} spacing="4"></Stack>
            <Box display={"flex"} alignItems={"center"} gap={6} mt={5}>
              <Box as="p" fontSize={"20px"}>
                Title:
              </Box>

              <Box textTransform="capitalize">{details?.title}</Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={6} mt={5}>
              <Box as="p" fontSize={"20px"}>
                Duration:
              </Box>

              <Box>{details?.duration} Minutes</Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={6} mt={5}>
              <Box as="p" fontSize={"20px"}>
                Questions:
              </Box>

              <Box>{details?.amountOfQuestions}</Box>
            </Box>
          </Box>
        )}
      </AdminMainAreaWrapper>
    </>
  );
};

export const AssessmentListingPageRoute = ({ ...rest }) => {
  return (
    <Route {...rest} render={(props) => <AssessmentListingPage {...props} />} />
  );
};

export default AssessmentListingPageRoute;
