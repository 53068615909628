import { Route, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CreateReiews } from "../../services/http/endpoints/reviews";
import { useState } from "react";
import { Button } from "../../components";
import { Box,} from "@chakra-ui/react";
import { Textarea } from "../../components";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { appendFormData, capitalizeFirstLetter } from "../../utils";
import { locale } from "dayjs";
import StarRating from "../../layouts/user/Rating/Rating";
import { Text } from "../../components";
import { CreatePageLayout } from "../../layouts";
import { useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";


export const CreateReviews=()=>{
    const toast = useToast();
const {
  register,
  handleSubmit,
  setValue,
  formState: { errors, isSubmitting },
} = useForm();
    const [reviewerComment, setreviewersComment]=useState("")
    const [reviewerRating, setreviewerRating]=useState(0)
    const courseId=localStorage.getItem("course")
    const {push}=useHistory()
    console.log(courseId);
    const onSubmit= async(data)=>{
     
     console.log(data);
         try{
            data={
                ...data,
                courseId,
                reviewerComment,
                reviewerRating

            }
            const body = appendFormData(data);
            console.log(body);
            const { message, reviews } = await CreateReiews(body)
            push("/home")
            toast({
                description: capitalizeFirstLetter(message),
                position: "top",
                status: "success",
              });
         }
         catch (error) {
            toast({
              description: capitalizeFirstLetter(error.message),
              position: "top",
              status: "error",
            });
          }
    }


    return(
        <>
        <CreatePageLayout title={"Create Review"}  submitButtonText={"Submit"}
    onSubmit={handleSubmit(onSubmit)}  submitButtonIsLoading={isSubmitting}>

           <Box as="div" display={"flex"} flexDirection={"column"} gap={7} >
 
          <Textarea onChange={(e)=>{
                
                setreviewersComment(e.target.value)}}   label="Please drop a review"
                isRequired
                id="reviewerId"
                {...register("reviewerId", {
                  required: "Review is required",
                })}
                error={errors.title?.message}
                height={"300px"}/>
           <Box mb={6}>
              <Box mb={2}>
              <Text level="level3">
                you can rate this course from 1-5
            </Text>
              </Box>
           <StarRating rating={reviewerRating} setRating={setreviewerRating}/>
           </Box>
           </Box>
           
         
        </CreatePageLayout>
         
        </>
    )
}
export const CreateReviewsPageRoute = ({ component: Component, ...rest }) => {
    return (
      <Route {...rest} render={(props) => <CreateReviews {...props} />} />
    );
  };
  