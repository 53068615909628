import { Box, Button, Flex, Skeleton } from "@chakra-ui/react";
import { Image, Link, Text } from "..";
import { truncateText } from "../../utils";
import Rated from "../../layouts/user/Rated";
import { useApp } from "../../contexts";

export const CourseOverviewCard2 = ({
  course,
  controlledDimensions,
  isLoading,
  layout2,
}) => {
  const appManager = useApp();
  const metadata = appManager.state.metadata;
  const catItems = metadata?.courseCategory;
  return (
    <div>
      {isLoading ? (
        <Skeleton
          w={!controlledDimensions && { base: "200px", tablet: "300px" }}
          h={{ base: "240px", tablet: "180px" }}
        ></Skeleton>
      ) : (
        <Box
          pos={"relative"}
          p={{ base: 1, tablet: 3 }}
          pb={3}
          w={{ base: "300px", tablet: "300px" }}
          minHeight={"350px"}
          bgGradient={!layout2 && "linear(to-r, primary.base, primary.hover)"}
          bg={layout2 && "white"}
          shadow={layout2 && "0px 4px 10px rgba(0, 0, 0, 0.25)"}
          color={layout2 ? "black" : "white"}
          rounded="md"
        >
          <Flex
            gridGap="3"
            mb={{ base: 1, tablet: 4 }}
            flexDirection={
              layout2 ? "column" : { base: "column", tablet: "row" }
            }
          >
            <Image
              src={course.image}
              alt={course.title}
              height={layout2 ? "160px" : { base: "160px", tablet: "160px" }}
              width={layout2 ? "100%" : { base: "100%", tablet: "100%" }}
              rounded="md"
              flexShrink={0}
            />

            <Box>
              {layout2 ? (
                <Box
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <Link
                    href={
                      !isNaN(course.progress)
                        ? `/modules/${course.id}`
                        : `/general/courses/${course.id}`
                    }
                  >
                    <Box as="div" fontSize={18} fontWeight={"bold"}>
                      {course.title}
                    </Box>
                  </Link>
                </Box>
              ) : (
                <Box
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <Link href={`/modules/${course.id}`}>
                    <Text
                      bold
                      mb={{ base: 1, tablet: 4 }}
                      d={{ base: "none", tablet: "block" }}
                    >
                      {course.title}
                    </Text>
                    <Text
                      bold
                      mb={{ base: 1, tablet: 4 }}
                      d={{ base: "block", tablet: "none" }}
                    >
                      {course.title}
                    </Text>
                  </Link>
                </Box>
              )}

              <Box w="fit-content" my={1}>
                <Link href={`/users/${course.instructor.id}`}>
                  <Flex
                    alignItems="center"
                    _hover={{
                      textDecoration: "underline",
                    }}
                  >
                    <Text opacity={0.8} isTruncated>
                      By {course.instructor.name}
                    </Text>
                  </Flex>
                </Link>
              </Box>
            </Box>
          </Flex>
          {!isNaN(course.progress) && (
            <Flex gap={3} alignItems="center" mb={3}>
              <Box
                as="div"
                height="6px"
                w={"100%"}
                borderRadius={"10px"}
                bg="#C34A691A"
                display="flex"
              >
                <Box
                  as="div"
                  height="100%"
                  w={`${course.progress}%`}
                  backgroundColor="#800020"
                  borderRadius="10px"
                ></Box>
              </Box>
              <span>{course.progress}%</span>
            </Flex>
          )}

          <Box w="fit-content" mt={-3} mb={2}>
            {course?.reviews?.length === 0 ? (
              <Box
                _hover={{
                  textDecoration: "underline",
                }}
              >
                <Link
                  href={`/rev/${course.id}`}
                  onClick={localStorage.setItem("course", course.id)}
                >
                  <Text as="level4" bold mb={{ base: 1, tablet: 2 }}>
                    Rate this course
                  </Text>
                </Link>
              </Box>
            ) : (
              <Flex gap={2}>
                <Rated val={course.ratingsTotal / course.ratingsCount} />
                <Text level="level1" color="orange">
                  {course.ratingsTotal / course.ratingsCount}
                </Text>
                <Text level="level1">({course.ratingsCount})</Text>
              </Flex>
            )}
          </Box>
          <Flex>
            <Text as={layout2 ? "level6" : "level5"}>
              {course.noofmodules} Modules
            </Text>
          </Flex>

          {catItems
            ?.filter((cat) => cat.id === course.categoryId)
            .map((cat) => (
              <div key={cat.id}>
                <Button
                  backgroundColor={"#5E0101"}
                  outline={"2px solid #fff"}
                  color={"#fff"}
                  pos={"absolute"}
                  top={"10"}
                  left={6}
                  _hover={{ backgroundColor: "green" }}
                  borderRadius={7}
                >
                  {truncateText(cat.categoryName, 12)}
                </Button>
              </div>
            ))}
        </Box>
      )}
    </div>
  );
};
