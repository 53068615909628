import { getEndTime } from "../../../utils";
import { http } from "../http";

/**
 * Endpoint to get `assessment-details`
 * @param {string} id - assessmentId
 *
 * @returns {Promise<{ assessment: Assessment }>}
 */
export const requestAssessmentDetails = async (id, forAdmin) => {
  console.log("here2");
  const path = `/quiz${forAdmin ? "/admin" : ""}/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  const assessment = {
    id: data.id,
    courseId: data.courseId,
    topic: data.title,
    duration: data.duration,
    questionCount: data.amountOfQuestions,
    startTime: data.startTime,
    endTime: getEndTime(data.startTime, data.duration),
    hasCompleted: data.assessmentScoreSheets?.[0] ? true : false,
    minimumPercentageScoreToEarnABadge:
      data.minimumPercentageScoreToEarnABadge || 30, // TODO: remove hard coded data
    questions: data.quizQuestions
      ? data.quizQuestions.map((q, index) => ({
          id: q.id,
          question: q.question,
          // questionIndex: +q.questionIndex, // TODO: propose this field to be implemented by the BACKEND team
          questionIndex: index, // TODO: might remove `index`
          options: q.options.map((opt) => ({
            id: opt.id,
            isAnswer: opt.isAnswer,
            name: opt.name,
            optionIndex: +opt.optionIndex,
          })),
        }))
      : [],
  };

  return { assessment };
};

/**
 * Endpoint to submit an `assessment`
 * @param {object} body - answers
 *
 * @returns {Promise<{ message: string }>}
 */
export const submitAssessment = async (body) => {
  const path = `/quiz/scoresheet/create`;

  const {
    data: { message },
  } = await http.post(path, body);

  return { message };
};

/**
 * Endpoint for assessment creation
 * @param {{ title: string, courseId: string, duration: number, amountOfQuestions: string , moduleId:string }} body
 * @returns {Promise<{ message: string, assessment: { id: string } }>}
 */
export const adminCreateAssessment = async (body) => {
  const path = `/examination/create`;

  const {
    data: { message, data },
  } = await http.post(path, body);

  const assessment = {
    id: data.id,
  };

  return { message, assessment };
};

/**
 * Endpoint for assessment question creation
 * @param {object} body
 * @returns {Promise<{ message: string }>}
 */
export const adminCreateAssessmentQuestion = async (body) => {
  const path = "/quiz/question/create";

  const {
    data: { message },
  } = await http.post(path, body);

  return { message };
};

/**
 * Endpoint for assessment modification/update
 * @param {object} body
 * @returns {Promise<{ message: string }>}
 */
export const adminEditAssessmentQuestion = async (body) => {
  const path = `/quiz/question/edit`;

  const {
    data: { message },
  } = await http.patch(path, body);

  return { message };
};

/**
 * Endpoint for assessment listing
 * @param {string} courseId
 *
 * @returns {Promise<{ assessments: Array<{ id: string, courseId: string, title: string,  startTime: Date, duration: number }> }>}
 */
export const adminGetAssessmentListing = async (moduleId) => {
  const path = `/examination/admin/${moduleId}`;

  const {
    data: { data },
  } = await http.get(path);

  return { data };
};

/**
 * Endpoint to for admin to edit a assessment
 * @param {{ title: ?string, duration: number, amountOfQuestions: number, startTime: ?Date, courseId: string }} body
 *
 * @returns {Promise<{ message: string, assessment: { id: string } }>}
 */
export const adminEditAssessment = async (assessmentId, body) => {
  const path = `/quiz/edit/${assessmentId}`;

  const {
    data: { message, data },
  } = await http.patch(path, body);

  const assessment = {
    id: data[0].id,
  };

  return { message, assessment };
};
