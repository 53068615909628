import { rest } from "msw";
import { getUrl } from "../../../http";
import { handleSuccessResponse } from "../../helpers";
import {
  userForumGetCommentsRes_questionId_1,
  userForumGetCommentsRes_questionId_2,
  userForumGetCommentsRes_questionId_3,
  userForumAddCommentRes,
  // userForumReplyACommentRes,
  userForumGetYourAnswersRes,
  // userForumEditCommentRes,
  userForumDeleteCommentRes,
  userForumEditCommentRes_commentId_2,
  userForumEditCommentRes_replyId_3,
  userForumEditCommentRes_commentId_4,
  userForumCreateExpressionRes,
  userForumEditCommentRes_replyId_2,
} from "./responses";

const userForumGetComments = [
  rest.get(
    getUrl("/forum/comment/question/questionId_1hk4"),
    handleSuccessResponse(userForumGetCommentsRes_questionId_1)
  ),
  rest.get(
    getUrl("/forum/comment/question/questionId_2k4j4"),
    handleSuccessResponse(userForumGetCommentsRes_questionId_2)
  ),
  rest.get(
    getUrl("/forum/comment/question/questionId_3k4j4"),
    handleSuccessResponse(userForumGetCommentsRes_questionId_3)
  ),
];

const userForumEditComment = [
  rest.patch(
    getUrl("/forum/comment/replyId_3rrr"),
    handleSuccessResponse(userForumEditCommentRes_replyId_3)
  ),
  rest.patch(
    getUrl("/forum/comment/replyId_2rr"),
    handleSuccessResponse(userForumEditCommentRes_replyId_2)
  ),
  rest.patch(
    getUrl("/forum/comment/commentId_2rrr"),
    handleSuccessResponse(userForumEditCommentRes_commentId_2)
  ),
  rest.patch(
    getUrl("/forum/comment/commentId_3rrr"),
    handleSuccessResponse(userForumEditCommentRes_commentId_4)
  ),
  rest.patch(
    getUrl("/forum/comment/commentId_4rrr"),
    handleSuccessResponse(userForumEditCommentRes_commentId_4)
  ),
];

const userForumDeleteComment = [
  rest.delete(
    getUrl("/forum/comment/replyId_1rrr"),
    handleSuccessResponse(userForumDeleteCommentRes)
  ),
  rest.delete(
    getUrl("/forum/comment/replyId_3rrfr"),
    handleSuccessResponse(userForumDeleteCommentRes)
  ),
  rest.delete(
    getUrl("/forum/comment/replyId_2rr"),
    handleSuccessResponse(userForumDeleteCommentRes)
  ),
  rest.delete(
    getUrl("/forum/comment/commentId_1rrrr"),
    handleSuccessResponse(userForumDeleteCommentRes)
  ),
  rest.delete(
    getUrl("/forum/comment/commentId_2rere"),
    handleSuccessResponse(userForumDeleteCommentRes)
  ),
  rest.delete(
    getUrl("/forum/comment/commentId_3rr"),
    handleSuccessResponse(userForumDeleteCommentRes)
  ),
  rest.delete(
    getUrl("/forum/comment/commentId_4rrr"),
    handleSuccessResponse(userForumDeleteCommentRes)
  ),
];

const userForumCreateExpression = [
  rest.post(
    getUrl("/forum/comment/commentExpressionrrr"),
    handleSuccessResponse(userForumCreateExpressionRes)
  ),
];

const userForumAddComment = rest.post(
  // TODO: change `method`
  getUrl("/forum/comment/createrryr"), // TODO: change `path`
  handleSuccessResponse(userForumAddCommentRes)
);

// const userForumAddReply = rest.post(
//   getUrl("/forum/comment/create"),
//   handleSuccessResponse(userForumReplyACommentRes)
// );

const userForumGetYourAnswers = rest.get(
  // TODO: change `method`
  getUrl("/forum/your-answerswwrrtw"), // TODO: change `path`
  handleSuccessResponse(userForumGetYourAnswersRes)
);

const forumComment = [
  ...userForumCreateExpression,
  ...userForumGetComments,
  ...userForumEditComment,
  ...userForumDeleteComment,
  userForumGetYourAnswers,
  userForumAddComment,
  // userForumAddReply,
];

export default forumComment;
