import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import { Button, Heading, Image, Link, Text } from "../../../../components";
import {
  mainAreaHeight_userPages,
  pageWrapperSpacing_userPages,
} from "../../../../theme/breakpoints";
import { truncateText } from "../../../../utils";
import { useEffect } from "react";
import { userGetPurcasedCourseDetails } from "../../../../services";
import { useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useGoBack } from "../../../../hooks";
import { BiArrowBack } from "react-icons/bi";
import { MdPlayCircleOutline } from "react-icons/md";
import { BsPlayFill } from "react-icons/bs";
import { Grid } from "@chakra-ui/react";
import { Fragment } from "react";
import { CourseOverviewCard, Tabs } from "../../../../components";
import { CourseOverviewCard2 } from "../../../../components/Cards/CourseOverviewCard2";
import { EmptyState } from "../../../../layouts";
import { createRange } from "../../../../utils";
import { ReviewsPage } from "../../ReviewsPage";
import { CheckCircle, CheckCircleRounded } from "@material-ui/icons";
export const MyCoursesPage = ({ course }) => {
  return (
    <Box {...pageWrapperSpacing_userPages} pt={8} w={"80%"}>
      <Tabs
        tabs={[
          {
            id: "about",
            label: "About",
            content: <AboutPage course={course} />,
          },
          {
            id: "ratings",
            label: "Ratings",
            content: <ReviewsPage />,
          },
        ]}
      />
    </Box>
  );
};

export const AboutPage = ({ course }) => {
  return (
    <>
      <Text as="level2" color="#800020">
        Objectives of this course
      </Text>

      {course?.objectives?.map((dat) => (
        <Flex key={dat} alignItems={"center"} gap={3} mt={3}>
          <CheckCircleRounded
            style={{
              color: "#800020",
            }}
          />
          {dat}
        </Flex>
      ))}
    </>
  );
};

export const CourseListingGridLayout = ({
  courses,
  err,
  loading,
  onErrRetry,
  renderCard,
}) => (
  <>
    <Grid
      gridTemplateColumns={{
        base: "repeat(auto-fit, minmax(200px, 1fr))",
        tablet: "repeat(200, minmax(200px, 1fr))",
        laptop: "repeat(200, minmax(250px, 1fr))",
      }}
      gridGap={8}
      alignItems="flex-start"
      py={2}
    >
      {courses &&
        courses.map((course, index) => (
          <>
            {renderCard ? (
              <Fragment key={course.id}>{renderCard(course)}</Fragment>
            ) : (
              <CourseOverviewCard
                key={course.id}
                course={course}
                controlledDimensions
                layout2
              />
            )}
          </>
        ))}

      {loading &&
        createRange(8).map((i) => (
          <CourseOverviewCard key={i} controlledDimensions isLoading />
        ))}
    </Grid>

    {err && (
      <EmptyState
        cta={<Button onClick={onErrRetry}>Try Again</Button>}
        heading="Oops An Error Occurred"
        description="An unexpected error occurred, please try again later"
      />
    )}
  </>
);

export const CourseListingGridLayout2 = ({
  courses,
  err,
  loading,
  onErrRetry,
  renderCard,
}) => (
  <>
    <Grid
      gridTemplateColumns={{
        base: "repeat(auto-fit, minmax(200px, 1fr))",
        tablet: "repeat(200, minmax(200px, 1fr))",
        laptop: "repeat(200, minmax(250px, 1fr))",
      }}
      gridGap={8}
      alignItems="flex-start"
      py={2}
    >
      {courses &&
        courses.map((course) => (
          <>
            {renderCard ? (
              <Fragment key={course.id}>{renderCard(course)}</Fragment>
            ) : (
              <CourseOverviewCard2
                key={course.id}
                course={course}
                controlledDimensions
                layout2
              />
            )}
          </>
        ))}

      {loading &&
        createRange(8).map((i) => (
          <CourseOverviewCard key={i} controlledDimensions isLoading />
        ))}
    </Grid>

    {err && (
      <EmptyState
        cta={<Button onClick={onErrRetry}>Try Again</Button>}
        heading="Oops An Error Occurred"
        description="An unexpected error occurred, please try again later"
      />
    )}
  </>
);

const NewModulesPage = () => {
  const [preview, setPreview] = useState(false);
  const { id } = useParams();
  const [course, setCourse] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const toast = useToast();
  const handleGoBack = useGoBack();

  useEffect(() => {
    userGetPurcasedCourseDetails(id)
      .then((res) => {
        setCourse(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast({
          description: "You do not have access to that course",
          position: "top",
          status: "error",
        });

        history.push("/");
      });
    //eslint-disable-next-line
  }, []);

  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Flex>
        {isLoading === true ? (
          <Spinner />
        ) : course !== undefined ? (
          <>
            <Stack mr={4} pr={4} flex={1} height={mainAreaHeight_userPages}>
              <Flex as="section">
                <Button asIcon mr={2} onClick={handleGoBack}>
                  <BiArrowBack />
                </Button>
                <Heading
                  as="h1"
                  fontSize="heading.h3"
                  d="flex"
                  alignItems="center"
                >
                  {course.title}
                </Heading>
              </Flex>
              <Stack spacing={5} as="section">
                <Box
                  overflow="hidden"
                  rounded="md"
                  width="100%"
                  height="450px"
                  pos="relative"
                  border={"10px solid #800020"}
                >
                  {course.previewUrl ? (
                    <video
                      title={course.title}
                      src={course.previewUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      controls
                    />
                  ) : preview ? (
                    <video
                      title={course.title}
                      src={course.preview}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                      autoPlay
                      controls
                    />
                  ) : (
                    !preview && (
                      <>
                        <Image
                          src={course.image}
                          alt={course.title}
                          width="100%"
                          height="100%"
                        />

                        <Flex
                          onClick={() => setPreview(true)}
                          pos="absolute"
                          top={0}
                          left={0}
                          alignItems="center"
                          justifyContent="center"
                          color="#fff"
                          bg="rgba(0, 0, 0, .5)"
                          width="100%"
                          height="100%"
                          flexDirection="column"
                          cursor="pointer"
                          sx={{
                            "svg, p": {
                              transition: "all .3s ease-in-out",
                            },
                            "&:hover svg": {
                              transform: "scale(1.2)",
                            },
                            "&:hover p": {
                              transform: "translateY(-10px)",
                            },
                          }}
                        >
                          <BsPlayFill fontSize="100px" />
                          <Text as="level1">Preview this course</Text>
                        </Flex>
                      </>
                    )
                  )}
                </Box>
              </Stack>
            </Stack>
            <Stack
              as="aside"
              w="350px"
              h="fit-content"
              rounded="md"
              a={5}
              spacing={8}
              px={3}
              py={5}
            >
              <Section
                renderHeader={() => (
                  <Flex justifyContent="space-between">
                    <Flex>
                      <Heading fontSize="text.level1">Curriculum&nbsp;</Heading>
                      <Heading fontSize="text.level1" medium>
                        ({course.purchasedCourseModules.length} Module
                        {course.purchasedCourseModules.length < 2 ? "" : "s"})
                      </Heading>
                    </Flex>
                  </Flex>
                )}
                showHeight="100%"
              >
                {course.purchasedCourseModules.map(
                  (purchasedModule, moduleIndex) => (
                    <ModuleAccordion
                      key={purchasedModule.id}
                      moduleIndex={moduleIndex}
                      module={purchasedModule}
                      isOpen={moduleIndex === 0 && true}
                      courseId={course.id}
                      moduleAccess={
                        moduleIndex === 0 ||
                        course.purchasedCourseModules[moduleIndex - 1]
                          .status === "Completed"
                      }
                    />
                  )
                )}
              </Section>
            </Stack>
          </>
        ) : (
          <Heading color="">{"Loading....."}</Heading>
        )}
      </Flex>
      <Box>
        <MyCoursesPage course={course} />
      </Box>
    </Box>
  );
};

export const ModuleAccordion = ({
  moduleIndex,
  module,
  courseId,
  isOpen = false,
  moduleAccess,
}) => {
  return (
    <Box pt={5} pb={0}>
      <Box>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          _hover={{ opacity: 0.8, cursor: "pointer" }}
        >
          <Box w="100%">
            <Accordion allowMultiple w={"100%"}>
              <AccordionItem borderTop="none" borderBottom="1px solid #E8E8E8">
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton
                        _hover={{
                          bg: "#fff",
                        }}
                        pos={"relative"}
                        w={"320px"}
                        border={"none"}
                        _focus={{
                          border: "none",
                          bg: "#fff",
                        }}
                        paddingLeft="0px"
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          color="#1D060B"
                        >
                          {isExpanded ? (
                            <Heading
                              as="h3"
                              fontSize="text.level3"
                              width="250px"
                            >
                              Module {moduleIndex + 1} -{" "}
                              {module.courseModule.name}
                            </Heading>
                          ) : (
                            <Heading as="h3" fontSize="text.level3">
                              Module {moduleIndex + 1} -{" "}
                              {truncateText(module.courseModule.name, 20)}
                            </Heading>
                          )}
                        </Box>
                        <Box as="div" pos={"absolute"} right={0} top={1}>
                          {isExpanded ? (
                            <AiFillCaretUp color="#800020" />
                          ) : (
                            <AiFillCaretDown color="#800020" />
                          )}
                        </Box>
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pl={4}>
                      {module.purchasedModuleLessons.length > 0 ? (
                        <div>
                          {module.purchasedModuleLessons.map(
                            (purchasedLesson, li) => (
                              <Flex
                                key={purchasedLesson.id}
                                alignItems="start"
                                flexDirection={"column"}
                                justifyContent="space-between"
                                marginBottom="15px"
                              >
                                <Flex
                                  w={"280px"}
                                  alignItems="center"
                                  justifyContent={"space-between"}
                                >
                                  <Flex alignItems={"center"}>
                                    <Text as="level3" bold color="#800020">
                                      {purchasedLesson.moduleLesson.title}
                                    </Text>
                                  </Flex>
                                  {moduleAccess &&
                                    (li === 0 ||
                                      module.purchasedModuleLessons[li - 1]
                                        .status === "Completed") && (
                                      <Link
                                        href={`/courses/take/${courseId}/module/${module.id}/lessons/${purchasedLesson.id}`}
                                      >
                                        <MdPlayCircleOutline
                                          style={{
                                            color: "#800020",
                                            cursor: "pointer",
                                            fontSize: "22px",
                                          }}
                                        />
                                      </Link>
                                    )}
                                </Flex>
                              </Flex>
                            )
                          )}
                        </div>
                      ) : (
                        <Text as="level4">No lessons</Text>
                      )}
                      {module.courseModule.examination && (
                        <Link
                          onClick={() =>
                            localStorage.setItem("moduleId", module.id)
                          }
                          href={`/courses/take/${courseId}/module/${module.id}/assessment/${module.courseModule.id}`}
                        >
                          <Flex alignItems={"center"} gap={2}>
                            <Text
                              color="purple.500"
                              _hover={{
                                textDecoration: "underline",
                              }}
                              as="level3"
                              bold
                              mb={{ base: 1, tablet: 2 }}
                            >
                              Quiz-
                            </Text>
                            <Text
                              color="purple.500"
                              _hover={{
                                textDecoration: "underline",
                              }}
                              as="level3"
                              bold
                              mb={{ base: 1, tablet: 2 }}
                            >
                              {module.courseModule.examination.title}
                            </Text>
                          </Flex>
                        </Link>
                      )}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export const Section = ({
  heading,
  renderHeader,
  children,
  showHeight = "100px",
  showMoreFeature = true,
  ...rest
}) => {
  const [showMore] = useState(false);
  const contentWrapperRef = useRef();

  return (
    <Stack spacing={5} as="section" {...rest}>
      {renderHeader ? (
        renderHeader()
      ) : (
        <Heading fontSize="text.level2">{heading}</Heading>
      )}

      <Stack
        spacing={5}
        overflowY="hidden"
        transition=".3s"
        h={
          !showMoreFeature
            ? "auto"
            : showMore
            ? contentWrapperRef.current?.clientHeight + 10 + "px"
            : showHeight
        }
      >
        <Box ref={contentWrapperRef}>{children}</Box>
      </Stack>
    </Stack>
  );
};

export const NewModulesPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <NewModulesPage {...props} />} />;
};
