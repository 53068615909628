import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCache } from '../../../../../contexts';
import useComponentIsMount from '../../../../../hooks/useComponentIsMount';
import { userGetlesssonDetails } from '../../../../../services';

const useLessonDetails = courseId => {

	const { handleGetOrSetAndGet } = useCache();
	const componentIsMount = useComponentIsMount();

	const [lessonDetails, setLessonDetails] = useState();
	const [courseDetails, setCourseDetails] = useState({
		data: null,
		loading: false,
		err: null,
	});

	let params = useParams();
	const id = params.course_id || params.id;

	const fetcher = useCallback(async () => {
		setCourseDetails({ loading: true });
		const course = await userGetlesssonDetails(id);
		setLessonDetails(course);
		setCourseDetails({ data: course });
		setCourseDetails({ loading: false });

		return course;
	}, [id]);

	const fetchCourseDetails = useCallback(
		async bypassCache => {
			setCourseDetails({ loading: true });

			try {
				let courseDetails = await handleGetOrSetAndGet(
					id,
					fetcher,
					lessonDetails,
					bypassCache
				);

				if (componentIsMount) setCourseDetails({ data: courseDetails });
			} catch (err) {
				if (componentIsMount) setCourseDetails({ err: err.message });
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[id, componentIsMount]
	);

	return {
		courseDetails,
		fetchCourseDetails,
		setCourseDetails,
	};
};

export default useLessonDetails;
