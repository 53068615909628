export const userCourseDetailsRes_courseId_1 = {
  message: "course updated successfully",
  data: {
    id: "courseId_1",
    description: "This course will teach you about javascript",
    disabled: false,
    title: "PHP course for mid-level engineers",
    departmentId: "92ab95a4-47b3-4812-a23e-20951bd4b839",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia,molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborumnumquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentiumoptio, eaque rerum! Provident similique accusantium nemo autem. Veritatisobcaecati tenetur iure eius earum ut molestias architecto voluptate aliquamnihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint.",
    thumbnail:
      "https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=869&q=80",
    lesson: [
      {
        id: "lessonId_1",
        title: "JavaScripts",
        startTime: "2021-09-23T11:03:03.833Z",
        endTime: "2021-09-25T11:03:03.833Z",
        lessonType: {
          id: "4adf4cda-d69b-4d95-ad44-d1118529e246",
          name: "pdf",
        },
      },
    ],
    courseTracking: null,
    assessment: [
      {
        id: "assessmentId_1",
        title: "Html assessment",
        duration: 60,
        amountOfQuestions: null,
      },
    ],
    instructor: { profilePics: null, firstName: "shalom", lastName: "brain" },
    duration: 2880,
  },
};

export const userCourseDetailsRes_courseId_3 = {
  message: "course updated successfully",
  data: {
    id: "courseId_3",
    description: "This course  me",
    disabled: false,
    title: "mock titles",
    departmentId: "92ab95a4-47b3-4812-a23e-20951bd4b839",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia,molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborumnumquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentiumoptio, eaque rerum! Provident similique accusantium nemo autem. Veritatisobcaecati tenetur iure eius earum ut molestias architecto voluptate aliquamnihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit,tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit,quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint.",
    thumbnail:
      "https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=869&q=80",
    lesson: [
      {
        id: "lessonId_1",
        title: "JavaScripts",
        startTime: "2021-09-23T11:03:03.833Z",
        endTime: "2021-09-25T11:03:03.833Z",
        lessonType: {
          id: "4adf4cda-d69b-4d95-ad44-d1118529e246",
          name: "pdf",
        },
      },
    ],
    courseTracking: null,
    assessment: [
      {
        id: "assessmentId_1",
        title: "Html assessment",
        duration: 60,
        amountOfQuestions: null,
      },
      {
        id: "assessmentId_2",
        title: "CSS assessment",
        duration: 60,
        amountOfQuestions: null,
      },
    ],
    instructor: { profilePics: null, firstName: "shalom", lastName: "brain" },
    duration: 2880,
    examination: {
      id: "examinationId_1",
      duration: 70,
      questionCount: 20,
      minimumPercentageScoreToEarnABadge: 80,
    },
  },
};
