import { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { http } from '../../../services/http/http';
import { Button } from '@chakra-ui/react';

const VerifyEmail = () => {
	// useRemoveRefresh();
	// useBlockAuthenticatedUserFromPage();

	const [verificationStatus, setVerificationStatus] = useState("verifying")
	const [errorMessage, setErrorMessage]= useState("")

	const {
		location: { pathname },
		push,
	} = useHistory();

	const verifyUserEmail = async (token) => {
		try {
			await http.post('/verifyEmail', {}, {token});
			setVerificationStatus('success')
			setTimeout(() => {
				push('/auth/signin');
			}, 1500);
		} catch (error) {
			setErrorMessage(error.message)
			setVerificationStatus("error")
		}
	};

	useEffect(() => {
		const splitUrl = pathname.split('/');
		const token = splitUrl[splitUrl.length - 1];
		verifyUserEmail(token);
		//eslint-disable-next-line
	}, []);

	return (
		<div>
			{
				verificationStatus === 'verifying' &&
				<h1>Verifying Email...</h1>
			}
			
			{
				verificationStatus=== 'success' &&
				<h1>Email Verification Successful. Redirecting...</h1>
			}
			
			{
				verificationStatus=== 'error' &&
				<div style={{textAlign: 'center'}}>
					<h1>{errorMessage}. Please login</h1>
					<Button 
						marginTop="30px"
						onClick={()=> push('/auth/signin')}
						backgroundColor="#800020"
						color="#fff"
						_hover={{ backgroundColor:"#800020cc" }}
					>
						Go to Login
					</Button>
				</div>
				
			}
		</div>
	);
};

export const VerifyEmailRoute = ({ ...rest }) => {
	return <Route {...rest} render={props => <VerifyEmail {...props} />} />;
};
