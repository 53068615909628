const breakpoints = {
  "mobile-s": "320px",
  "mobile-m": "375px",
  "mobile-l": "425px",
  tablet: "768px",
  laptop: "1024px",
  "laptop-l": "1440px",
  "4k": "2560px",
};

export const pageWrapperSpacing_userPages = {
  marginX: { base: 0, laptop: 6 },
  paddingX: { base: 2, laptop: 0 },
};

export const sectionMaxWidth_userPages = {
  base: "100vw",
  tablet: "calc(100vw - 240px)",
  laptop: "calc(100vw - 285px)",
};

export const mainAreaHeight_userPages = {
  base: "calc(100vh - 100px)",
  tablet: "calc(100vh - 60px)",
};

export default breakpoints;
