import { Box, Flex, Stack } from "@chakra-ui/react";
import { Route } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Heading, Button, Text, Ratings } from "../../components";
import { pageWrapperSpacing_userPages } from "../../theme/breakpoints";
import { useGoBack } from "../../hooks";
import { userGetOngoingCourses } from "../../services";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import StarRating from "../../layouts/user/Rating/Rating";
import Rated from "../../layouts/user/Rated";
import { GetReviews } from "../../services/http/endpoints/reviews";
import { object } from "prop-types";
// const reviews = [
//   {
//     id: 1,
//     user: {
//       name: "John Doe",
//     },
//     rating: 5,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ctetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     date: "2 min ago",
//   },
//   {
//     id: 2,
//     user: {
//       name: "John Doe",
//     },
//     rating: 2,
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ctetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//     date: "2 min ago",
//   },
// ];

export const ReviewsPage = () => {
  const {id:courseId}=useParams()
  const handleGoBack = useGoBack();
  console.log(courseId);
  const [reviews, setReviews] = useState("");
  const fetchReviews = async () => {
    const data = await GetReviews(courseId);
    setReviews(data);
    console.log(data);
    
  };
  console.log(reviews);
  useEffect(() => {
    fetchReviews();
  }, []);
  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Flex>
        <Stack mr={4} pr={4} flex={1} spacing={5} maxWidth="700px">
         
          <Text as="level1">
          
            Ratings about this course
          
          </Text>
         <Box>
         <Box mb={3} display={"flex"} gap={2}>
          {reviews?.data?.map((dat, i)=>(
          <Box>
              <Flex gap={4} mb={3}>
              {`(${i+1})`}
             <Rated val={dat.reviewerRating}/>
              </Flex>
             <Text as="level4">
             {dat.reviewer.firstName} {dat.reviewer.lastName}  rated this course {dat.reviewerRating} out of 5
          </Text>
          </Box>))}
        
        
          </Box> 
          
         </Box>
         
          
        </Stack>
      </Flex>
    </Box>
  );
};

export const ReviewsPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <ReviewsPage {...props} />} />;
};
