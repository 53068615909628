import { Avatar, Box, Flex, HStack, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { Route, useParams } from "react-router-dom";
import { CourseListingGridLayout } from ".";
import {
  Button,
  CourseProductCard,
  Heading,
  Image,
  Link,
  Text,
  Textarea,
} from "../../components";
import { useApp } from "../../contexts";
import { useGoBack } from "../../hooks";
import { requestMyDataRes } from "../../mocks/server/controllers/auth/me_response";
import { http } from "../../services";
import { pageWrapperSpacing_userPages } from "../../theme/breakpoints";
import { InstructorRating } from "./Rating/Rating";
import StarRating from "../../layouts/user/Rating/Rating";

const UserInfoPage = () => {
  const handleGoBack = useGoBack();

  const {
    state: { user: currentUser },
  } = useApp();

  const { id: userId } = useParams();
  const isCurrentUser = currentUser?.id === userId;
  const [courses, setCourses] = useState([]);
  const [instructor, setInstructor] = useState();
  const [rating, setRating] = useState(0);
  const title = isCurrentUser
    ? "My Profile"
    : instructor
    ? `${instructor?.firstName}'s Profile`
    : "";
  const fetchInstructorCourses = async () => {
    try {
      const data = await http.get(`/course/instructorcourses/${userId}`);
      
      setCourses(data.data.data);
      const courses = data.data.data;
      courses.length > 0 && setInstructor(courses[0].instructor);
    } catch (error) {
      console.log(error);
    }
  };

  const CreateReviews = async () => {
    try {
      const data = await http.post(`/reviews/create/${userId}`);
      console.log(data);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchInstructorCourses();
    }
    CreateReviews()
    //eslint-disable-next-line
  }, [userId]);

  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Flex as="section">
        <Button asIcon mr={2} onClick={handleGoBack}>
          <BiArrowBack />
        </Button>

        <Heading as="h1" fontSize="heading.h3" d="flex" alignItems="center">
          {title}
        </Heading>
      </Flex>

      <Box minH="500px" maxW="700px">
        <Stack my={10}>
          {instructor?.photo === undefined ? (
            <Avatar
              name={instructor?.firstName + " " + instructor?.lastName}
              borderRadius="100%"
              width="100px"
              height="100px"
              src={instructor?.profilePics}
            />
          ) : (
            <Image
              src={instructor?.photo}
              alt={instructor?.firstName}
              height={{ base: "150px", tablet: "150px" }}
              width={{ base: "150px", tablet: "150px" }}
              flexShrink={0}
              rounded="full"
            />
          )}

          <Box>
            <Text bold as="level3">
              {instructor?.firstName} {instructor?.lastName}
            </Text>
            {/* <Text>{user.title}</Text> */}
          </Box>

          {/* <HStack>
            {socialLinks.map((socialLink, index) => (
              <Box
                key={index}
                fontSize="heading.h2"
                _hover={{ color: "blue.500" }}
              >
                <Link href={socialLink.url}>
                  {socialLink.icon || <Text bold>{socialLink.name}</Text>}
                </Link>
              </Box>
            ))}
          </HStack> */}

          {/* <Text as="level3" py={5} textAlign="left">
            {user.bio}
          </Text> */}

          <Stack alignSelf={"stretch"} pt={3}>
            <Flex justifyContent="space-between">
              <Heading fontSize="heading.h4" mb={2} textAlign="left">
                {isCurrentUser
                  ? "My Courses "
                  : instructor
                  ? `${instructor?.firstName}'s Courses `
                  : "No instructor"}
                ({courses.length})
              </Heading>

              {isCurrentUser && (
                <Box>
                  <Button
                    link={"/my-courses"}
                    ghost
                    sm
                    d={{ base: "none", tablet: "flex" }}
                  >
                    SEE ALL
                  </Button>
                  <Button
                    link={"/my-courses"}
                    ghost
                    xs
                    d={{ base: "flex", tablet: "none" }}
                  >
                    SEE ALL
                  </Button>
                </Box>
              )}
            </Flex>

            <CourseListingGridLayout
              courses={courses}
              renderCard={(course) => (
                <CourseProductCard w="auto" course={course} />
              )}
            />
          </Stack>
          
        </Stack>
      </Box>
    </Box>
  );
};

// const MyCourses = ({  }) => {
//   const { myCourses, handleFetchMyCourses } = useMyCourses({ ongoing: true });

//   return (
//     <CourseListingGridLayout
//       courses={myCourses.data}
//       err={myCourses.err}
//       loading={myCourses.loading}
//       onErrRetry={handleFetchMyCourses}
//     />
//   );
// };

export const UserInfoPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <UserInfoPage {...props} />} />;
};
