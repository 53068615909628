import { http } from "../http";

/**
 * Endpoint to for admin get all modules
 * @param {object} params
 *
 * @param {{ title: string, courseId: string }}
 *
 * @returns {Promise<{ message: string, modules: Array<{ id: string, title: string, active: boolean, courseId: string }>}>}
 */
export const adminGetModuleListing = async (courseId, params) => {
  const path = `/module/${courseId}`;

  const {
    data: { data },
  } = await http.get(path, { params });
  console.log(data);
  if (data.length === 0) {
    return { modules: [] };
  } else {
    return {
      modules: data.map((module) => ({
        id: module.id,
        title: module.name,
        // instructor: {
        //   firstName: module.user.firstName,
        //   lastName: module.user.lastName,
        // },
        active: module.active,
        courseId: module.courseId,
      })),
      showingDocumentsCount: data.length,
      totalDocumentsCount: data.length,
    };
  }
};

/**
 * Endpoint for module editing/modification
 * @param {string} moduleId
 * @param {object} body
 * @returns {Promise<{ message: string, module: { id: string }}>}
 */
export const adminEditModule = async (moduleId, body) => {
  const path = `/module/edit/${moduleId}`;

  const {
    data: { message, data },
  } = await http.patch(path, body);
  const modules = {
    id: data[0].id,
  };

  return { message, modules };
};

/**
 * Endpoint for module creation
 * @param {{ title: string, thumbnail: File, certificate: File, description: string, departmentId: string, }} body
 * @returns {Promise<{ message: string, module: { id: string } }>}
 */
export const adminCreateModule = async (body) => {
  const path = "/module/create";

  const {
    data: { message, data },
  } = await http.post(path, body);

  const modules = { id: data.id };

  return { message, modules };
};

/**
 * Endpoint to get `module-details`
 * @param {string} id - moduleId
 *
 * @returns {Promise<{ module: module }>}
 */
export const adminRequestModuleDetailsForModuleId = async (id) => {
  const path = `/module/single/${id}`;

  const { data } = await http.get(path);
  return {
    data,
  };
};
export const adminDeleteForModuleId = async (id) => {
  const path = `/module/delete/${id}`;

  const { data } = await http.delete(path);
  return {
    data,
  };
};





