import { Box, Flex } from "@chakra-ui/layout";
import { BiCalendarEvent } from "react-icons/bi";
import { BsBook } from "react-icons/bs";
import { FiHelpCircle } from "react-icons/fi";
import { GiBookshelf } from "react-icons/gi";
import { IoChatbubblesOutline } from "react-icons/io5";
import { RiHome2Line } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { Link, Spinner, Text } from "../../../components";
import { useApp } from "../../../contexts";

const links = [
  {
    href: "/home",
    text: "Home",
    icon: <RiHome2Line />,
  },
  // {
  //   href: (user) => `/users/${user?.id}`,
  //   text: "My Profile",
  //   icon: <BiUser />,
  // },
  {
    href: "/my-courses",
    text: "My Courses",
    icon: <BsBook />,
  },
  {
    href: "/categories",
    text: "Categories",
    icon: <BiCategory />,
  },
  // {
  //   href: "/my-certificates",
  //   text: "My Certificates",
  //   icon: <BiBadgeCheck />,
  // },
  {
    href: "/library",
    text: "Library",
    icon: <GiBookshelf />,
  },
  {
    href: "/forum",
    text: "Forum",
    icon: <IoChatbubblesOutline />,
  },
  {
    href: "/events",
    text: "Events",
    icon: <BiCalendarEvent />,
  },
  {
    href: "/polls",
    text: "Polls",
    icon: <GiBookshelf />,
  },
  // {
  //   href: "/settings",
  //   text: "Settings",
  //   icon: <VscSettingsGear />,
  // },
  // {
  //   href: "/help",
  //   text: "Help",
  //   icon: <FiHelpCircle />,
  // },
];

const NavBar = ({ ...rest }) => {
  const {
    handleUserScreenSidebarToggle,
    state: {
      user,
      loadingStatuses: { user: loadingUser },
      errorStatuses: { user: errorUser },
    },
    isAuthenticated,
  } = useApp();

  return (
    <Flex as="nav" alignSelf="stretch" w="230px" {...rest}>
      <Flex listStyleType="none" as="ul" flexDirection="column">
        {loadingUser || errorUser ? (
          <Flex
            minH="300px"
            w="230px"
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            alignItems="center"
            color="white"
          >
            {loadingUser && <Spinner />}

            {errorUser && (
              <Text fontSize="xl" bold>
                Error!
              </Text>
            )}
          </Flex>
        ) : (
          links.map((link) => (
            <Box as="li" key={link.href} py={2}>
              <Link
                className="user-header-nav-link"
                activeClassName="user-header-nav-link--active"
                navLink
                href={link.text === "My Profile" ? link.href(user) : link.href}
                onClick={handleUserScreenSidebarToggle}
              >
                <Box mr={3}>{link.icon}</Box>

                <Text as="level3">{link.text}</Text>
              </Link>
            </Box>
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default NavBar;
