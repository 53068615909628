import { Box, Flex, Skeleton } from "@chakra-ui/react";
import { Image, Link, Text } from "../../components";
import { getDuration, truncateText } from "../../utils";
import Rated from "../../layouts/user/Rated";

export const CourseOverviewCard = ({
  course,
  controlledDimensions,
  isLoading,
  layout2,
}) => {
  return (
    <div>
      {isLoading ? (
        <Skeleton
          w={!controlledDimensions && { base: "100px", tablet: "300px" }}
          h={{ base: "240px", tablet: "180px" }}
        ></Skeleton>
      ) : (
        <Box
          pos={"relative"}
          p={{ base: 1, tablet: 3 }}
          pb={3}
          w={"400px"}
          h={layout2 ? "auto" : { base: "auto", tablet: "auto" }}
          bgGradient={!layout2 && "linear(to-r, primary.base, primary.hover)"}
          bg={layout2 ? " #fff" : "#800020"}
          shadow={layout2 && "0px 4px 10px rgba(0, 0, 0, 0.25)"}
          color={layout2 ? "black" : "white"}
          rounded="md"
        >
          <Box
            as="div"
            display="flex"
            gridGap="3"
            pos={"relative"}
            // mb={{ base: 1, tablet: 4 }}
            flexDirection={
              layout2 ? "column" : { base: "column", tablet: "row" }
            }
          >
            <Box w={"50%"}>
              <Image
                src={course.image}
                alt={course.title}
                height={layout2 ? "140px" : { base: "140px", tablet: "140px" }}
                width={layout2 ? "100%" : { base: "100%", tablet: "100%" }}
                rounded="md"
                flexShrink={0}
              />
            </Box>

            <Box>
              {layout2 ? (
                <Box
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <Link href={`/my-courses`}>
                    <Text as="level1" bold mb={{ base: 1, tablet: 2 }}>
                      {course?.title}
                    </Text>
                  </Link>
                </Box>
              ) : (
                <Box
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <Link href={`/modules/${course?.id}`}>
                    <Text
                      as="level2"
                      bold
                      mb={{ base: 1, tablet: 4 }}
                      mt={2}
                      d={{ base: "none", tablet: "block" }}
                    >
                      {course?.title}
                    </Text>
                  </Link>
                </Box>
              )}
              <Box w="fit-content" mt={23} mb={2}>
                {course?.reviews?.length === 0 ? (
                  <Box
                    _hover={{
                      textDecoration: "underline",
                    }}
                  >
                    <Link
                      href={`/rev/${course.id}`}
                      onClick={localStorage.setItem("course", course.id)}
                    >
                      <Text
                        as="level4"
                        bold
                        mb={{ base: 1, tablet: 2 }}
                        marginTop={4}
                      >
                        Rate this course
                      </Text>
                    </Link>
                  </Box>
                ) : (
                  <Flex gap={2}>
                    <Rated val={course.ratingsTotal / course.ratingsCount} />
                    <Text level="level1" color="orange">
                      {course.ratingsTotal / course.ratingsCount}
                    </Text>
                    <Text level="level1">({course.ratingsCount})</Text>
                  </Flex>
                )}
              </Box>
              <Box w="fit-content" pos={"absolute"} bottom={"5"}>
                <Link href={`/users/${course.instructor.id}`}>
                  <Flex
                    alignItems="center"
                    _hover={{
                      textDecoration: "underline",
                    }}
                  >
                    <Text opacity={0.8} isTruncated>
                      By {course.instructor.name}
                    </Text>
                  </Flex>
                </Link>
              </Box>
            </Box>
          </Box>
          {!isNaN(course.progress) && (
            <Flex gap={3} alignItems="center" mb={3}>
              <Box
                as="div"
                height="6px"
                w={"100%"}
                borderRadius={"10px"}
                bg="#C34A691A"
                display="flex"
              >
                <Box
                  as="div"
                  height="100%"
                  w={`${course.progress}%`}
                  backgroundColor="#fff"
                  borderRadius="10px"
                ></Box>
              </Box>
              <span>{course.progress}%</span>
            </Flex>
          )}
          <Flex justifyContent={"center"}>
            <>
              <Text
                as={layout2 ? "level6" : "level5"}
                d={{ base: "none", tablet: "block" }}
              >
                {getDuration(course?.duration).combinedText}
              </Text>
              <Text
                as={layout2 ? "level6" : "level5"}
                d={{ base: "block", tablet: "none" }}
              >
                {getDuration(course?.duration).hours &&
                  `${getDuration(course?.duration).hours} hrs`}{" "}
                {getDuration(course?.duration).minutes &&
                  `${getDuration(course?.duration).minutes} mins`}
              </Text>
            </>

            <Text as={layout2 ? "level6" : "level5"}>
              {course.noofmodules} Modules
            </Text>
          </Flex>
        </Box>
      )}
    </div>
  );
};
