import { Box, Flex, Grid, Image } from "@chakra-ui/react";
import {
  Button,
  Select,
  Spinner,
  Text,
} from "../../../components";
import { EmptyState } from "../../../layouts";
import { formatPriceToNaira, truncateText } from "../../../utils";
import CoursesPagination from "../../../components/CoursesPagination/CoursesPagination";
import { FiShoppingCart, FiFilter, FiX } from "react-icons/fi";
import Rated from "../../../layouts/user/Rated";
import { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";
import { NewModulesPageContent } from "./components/ModalContent";

export const Courses = ({
  isLoading,
  myCourses,
  setPage,
  page,
  toggleCartItem,
  buttonTexts,
  params,
  setValue,
  setLabel,
  label,
  category,
  value,
  setLoading,
  isLoading2,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleOpenModal = (course) => {
    setSelectedCourse(course);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCourse(null);
    setIsOpen(false);
  };

  return (
    <div>
      <Box id="courses" mt={12}>
        <Box
          display="flex"
          flexDirection={{ lg: "row", base: "column", md: "row", sm: "column" }}
          justifyContent="space-between"
        >
          <Box
            display={{ lg: "flex", md: "flex", base: "block", sm: "block" }}
            alignItems="center"
            gap={4}
          >
            <Text fontSize="20px">
              {label === ""
                ? "All Courses"
                : `Courses Under ${label ?? "..."} Category`}
            </Text>
            {value !== "" && (
              <Flex
                onClick={() => {
                  setValue("");
                  setLabel("");
                  setLoading && setLoading(true);
                }}
                display={{ lg: "flex", base: "none", sm: "none", md: "flex" }}
                cursor="pointer"
                alignItems={"center"}
                color="red"
              >
                <FiX size={20} /> Clear filters
              </Flex>
            )}
          </Box>
          <Box
            w={{ lg: "200px", md: "200px", base: "full", sm: "full" }}
            display={"flex"}
            justifyContent="space-between"
            ml={{ lg: "auto" }}
            my={4}
            alignItems="center"
          >
            <Select
              isLoading={isLoading2}
              w={{ lg: "full", md: "full", base: "150px", sm: "150px" }}
              placeholder="filter by category"
              options={category?.map((item) => ({
                label: item?.categoryName,
                value: item?.id,
              }))}
              onChange={(e) => {
                setValue(e.target.value);

                setLoading && setLoading(true);
                const selectedOption = category.find(
                  (option) => option.id === e.target.value
                );

                if (selectedOption) {
                  setLabel(selectedOption.categoryName);
                }
              }}
              label={
                <Flex alignItems="center" gap={2}>
                  <FiFilter />
                  Filter courses
                </Flex>
              }
            />
            {value !== "" && (
              <Flex
                onClick={() => {
                  setValue("");
                  setLabel("");
                  setLoading && setLoading(true);
                }}
                display={{ lg: "none", base: "flex", sm: "flex", md: "none" }}
                cursor="pointer"
                alignItems={"center"}
                color="red"
              >
                <FiX size={20} /> Clear filters
              </Flex>
            )}
          </Box>
        </Box>
        {isLoading ? (
          <Box
            h={"300px"}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Spinner />
          </Box>
        ) : !myCourses?.courses || myCourses?.courses?.length === 0 ? (
          <EmptyState
            heading={"No course found"}
            description={
              !myCourses?.courses
                ? "Please check your internet connection or come back later"
                : "No course found, please come back later"
            }
          />
        ) : (
          <Box>
            <Grid
              justifyContent={"center"}
              templateColumns={{
                base: "1fr",
                md: "1fr 1fr",
                lg: "1fr 1fr 1fr 1fr",
              }}
              gap={10}
            >
              {myCourses?.courses?.map((course, i) => (
                <Box
                  cursor="pointer"
                  key={i}
                  h="320px"
                  shadow="lg"
                  rounded={"lg"}
                  onClick={() => handleOpenModal(course)}
                >
                  <Image
                    w={"full"}
                    loading="lazy"
                    h="150px"
                    objectFit={"cover"}
                    alt={`course-thumbnail-${i}`}
                    src={course?.thumbnailUrl}
                  />
                  <Box px={4} py={2}>
                    <Text fontWeight="bold" fontSize="16px" textAlign="start">
                      {truncateText(course?.title, 30)}
                    </Text>
                    <Flex alignItems={"center"} gap={"10px"} mt={1}>
                      <Text fontWeight="bold" size="md" textAlign="start">
                        {formatPriceToNaira(Number(course?.discountedPrice))}
                      </Text>
                      <Text color="gray" textAlign="start">
                        <del>{formatPriceToNaira(Number(course?.price))}</del>
                      </Text>
                    </Flex>
                    <Text mt={1} textAlign="start">
                      {course?.instructor?.firstName} {course?.instructor?.lastName}
                    </Text> 
                    {course?.reviews?.length > 0 && (
                      <p
                        style={{
                          display: "flex",
                          fontSize: "12px",
                          alignItems: "center",
                          gap: "5px",
                          color: "#FFD700",
                        }}
                      >
                        <Rated val={course?.reviews[0]?.reviewerRating} />
                        {course?.reviews[0]?.reviewerRating}.5
                      </p>
                    )}
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCartItem(course);
                      }}
                      display="flex"
                      mt={2}
                      justifyContent="start"
                      secondary
                      sm
                      leftIcon={<FiShoppingCart />}
                    >
                      {buttonTexts[course.id] || "Add to cart"}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Grid>
            <CoursesPagination
              itemsPerPage={params.limit}
              pageLength={myCourses?.totalItems}
              setCurrentPage={setPage}
              currentPage={page}
              npages={myCourses?.totalPages}
            />
          </Box>
        )}
      </Box>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={handleCloseModal}
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt={5} height={"600px"}>
            {selectedCourse && (
              <NewModulesPageContent
                buttonTexts={buttonTexts}
                toggleCartItem={toggleCartItem}
                course={selectedCourse}
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button secondary mr={3} onClick={handleCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
