import React, { useCallback, useEffect, useState } from "react";
import {
  adminRequestModuleDetailsForModuleId,
  requestLessonDetails,
} from "../services";
import { useParams } from "react-router-dom";

export const useLesson = () => {
  const { lessonId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [lesson, setLessonDetails] = useState([]);
  const isEditMode = lessonId && lessonId !== "new";
  const fetcher = useCallback(async () => {
    const { data } = isEditMode && (await requestLessonDetails(lessonId));
    setLessonDetails(data?.data);
    setIsLoading(lesson?.loading);
    setError(lesson?.err);
  }, []);

  useEffect(() => {
    fetcher();
  }, []);
  return {
    isLoading,
    isError,
    lessonId,
    lesson,
  };
};
