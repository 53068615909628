import { SearchRounded } from "@material-ui/icons";
import { FiXCircle } from "react-icons/fi";
import { useEffect, useMemo, useRef } from "react";
import debounce from "lodash.debounce";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { Input } from "../Form/Input/Input";
import { BsSearch } from "react-icons/bs";
const SearchFilter = ({ placeholder, value, setValue, onSearch }) => {
  const inputRef = useRef(null);

  const handleChange = (e) => {
   setValue && setValue(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  const clearInput = () => {
    setValue("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setTimeout(() => {
      onSearch?.();
    }, 1);
  };

  return (
    <Flex
    mt={4}
      as="form"
      border="1px"
      value = {value}
      backgroundColor="white"
      w={"400px"}
      textColor="accent.3"
      rounded="full"
      pos={"relative"}
      overflow="hidden"
      onSubmit={(e) => {
        e.preventDefault();
        onSearch?.();
      }}
    >
   <Input
        border="none"
        type="search"
        onChange={debouncedResults} 
        placeholder={placeholder}
        paddingLeft={2}
        _focus={{
          transform: "scale(1.01)",
          "&::placeholder": {
            textColor: "black",
          },
        }}
      />

      <IconButton
        _focus={{
          fontSize: "20px",
          textColor: "primary.base",
          backgroundColor: "secondary.1",
        }}
        rounded="none"
        variant="ghost"
        width="60px"
        order={-1}
        type="submit"
      >
        <BsSearch />
      </IconButton>

     


    </Flex>
  );
};

export default SearchFilter;
