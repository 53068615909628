import { Stack } from "@chakra-ui/react";
import { Flex, Button } from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";
import { Heading } from "../../../components";
import { Route } from "react-router-dom";
import { mainAreaHeight_userPages } from "../../../theme/breakpoints";
import { useHistory } from "react-router-dom";
export const InstructorPage = () => {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div>
      <Stack
        mr={4}
        pr={4}
        flex={1}
        spacing={10}
        height={mainAreaHeight_userPages}
        overflowY="scroll"
      >
        <Flex as="section">
          <Button asIcon mr={2} onClick={handleGoBack}>
            <BiArrowBack />
          </Button>

          <Heading as="h1" fontSize="heading.h3" d="flex" alignItems="center">
            Instructor
          </Heading>
        </Flex>
      </Stack>
    </div>
  );
};
export const InstructorPageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <InstructorPage {...props} />} />;
};
