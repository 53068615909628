import {
  Box,
  Flex,
  Stack,
  List,
  ListItem,
  ListIcon,
  Grid,
  OrderedList,
  ButtonGroup,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";
import {
  AiOutlineUp,
  AiOutlineDown,
  AiOutlineClockCircle,
  AiFillCaretDown,
  AiFillCaretUp,
} from "react-icons/ai";
import { BiArrowBack, BiPlayCircle } from "react-icons/bi";
import { BsCheck, BsDot } from "react-icons/bs";
import { Heading, Button, Image, Text, Link } from "../../components";
import {
  mainAreaHeight_userPages,
  pageWrapperSpacing_userPages,
} from "../../theme/breakpoints";
import { useGoBack } from "../../hooks";
import { getDuration } from "../../utils";

const course = {
  title: "Fundamental of Financial Principles",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit volutpat pellentesque elit dolor ultricies purus. Scelerisque tempus, nunc, nibh enim, porttitor et. Hendrerit elementum pretium leo nibh interdum. Mattis pharetra in leo elementum sed gravida senectus. Dictum ultrices proin scelerisque convallis habitant. Ultrices a, consequat nulla arcu dui tellus adipiscing. Morbi amet pulvinar maecenas euismod a, vitae. Mauris sapien, luctus magna lobortis adipiscing risus, lectus tortor. Maecenas auctor ac et neque in amet odio. In justo proin ipsum nam congue tortor a.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  ratingsAverage: 4.5,
  ratingsCount: 10,
  ratingOverview: [
    {
      rating: 5,
      percentage: 20,
    },
    {
      rating: 4,
      percentage: 50,
    },
    {
      rating: 3,
      percentage: 40,
    },
    {
      rating: 2,
      percentage: 20,
    },
    {
      rating: 1,
      percentage: 80,
    },
  ],
  reviews: [
    {
      id: 1,
      user: {
        name: "John Doe",
      },
      rating: 5,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ctetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "2 min ago",
    },
    {
      id: 2,
      user: {
        name: "John Doe",
      },
      rating: 2,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ctetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "2 min ago",
    },
  ],
  thumbnail:
    "http://res.cloudinary.com/xanotech/image/upload/v1637836170/grooming-centre/Test-Course/course-thumbnail-1.jpg",
  previewVideo:
    "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4",
  whatYouLearn: [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  ],
  lessonCount: 30,
  totalDuration: 3800,
  modules: [
    {
      id: "module-1",
      title: "Understanding what you can never understand",
      totalDuration: 3800,
      lessons: [
        {
          id: "lesson-1",
          title: "CSS Intro",
          video:
            "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4",
        },
      ],
    },
    {
      id: "module-2",
      title: "Understanding what you can never understand",
      totalDuration: 3800,
      lessons: [
        {
          id: "lesson-2",
          title: "How to get the most out",
          video:
            "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4",
        },
      ],
    },
    {
      id: "module-3",
      title: "Understanding what you can never understand",
      totalDuration: 3800,
      lessons: [
        {
          id: "lesson-3",
          title: "Objectives",
          video:
            "http://res.cloudinary.com/xanotech/video/upload/v1637447807/grooming-centre/HTML-clash-clans/0001.%20TutFlix.io--1-1-intro-to-fundamentals-of-testing-in-javascript-00-00-33.mp4",
        },
      ],
    },
  ],
  instructor: {
    id: "instructor-1",
    name: "Dr. B. Ambedkar",
    photo: "https://i.pravatar.cc/150",
    bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    title: "Developer and Lead instructor",
  },
  price: "7500",
  discountedPrice: "5000",
};

const CourseTakePage = () => {
  const handleGoBack = useGoBack();

  const allLessons = course.modules.reduce(
    (acc, module) => [...acc, ...module.lessons],
    []
  );

  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [currentLesson, setCurrentLesson] = useState(
    allLessons[currentLessonIndex]
  );

  useEffect(() => {
    setCurrentLesson(allLessons[currentLessonIndex]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLessonIndex]);

  const handleNext = () =>
    setCurrentLessonIndex((prev) =>
      prev !== allLessons.length - 1 ? prev + 1 : prev
    );

  const handlePlayLesson = (id) =>
    setCurrentLessonIndex(allLessons.findIndex((lesson) => lesson.id === id));


  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Flex>
        <Stack
          mr={4}
          pr={4}
          flex={1}
          spacing={10}
          height={mainAreaHeight_userPages}
          overflowY="scroll"
        >
          <Flex as="section">
            <Button asIcon mr={2} onClick={handleGoBack}>
              <BiArrowBack />
            </Button>

            <Heading as="h1" fontSize="heading.h3" d="flex" alignItems="center">
              {course.title}
            </Heading>
          </Flex>

          <Stack spacing={5} as="section">
            <Box
              overflow="hidden"
              rounded="md"
              width="100%"
              height="450px"
              pos="relative"
            >
              <video
                title={currentLesson.title}
                src={currentLesson.video}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                autoPlay
                controls
              />

              <Button onClick={handleNext}>Next</Button>
            </Box>

            <Box w="fit-content" mb={5}>
              <Link href={`/users/${course.instructor.id}`}>
                <Flex
                  alignItems="center"
                  _hover={{
                    textDecoration: "underline",
                  }}
                >
                  <Image
                    src={course.instructor.photo}
                    alt={course.instructor.name}
                    height={{ base: "50px", tablet: "50px" }}
                    width={{ base: "50px", tablet: "50px" }}
                    flexShrink={0}
                    rounded="full"
                    mr={2}
                  />

                  <Box>
                    <Text bold mb={1} as="level3">
                      {course.instructor.name}
                    </Text>
                    <Text opacity={0.9}>{course.instructor.title}</Text>
                  </Box>
                </Flex>
              </Link>
            </Box>
          </Stack>

          <Section heading="What you’ll learn" showMoreFeature={false}>
            <List spacing={3}>
              <Grid
                gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                gridGap={3}
              >
                {course.whatYouLearn.map((item, index) => (
                  <ListItem key={index}>
                    <Text as="level5">
                      <ListIcon as={BsCheck} color="primary.base" />
                      {item}
                    </Text>
                  </ListItem>
                ))}
              </Grid>
            </List>
          </Section>

          <Section heading="Description" showMoreFeature={false}>
            <Box>
              <Text>{course.description}</Text>
            </Box>
          </Section>
        </Stack>

        <Stack
          // pos="sticky"
          // top={0}
          as="aside"
          w="400px"
          height={mainAreaHeight_userPages}
          overflowY="scroll"
          shadow="0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)"
          rounded="md"
          a={5}
          spacing={8}
          p={3}
        >
          <Section
            renderHeader={() => (
              <Flex justifyContent="space-between">
                <Heading fontSize="text.level2">
                  Curriculum({course.lessonCount} Lessons)
                </Heading>

                <Flex alignItems="center" opacity={0.8}>
                  <AiOutlineClockCircle />

                  <Text>
                    {getDuration(course.totalDuration, true).combinedText}
                  </Text>
                </Flex>
              </Flex>
            )}
            w="370px"
            showMoreFeature={false}
          >
            {course.modules.map((module, moduleIndex) => (
              <ModuleAccordion
                key={module.id}
                moduleIndex={moduleIndex}
                module={module}
                isOpen={moduleIndex === 0 && true}
                onLessonPlay={handlePlayLesson}
              />
            ))}
          </Section>
        </Stack>
      </Flex>
    </Box>
  );
};

const ModuleAccordion = ({
  moduleIndex,
  module,
  isOpen = false,
  onLessonPlay,
}) => {
  const [open, setOpen] = useState(isOpen);
  const handleToggle = () => setOpen((prev) => !prev);

  return (
    <Box p={5} key={module.id} pb={0}>
      <Box onClick={handleToggle}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          _hover={{ opacity: 0.8, cursor: "pointer" }}
        >
          <Box w="400px">
            <Heading as="h3" fontSize="text.level3">
              Module {moduleIndex + 1} - {module.title}
            </Heading>
          </Box>

          <Box color="primary.base">
            {open ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </Box>
        </Flex>

        <Flex gridGap={2} alignItems="center" mt={2}>
          <Text as="level5" opacity={0.8}>
            {getDuration(module.totalDuration).combinedText}
          </Text>

          <BsDot />

          <Text as="level5">{module.lessons.length} lessons</Text>
        </Flex>
      </Box>

      {open && (
        <Box mt={3} p={2}>
          <OrderedList spacing={3}>
            {module.lessons.map((lesson) => (
              <ListItem key={lesson.id}>
                <Flex justifyContent="space-between">
                  <Text pl={3}>
                    Module {moduleIndex + 1} - {lesson.title}
                  </Text>

                  <ButtonGroup size="xs">
                    <Button asIcon onClick={onLessonPlay.bind(null, lesson.id)}>
                      <BiPlayCircle />
                    </Button>
                  </ButtonGroup>
                </Flex>
              </ListItem>
            ))}
          </OrderedList>
        </Box>
      )}
    </Box>
  );
};

const Section = ({
  heading,
  renderHeader,
  children,
  showHeight = "100px",
  showMoreFeature = true,
  ...rest
}) => {
  const [showMore, setShowMore] = useState(false);
  const handleToggleVisibility = () => setShowMore((prev) => !prev);
  const contentWrapperRef = useRef();

  return (
    <Stack spacing={5} as="section" {...rest}>
      {renderHeader ? (
        renderHeader()
      ) : (
        <Heading fontSize="text.level2">{heading}</Heading>
      )}

      <Stack
        spacing={5}
        overflowY="hidden"
        transition=".3s"
        h={
          !showMoreFeature
            ? "auto"
            : showMore
            ? contentWrapperRef.current?.clientHeight + 10 + "px"
            : showHeight
        }
      >
        <Box ref={contentWrapperRef}>{children}</Box>
      </Stack>

      {showMoreFeature && (
        <Box transform="translateY(-10px)">
          <Button xs ghost onClick={handleToggleVisibility}>
            {showMore ? "show less" : "show more"}
            {showMore ? <AiOutlineUp /> : <AiOutlineDown />}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export const CourseTakePageRoute = ({ ...rest }) => {
  return <Route {...rest} render={(props) => <CourseTakePage {...props} />} />;
};
