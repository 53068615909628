import { rest } from "msw";
import { getUrl } from "../../http";
import { handleSuccessResponse } from "../helpers";
import {
  adminCreateModuleRes,
  adminEditModuleRes_moduleId_1,
  adminEditModuleRes_moduleId_2,
  adminGetModuleListingRes_courseId_3,
  adminGetModuleListingRes_courseId_1,
  adminRequestModuleDetailsRes_moduleId_1,
  adminRequestModuleDetailsRes_moduleId_2,
} from "./responses";

const adminGetModuleListing = [
  rest.get(
    getUrl("/module/admin/courseId_1"),
    handleSuccessResponse(adminGetModuleListingRes_courseId_1)
  ),
  rest.get(
    getUrl("/module/admin/courseId_3"),
    handleSuccessResponse(adminGetModuleListingRes_courseId_3)
  ),
];

const adminCreateModule = rest.post(
  // getUrl("/module/create"),
  getUrl("/module/create"),
  handleSuccessResponse(adminCreateModuleRes)
);

const adminEditModule = [
  rest.patch(
    getUrl("/module/edit/moduleId_1"),
    handleSuccessResponse(adminEditModuleRes_moduleId_1)
  ),

  rest.patch(
    getUrl("/module/edit/moduleId_2"),
    handleSuccessResponse(adminEditModuleRes_moduleId_2)
  ),
];

const adminRequestModuleDetailsForModuleId = [
  rest.get(
    getUrl("/module/moduleId_1"),
    handleSuccessResponse(adminRequestModuleDetailsRes_moduleId_1)
  ),
  rest.get(
    getUrl("/module/moduleId_2"),
    handleSuccessResponse(adminRequestModuleDetailsRes_moduleId_2)
  ),
];

const modules = [
  // ...adminGetModuleListing,
  // adminCreateModule,
  // ...adminEditModule,
  // ...adminRequestModuleDetailsForModuleId,
];

export default modules;
