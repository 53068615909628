import { Flex, Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useApp } from '../../contexts';
import {
  usePageRefreshAfterLogin,
  useRedirectNewUserToNewPasswordPage,
  useRedirectNonAuthUserToSigninPage,
} from '../../hooks';
import breakpoints from '../../theme/breakpoints';
import Footer from '../user/Footer';
import Header from './Header';
import MainArea from './MainArea/MainArea';
import Sidebar from './Sidebar/Sidebar';
const useRedirect = () => {
  const { replace } = useHistory();
  const appManager = useApp();

  useEffect(() => {
    if (appManager.state.user && appManager.state.metadata) {
      const { userRoleId } = appManager.state.user;
      // const role = appManager.getOneMetadata("userRoles", userRoleId);

      const role = appManager.state.user.userRoleName;

      if (!/admin/i.test(role)) {
        return replace('/not-found');
      }
    }
  }, [
    appManager,
    appManager.state.metadata,
    appManager.state.user,
    appManager.isAuthenticated,
    replace,
  ]);
};

const AdminLayout = () => {
  usePageRefreshAfterLogin();
  useRedirect();
  useRedirectNonAuthUserToSigninPage();
  useRedirectNewUserToNewPasswordPage();
const [open, setOpen]=useState()
  const isSettingsPage = /settings/i.test(window.location.pathname);

  return (
    <Flex
      flexDirection="column"
      height="100vh"
      backgroundColor={isSettingsPage ? 'white' : 'gray.100'}
      maxWidth={breakpoints['laptop-l']}
      marginX="auto"
      shadow="0 0 10px 3px rgba(0, 0, 0, .1)"
    >
      <Header setOpen={setOpen} open={open} />
{open ?<Box display={{lg:"none", md:"block", base:"block"}} pos={"absolute"} top={0} left={0} w="100%" height={"100%"} bg={"#00000027"} zIndex={100} onClick={()=>{
  setOpen(false)
}}/>:null}


      <Flex
        height="100%"
        marginX={isSettingsPage ? "20px" : null}
        marginY={isSettingsPage ? '40px' : null}
        boxShadow={isSettingsPage ? '0px 2px 7px rgba(0, 0, 0, 0.1)' : null}
        overflow="hidden"
      >
        <Sidebar setOpen={setOpen} open={open}/>
        <MainArea />
      </Flex>
      {isSettingsPage ? <Footer /> : null}
    </Flex>
  );
};

export const AdminLayoutRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <AdminLayout {...props} />} />;
};
