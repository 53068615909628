const fontSizes = {
  heading: {
    h1: "42px",
    h2: "36px",
    h3: "24px",
    h4: "20px",
  },
  text: {
    level1: "20px",
    level2: "18px",
    level3: "16px",
    level4: "14px",
    level5: "12px",
    level6: "10px",
    level7: "8px",
  },
  others: {
    1: "28px",
  },
};

export default fontSizes;
