import { Box, Heading} from "@chakra-ui/react"
import { ScrollCarousel } from "../../../components"
import { sectionMaxWidth_userPages } from "../../../theme/breakpoints"
import { TestimonialCard } from "./components/TestimonialCard"
import {Route} from "react-router-dom"
import { Header } from "./components/Header"
import { testimonial } from "./LandingPage"
export const Testimonials=()=>{
    return (
        <div>
     <Header
      />
         <Box
        px={{
          base: 6,
          md: 6,
          lg: 20,
        }}
        pt={20}
        overflow={"hidden"}
        pb={5}
        mb={10}
      >
        <Heading color="#800020" textAlign="center" mb={"60px"} size="lg">
          What Our Users Say
        </Heading>
        {testimonial && (
          <ScrollCarousel gridGap="6" autoPlay>
            {testimonial.map((item, i) => (
              <TestimonialCard key={i} item={item} />
            ))}
          </ScrollCarousel>
        )}
      </Box>
        </div>
    )
}
export const TestimonialsPageRoute = ({ ...rest }) => {
    return <Route {...rest} render={(props) => <Testimonials {...props} />} />;
  };
  