import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Button, DateTimePicker, Input } from "../../../../../components";
import {
  adminGetAssessmentListing,
  adminGetModuleListing,
} from "../../../../../services";
import { useCallback } from "react";
import { capitalizeWords } from "../../../../../utils";
import {
  useDateTimePicker,
  useGoBack,
  useQueryParams,
} from "../../../../../hooks";
import { Select } from "../../../../../components";
import { AdminMainAreaWrapper } from "../../../../../layouts";
import {
  adminEditAssessment,
  adminEditExamination,
} from "../../../../../services";
import {
  appendFormData,
  capitalizeFirstLetter,
  formatDateToISO,
} from "../../../../../utils";
import { useCache } from "../../../../../contexts";
import { useViewModuleInfo } from "../../useModule";
import { useState } from "react";
const EditAssessmentPage = ({ assessment }) => {
  const [Modules, setModules] = useState([]);
  const [details, setDetails] = useState(null);

  const { id: courseId, assessmentId } = useParams();
  const isExamination = useQueryParams().get("examination");
  const { push } = useHistory();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleCancel = useGoBack();

  const startTimeManager = useDateTimePicker();

  // Init `Title` value
  useEffect(() => {
    if (assessment) {
      setValue("title", assessment.topic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment]);

  // Init `StartTime` value
  useEffect(() => {
    if (assessment) {
      setValue("module", assessment.Module);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment]);

  // Init `Duration` value
  useEffect(() => {
    if (assessment) {
      setValue("duration", assessment.duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment]);

  // Init `Number of Questions` value
  useEffect(() => {
    if (assessment) {
      setValue("amountOfQuestions", assessment?.questionCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment]);
  useEffect(() => {
    if (assessment) {
      setValue("amountOfQuestions", assessment?.module);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment]);

  const { handleDelete } = useCache();

  const fetcher = async () => {
    const { data } = await adminGetAssessmentListing(assessmentId);
    setDetails(data);
    console.log(data);
  };
  useEffect(() => {
    console.log("useEffect ran");
    fetcher();
  }, []);
  // Handle form submission
  const fetchers = async () => {
    const { data } = await adminGetAssessmentListing(assessmentId);
    setDetails(data);
    console.log(data);
  };
  useEffect(() => {
    fetchers();
  }, []);
  const onSubmit = async (data) => {
    try {
      data = {
        ...data,
        courseId,
      };

      const body = appendFormData(data);

      const { message } = await adminEditExamination(details?.id, body);

      toast({
        description: capitalizeFirstLetter(message),
        position: "top",
        status: "success",
      });

      handleDelete(isExamination || assessmentId);

      push(`/admin/courses/${courseId}/module/${assessmentId}/exam`);
    } catch (error) {
      toast({
        description: capitalizeFirstLetter(error.message),
        position: "top",
        status: "error",
      });
    }
  };

  const populateSelectOptions = (data, filterBody = () => true) => {
    return data?.filter(filterBody)?.map((item) => ({
      label: capitalizeWords(item.title),
      value: item.id,
    }));
  };
  return (
    <AdminMainAreaWrapper>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} marginY={14} marginX={6}>
        <Box backgroundColor="white" padding={10}>
          <Input
            label={isExamination ? "Examination Title" : "Assessment Title"}
            id="title"
            error={errors.title?.message}
            {...register("title", {
              required: "Title is required",
            })}
          />
          <Grid
            templateColumns={{
              lg: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              base: "1fr",
            }}
            gap={10}
            marginY={10}
          >
            <GridItem>
              <Select
                label="Select module"
                id="module"
                options={populateSelectOptions(Modules)}
                error={errors.module?.message}
                isLoading={!Modules}
                disabled
              />
            </GridItem>
            <GridItem>
              <Input
                label="Duration"
                type="number"
                id="duration"
                placeholder="Enter duration in minutes"
                error={errors.duration?.message}
                {...register("duration", {
                  required: "Please enter duration",
                })}
              />
            </GridItem>
            <GridItem>
              <Input
                label="Number of Questions"
                type="number"
                id="amountOfQuestions"
                placeholder="Enter number of questions"
                error={errors.amountOfQuestions?.message}
                {...register("amountOfQuestions", {
                  required: "Please enter number of questions",
                })}
              />
            </GridItem>
          </Grid>
        </Box>
        <Flex paddingY={10} marginX={6} justifyContent="space-between">
          <Button secondary onClick={handleCancel}>
            Cancel
          </Button>
          <Button isLoading={isSubmitting} loadingText type="submit">
            Update
          </Button>
        </Flex>
      </Box>
    </AdminMainAreaWrapper>
  );
};

export default EditAssessmentPage;
