import { useCallback, useContext, useState } from "react";
import {
  requestMyData,
  requestMetadata,
  requestSignin,
  requestNewmailVerification,
} from "../../services";
import { AppContext } from "./AppProvider";
import Cookies from "js-cookie";
import {
  addToCart,
  getCart,
  removeFromCart,
} from "../../services/http/endpoints/cart";
/**
 * App state `Manager` - its consumes the ContextProvider and returns whats necessary.
 *
 * @returns  Object {
 *   state: { user: `null`| `{}`, token: `null`| `string`, metadata: `null`| `{}` },
 *   isAuthenticated: `boolean`,
 *   fetchMetadata: () => `Promise<void>`,
 *   handleSetToken: (token: `string`) => `void`,
 *   handleSetCurrentUser: (user: `{}`) => `void`,
 *   handleGetTokenFromClientStorage: () => token: `string`,
 *   handleLogout: () => `void`,
 *   getOneMetadata: (arrayKey: `string`, id: `string`) => (`OneMetadata` || `undefined`),
 * }
 */
export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useApp must be used within a AppProvider`);
  }

  const [state, setState] = context;

  const fetchMetadata = useCallback(async () => {
    try {
      setState((prev) => ({
        ...prev,
        loadingStatuses: {
          ...prev.loadingStatuses,
          metadata: true,
        },
      }));

      const { data } = await requestMetadata();

      setState((prev) => ({
        ...prev,
        metadata: {
          ...data,
        },
        loadingStatuses: {
          ...prev.loadingStatuses,
          metadata: false,
        },
      }));
    } catch (err) {
      console.error(err);
      setState((prev) => ({
        ...prev,

        errorStatuses: {
          ...prev.errorStatuses,
          metadata: err.message,
        },
        loadingStatuses: {
          ...prev.loadingStatuses,
          metadata: false,
        },
      }));
    }
  }, [setState]);

  const fetchCurrentUser = useCallback(async () => {
    try {
      setState((prev) => ({
        ...prev,
        loadingStatuses: {
          ...prev.loadingStatuses,
          user: true,
        },
      }));

      const { data } = await requestMyData();
      localStorage.setItem("DateNow", new Date(data.currentDateTime).getTime());

      setState((prev) => ({
        ...prev,
        user: {
          ...data,
        },
        loadingStatuses: {
          ...prev.loadingStatuses,
          user: false,
        },
      }));
    } catch (err) {
      console.error(err);
      setState((prev) => ({
        ...prev,

        errorStatuses: {
          ...prev.errorStatuses,
          user: err.message,
        },
        loadingStatuses: {
          ...prev.loadingStatuses,
          user: false,
        },
      }));
    }
  }, [setState]);

  const handleGetTokenFromClientStorage = useCallback(() => {
    const token = localStorage.getItem("token");
    return token;
  }, []);

  const handleSetToken = useCallback(
    (token) => {
      if (token) {
        localStorage.setItem("token", token);
        Cookies.set("micro", token, {
          expires: 7,
          path: "/",
          sameSite: "lax",
          secure: true,
          domain: "localhost",
        });
        setState((prev) => ({ ...prev, token }));
      }
    },
    [setState]
  );

  const handleSetCurrentUser = useCallback(
    (user) => {
      setState((prev) => ({ ...prev, user }));
    },
    [setState]
  );

  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    setState((prev) => ({ ...prev, user: null, token: null }));
  }, [setState]);

  const [loginSuccess, setLoginSuccess] = useState(false);

  const handleLogin = async (data) => {
    const {
      token,
      message,
      firstName,
      lastName,
      id,
      email,
      userRoleId,
      userRoleName,
    } = await requestSignin(data);
    handleSetToken(token);
    if (userRoleName === "admin") {
      Cookies.set("user", "admin", {
        expires: 7,
        path: "/admin",
        sameSite: "lax",
        secure: true,
        domain: "localhost",
      });
    } else {
      Cookies.set("user", "user", {
        expires: 7,
        path: "/",
        sameSite: "lax",
        secure: true,
        domain: "localhost",
      });
    }

    handleSetCurrentUser({
      user: { firstName, lastName, id, email, userRoleId, userRoleName },
      email,
      firstName,
      lastName,
      id,
      userRoleId,
      userRoleName,
    });

    // requestMyData().then((res) => { });
    setLoginSuccess(true);
    // if (message === 'Kindly verify email') {
    //   handleResendVerificationEmail(data)
    // }
  };

  const handleAddToCart = async (data) => {
    await addToCart(data);
  };

  const handleRemoveFromCart = async (data) => {
    await removeFromCart(data);
  };

  const handleGetCart = async () => {
    const data = await getCart();
    setState((state) => ({
      ...state,
      cart: data,
    }));
  };

  const handleResendVerificationEmail = async (data) => {
    const { user, token, message } = await requestNewmailVerification(data);
    // handleSetToken(token);
    // handleSetCurrentUser(user);
    // setLoginSuccess(true);
  };

  const getOneMetadata = (arrayKey, id, options) =>
    state[options?.allMetadata ? "allMetadata" : "metadata"]?.[arrayKey]?.find(
      (item) => (options?.condition ? options.condition(item) : item.id === id)
    );

  const isAuthenticated = handleGetTokenFromClientStorage() ? true : false;

  const handleUserScreenSidebarToggle = () =>
    setState((state) => ({
      ...state,
      userScreenSidebarIsOpen: !state.userScreenSidebarIsOpen,
    }));

  return {
    state,
    isAuthenticated,
    fetchMetadata,
    handleSetToken,
    handleSetCurrentUser,
    fetchCurrentUser,
    handleGetTokenFromClientStorage,
    handleLogout,
    getOneMetadata,
    handleLogin,
    loginSuccess,
    handleAddToCart,
    handleGetCart,
    handleRemoveFromCart,
    handleResendVerificationEmail,
    handleUserScreenSidebarToggle,
  };
};
