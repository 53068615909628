export const adminGetRoleListingRes = {
  data: {
    userRoles: [
      {
        id: "userRoleId_1",
        name: "Super Admin",
        noOfUsers: 20,
      },
      {
        id: "userRoleId_2",
        name: "Admin",
        noOfUsers: 12,
      },
      {
        id: "userRoleId_3",
        name: "User",
        noOfUsers: 183,
      },
    ],
  },
};
