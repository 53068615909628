import { Box, Flex, Grid, GridItem } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, DateTimePicker, Input } from "../../../../../components";
import { Select } from "../../../../../components";
import {
  useDateTimePicker,
  useGoBack,
  useQueryParams,
} from "../../../../../hooks";
import { AdminMainAreaWrapper } from "../../../../../layouts";
import {
  adminCreateAssessment,
  adminCreateExamination,
} from "../../../../../services";
import {
  appendFormData,
  capitalizeFirstLetter,
  capitalizeWords,
  populateSelectOptions,
} from "../../../../../utils";
import { adminGetModuleListing } from "../../../../../services";
import useModule from "../../../../../hooks/useModule";
const CreateAssessmentPage = () => {
  const [Modules, setModules] = useState([]);
  const { id: courseId, q } = useParams();
  console.log(q, "hello");
  const { push } = useHistory();
  const history = useHistory();
  const toast = useToast();
  const fetcher = async () => {
    const { modules } = await adminGetModuleListing(courseId);
    setModules(modules);
  };

  useEffect(() => {
    fetcher();
  }, [courseId]);
  const populateSelectOptions = (data, filterBody = () => true) => {
    return data?.filter(filterBody)?.map((item) => ({
      label: capitalizeWords(item.title),
      value: item.id,
    }));
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleCancel = useGoBack();
  const onSubmit = async (data) => {
    try {
      data = {
        ...data,
        courseId,
      };
      console.log(data);
      const body = appendFormData(data);
      console.log(body);
      const { message } = await adminCreateAssessment(body);

      toast({
        description: capitalizeFirstLetter(message),
        position: "top",
        status: "success",
      });
      history.goBack();
    } catch (error) {
      toast({
        description: capitalizeFirstLetter(error.message),
        position: "top",
        status: "error",
      });
      console.log(error.message);
    }
  };
  return (
    <AdminMainAreaWrapper>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} marginY={14} marginX={6}>
        <Box backgroundColor="white" padding={10}>
          <Input
            label={"Quiz Title"}
            id="title"
            error={errors.title?.message}
            {...register("title", {
              required: "Title is required",
            })}
          />
          <Grid
            templateColumns={{
              lg: "repeat(2, 1fr)",
              md: "repeat(2, 1fr)",
              base: "1fr",
            }}
            gap={10}
            marginY={10}
          >
            <GridItem>
              <Select
                label="Select module"
                id="moduleId"
                // option={Modules.map((module) => (
                //   <option style={{ textTransform: "capitalize" }} key={module}>
                //     {module}
                //   </option>
                // ))}
                options={populateSelectOptions(Modules)}
                error={errors.module?.message}
                isLoading={!Modules}
                {...register("moduleId", {
                  required: "Please select a module",
                })}
              />
            </GridItem>
            <GridItem>
              <Input
                label="Duration"
                type="number"
                id="duration"
                placeholder="Enter duration in minutes"
                error={errors.duration?.message}
                {...register("duration", {
                  required: "Please enter duration",
                })}
              />
            </GridItem>
            <GridItem>
              <Input
                label="Number of Questions"
                type="number"
                id="amountOfQuestions"
                placeholder="Enter number of questions"
                error={errors.amountOfQuestions?.message}
                {...register("amountOfQuestions", {
                  required: "Please enter number of questions",
                })}
              />
            </GridItem>
          </Grid>
        </Box>
        <Flex paddingY={10} marginX={6} justifyContent="space-between">
          <Button secondary onClick={handleCancel}>
            Cancel
          </Button>
          <Button isLoading={isSubmitting} loadingText type="submit">
            Save
          </Button>
        </Flex>
      </Box>
    </AdminMainAreaWrapper>
  );
};

export default CreateAssessmentPage;
