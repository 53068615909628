import { Box } from "@chakra-ui/layout";
import { Switch, Redirect } from "react-router-dom";
import BooksPageRoute from "../pages/BooksPage";
import AudioPageRoute from "../pages/AudioPage";
import VideoPageRoute from "../pages/VideoPage";
import PurchasedPageRoute from "../pages/Purchased";

const MainArea = () => {
  return (
    <Box flex={1} overflowY="auto">
      <Switch>
        <BooksPageRoute exact path="/library/books" />
        <AudioPageRoute path="/library/audio" />
        <VideoPageRoute path="/library/videos" />
        <PurchasedPageRoute path="/library/purchased"/>
        <Redirect exact from="/library" to="/library/books" />
      </Switch>
    </Box>
  );
};

export default MainArea;
