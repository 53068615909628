import { Box, Button, Flex, Stack, useToast } from "@chakra-ui/react";
import { Text, Image, Link, Ratings } from "..";
import {
  capitalizeFirstLetter,
  formatPriceToNaira,
  truncateText,
} from "../../utils";
import Book from "../../assets/images/book.png";
import { BiTrash } from "react-icons/bi";
import { useState } from "react";
import { useApp } from "../../contexts";

export const CourseProductCardCart = ({
  course,
  listItem,
  w,
  cart,
  setCart,
}) => {
  const [isLoading, setIsCartLoading] = useState(false);
  const toast = useToast();
  const appManager = useApp();

  const handleRemoveFromCart = async () => {
    setIsCartLoading(true);
    const data = {
      cartId: course?.id,
    };
    try {
      await appManager.handleRemoveFromCart(data);
      const newCart = cart?.cartItems?.filter((c) => c.id !== course?.id);
      const itemToBeRemoved = cart?.cartItems?.filter(
        (c) => c.id === course.id
      );
      setCart({
        ...cart,
        cartItems: newCart,
        totalPrice:
          cart?.totalPrice - Number(itemToBeRemoved[0].resource?.price),
      });
      toast({
        description: "Resource removed to cart",
        position: "top",
        status: "success",
      });
    } catch (err) {
      toast({
        description: capitalizeFirstLetter(err.message),
        position: "top",
        status: "error",
      });
    }
    setIsCartLoading(false);
  };
  const renderContent = () => (
    <>
      <Text
        isTruncated={listItem ? false : true}
        bold
        mb={1}
        _hover={{
          textDecoration: "underline",
        }}
      >
        <Link href={`/courses/${course?.id}`}>
          {listItem
            ? truncateText(course?.resource?.title, 10)
            : course?.resource?.title}
        </Link>
      </Text>
      {course?.resource?.description && (
        <Text mb={1}>{truncateText(course?.resource?.description, 150)}</Text>
      )}

      {/* {course.instructor && (
        <Box w="fit-content">
          <Link href={`/users/${course.instructor.id}`}>
            <Flex
              alignItems="center"
              _hover={{
                textDecoration: "underline",
              }}
            > */}
      {/*<Image*/}
      {/*  src={course.instructor.photo}*/}
      {/*  alt={course.instructor.name}*/}
      {/*  height={{ base: "30px", tablet: "30px" }}*/}
      {/*  width={{ base: "30px", tablet: "30px" }}*/}
      {/*  flexShrink={0}*/}
      {/*  rounded="full"*/}
      {/*  mr={2}*/}
      {/*/>*/}

      {/* <Text opacity={0.8} isTruncated>
                {course.instructor.name}
              </Text>
            </Flex>
          </Link>
        </Box>
      )} */}
      {/* 
      {course.ratingsCount && (
        <Flex alignItems="center">
          <Ratings value={course.ratingsAverage} />

          <Text as="level5" ml={2}>
            ({course.ratingsCount})
          </Text>
        </Flex>
      )} */}

      <Flex alignItems="center" gridGap={5}>
        <Text bold as={course?.resource?.description ? "level2" : "level3"}>
          {formatPriceToNaira(Number(course?.resource?.price))}
        </Text>
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          asIcon
          sm
          data-testid="delete"
          onClick={handleRemoveFromCart}
          color="secondary.7"
        >
          <BiTrash />
        </Button>
      </Flex>
    </>
  );

  return (
    <Stack
      p={{ base: 1, tablet: 2 }}
      m={{ base: 1, tablet: 2 }}
      spacing={listItem ? 0 : 2}
      w={
        w ||
        (listItem
          ? "auto"
          : { base: "200px", tablet: "225px", laptop: "270px" })
      }
      //   maxW={listItem && { base: "200px", tablet: "400px", laptop: "700px" }}
      minH={!listItem && { base: "250px", laptop: "280px" }}
      bg="white"
      rounded="md"
      shadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
      flexDirection={listItem ? "row" : "column"}
    >
      <Image
        src={
          course?.resourceType === "library" ? Book : course?.resource?.image
        }
        alt={course?.resource?.title}
        w={
          course?.resource?.description ? "100px" : listItem ? "150px" : "100%"
        }
        h={
          course?.resource?.description
            ? "100px"
            : listItem
            ? ""
            : { base: "120px", tablet: "130px", laptop: "150px" }
        }
        rounded="md"
        flexShrink={0}
      />
      ){listItem ? <Stack pl={5}>{renderContent()}</Stack> : renderContent()}
    </Stack>
  );
};
