import { Flex, Grid, GridItem } from "@chakra-ui/layout";
import { Box, BreadcrumbItem } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { Route } from "react-router-dom";
import {
  Breadcrumb,
  Checkbox,
  Heading,
  Input,
  Link,
  Select,
  Spinner,
  Text,
  Textarea,
  Upload,
} from "../../../components";
import { useApp, useCache } from "../../../contexts";
import { useUpload } from "../../../hooks";
import { CreatePageLayout } from "../../../layouts";
import { adminCreateCourse, adminEditCourse } from "../../../services";
import {
  appendFormData,
  capitalizeFirstLetter,
  capitalizeWords,
} from "../../../utils";
import useCourseDetails from "../../user/Courses/CourseDetails/hooks/useCourseDetails";
const CreateCoursePage = ({ metadata: propMetadata }) => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = useForm();
  const thumbnailUpload = useUpload();
  const coursePreviewUpload = useUpload();
  const values = watch();
  const [loader, setUploadProgress] = useState(0);
  const { push } = useHistory();
  const appManager = useApp();
  const metadata = propMetadata || appManager.state.metadata;
  const { courseDetails, fetchCourseDetails } = useCourseDetails();
  const { id: courseId } = useParams();
  const isEditMode = useMemo(() => courseId && courseId !== "new", [courseId]);

  const { handleDelete } = useCache();

  // const setCourseAccept = (libraryTypeId) => {
  //   if (libraryTypeId === "pdf") {
  //     fileManager.handleAcceptChange("application/pdf");
  //   }

  //   if (libraryTypeId === "video") {
  //     fileManager.handleAcceptChange("video/mp4, video/mkv");
  //   }

  //   if (libraryTypeId === "audio") {
  //     fileManager.handleAcceptChange("audio/mpeg, audio/ogg, audio/wav");
  //   }
  // };
  const handleUploadProgress = (progress) => {
    setUploadProgress(progress);
  };
  const onSubmit = async (data) => {
    try {
      let courseThumbnail;
      let coursePreview;
      if (isEditMode) {
        courseThumbnail = thumbnailUpload.file;
        coursePreview = coursePreviewUpload.file;
      } else {
        courseThumbnail =
          thumbnailUpload.handleGetFileAndValidate("courseThumbnail");
        coursePreview =
          coursePreviewUpload.handleGetFileAndValidate("coursePreview");
      }

      // const certificate =
      // 	certificateUpload.handleGetFileAndValidate('Certificate');
      if (isEditMode && courseThumbnail) {
        data = {
          ...data,
          courseThumbnail,
        };
      } else if (isEditMode && coursePreview) {
        data = {
          ...data,
          coursePreview,
        };
      } else if (isEditMode && coursePreview && courseThumbnail) {
        data = {
          ...data,
          coursePreview,
          courseThumbnail,
        };
      } else if (isEditMode) {
        data = {
          ...data,
        };
      } else {
        data = {
          ...data,
          courseThumbnail,
          coursePreview,
        };
      }

      const body = appendFormData(data);
      console.log(body);
      const { course, message } = await (isEditMode
        ? adminEditCourse(courseId, body)
        : adminCreateCourse(body, handleUploadProgress));

      if (isEditMode) handleDelete(courseDetailsData.id);

      toast({
        description: capitalizeFirstLetter(message),
        position: "top",
        status: "success",
      });

      push(`/admin/courses/details/${course.id}/info`);
    } catch (error) {
      toast({
        description: capitalizeFirstLetter(error.message),
        position: "top",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (isEditMode) {
      fetchCourseDetails();
    }
  }, [fetchCourseDetails, isEditMode]);

  const courseDetailsData = courseDetails.data;
  const isLoading = courseDetails.loading;
  const isError = courseDetails.err;

  useEffect(() => {
    if (courseDetailsData) {
      thumbnailUpload.handleInitialImageSelect(courseDetailsData.coursePreview);
      // certificateUpload.handleInitialImageSelect(courseDetailsData.certificate);
      coursePreviewUpload.handleInitialVideoSelect(
        courseDetailsData.courseThumbnail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetailsData]);

  useEffect(() => {
    if (courseDetailsData) {
      setValue("title", courseDetailsData.title);
      setValue("description", courseDetailsData.description);
      setValue("price", courseDetailsData.price);
      setValue("discountedPrice", courseDetailsData.discountedPrice);
      setValue("noOfModules", courseDetailsData.noOfModules);
      setValue("objectives", courseDetailsData.objectives);
      setValue("featured", courseDetailsData.featured);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetailsData]);
  useEffect(() => {
    if (courseDetailsData && metadata?.departments) {
      setValue("categoryId", courseDetailsData.categoryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetailsData, metadata?.departments]);

  const populateSelectOptions = (data, filterBody = () => true) => {
    return data?.filter(filterBody)?.map((item) => ({
      label: capitalizeWords(item.categoryName),
      value: item.id,
    }));
  };

  return isLoading || isError ? (
    <Flex
      // Make the height 100% of the screen minus the `height` of the Header and Footer
      height="calc(100vh - 200px)"
      justifyContent="center"
      alignItems="center"
    >
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Heading color="red.500">{isError}</Heading>
      ) : null}
    </Flex>
  ) : (
    <>
      <Box paddingLeft={6}>
        <Breadcrumb
          item2={
            <BreadcrumbItem>
              <Link href="/admin/courses">Courses</Link>
            </BreadcrumbItem>
          }
          item3={
            <BreadcrumbItem isCurrentPage>
              <Link href="#">Create Courses</Link>
            </BreadcrumbItem>
          }
        />
      </Box>

      <CreatePageLayout
        title="Create Course"
        submitButtonText={isEditMode ? "Update Course" : "Submit"}
        onSubmit={handleSubmit(onSubmit)}
        submitButtonIsLoading={isSubmitting}
      >
        <Grid
          templateColumns={{
            lg: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            base: "1fr",
          }}
          gap={10}
          marginBottom={10}
        >
          <GridItem>
            <Input
              label="Course title"
              isRequired
              id="title"
              {...register("title", {
                required: "Title is required",
              })}
              error={errors.title?.message}
            />
              <Checkbox
                defaultChecked={false}
                onChange={(e) => {
                  setValue("featured", e.target.checked)
                }}
                mt={3}
                label="Check the box to add course to featured course"
              />
          </GridItem>
          <GridItem>
            <Select
              isRequired
              label="Select Category"
              options={populateSelectOptions(metadata?.courseCategory)}
              id="categoryId"
              isLoading={!metadata?.courseCategory}
              {...register("categoryId", {
                required: "Please select a department",
              })}
              error={errors.departmentId?.message}
            />
          </GridItem>
        </Grid>
        {/* Row 2 */}
        <Grid>
          <Input
            label="Course objectives (separate with commas)"
            isRequired
            id="objectives"
            {...register("objectives", {
              required: "Objectives is required",
            })}
            error={errors.objectives?.message}
          />
        </Grid>
        <Grid>
          <Input
            label="Number of modules"
            isRequired
            id="noOfModules"
            {...register("noOfModules", {
              required: "noOfModules is required",
            })}
            error={errors.title?.message}
          />
        </Grid>
        <Grid>
          <Input
            label="Price (NGN)"
            isRequired
            id="price"
            {...register("price", {
              required: "price is required",
            })}
            error={errors.price?.message}
          />
        </Grid>
        <Grid>
          <Input
            label="DiscountedPrice (NGN)"
            id="discountedPrice"
            isRequired={false}
            {...register("discountedPrice")}
            error={errors.discountedPrice?.message}
          />
        </Grid>
        {/* <Grid>
          <Input
            label="Number of modules"
            isRequired
            id="noOfModules"
            {...register("noOfModules", {
              required: "noOfModules is required",
            })}
            error={errors.title?.message}
          />
        </Grid> */}
        <Grid marginBottom={10}>
          <Textarea
            minHeight="150px"
            label="Course description"
            id="description"
            isRequired
            {...register("description", {
              required: "Please add a description",
              maxLength: 1000,
            })}
            error={
              errors.description?.type === "maxLength"
                ? "Maximum length of 5000 characters"
                : errors.description?.message
            }
          />
        </Grid>
        {/* Row 3 */}
        <Grid marginBottom={10}>
          <GridItem colSpan={2}>
            <Upload
              id="courseThumbnail"
              isRequired={isEditMode ? false : true}
              label="Course Image"
              onFileSelect={thumbnailUpload.handleFileSelect}
              imageUrl={
                thumbnailUpload.image.url
                  ? thumbnailUpload.image.url
                  : isEditMode &&
                    courseDetailsData &&
                    courseDetailsData.thumbnailUrl &&
                    courseDetailsData.thumbnailUrl
              }
              accept={thumbnailUpload.accept}
            />
          </GridItem>
        </Grid>
        <Grid marginBottom={10}>
          <GridItem colSpan={2}>
            <Upload
              id="coursePreview"
              isRequired={isEditMode ? false : true}
              label="Preview Video"
              onFileSelect={coursePreviewUpload.handleFileSelect}
              videoUrl={
                coursePreviewUpload.video.url
                  ? coursePreviewUpload.video.url
                  : isEditMode &&
                    courseDetailsData &&
                    courseDetailsData.previewUrl &&
                    courseDetailsData.previewUrl
              }
              // imageUrl={thumbnailUpload.image.url}
              accept={coursePreviewUpload.accept}
            />
            {coursePreviewUpload?.video?.url && (
              <Box marginTop={"30px"} width={"300px"}>
                <Text fontSize={"17px"} mb={"10px"}>
                  File progress
                </Text>
                <Box
                  overflow={"hidden"}
                  width={"100%"}
                  height={"8px"}
                  borderRadius={"10px"}
                  backgroundColor={"gray.300"}
                >
                  <Box
                    w={`${loader}%`}
                    h={"100%"}
                    transitionDuration={"0.5s"}
                    backgroundColor={"orange.600"}
                  ></Box>
                </Box>
                <Text
                  fontSize={"17px"}
                  display="flex"
                  justifyContent="flex-end"
                  mt={"10px"}
                >
                  {loader}%
                </Text>
              </Box>
            )}
          </GridItem>
        </Grid>
        {/* Row 4 */}
        {/* <Grid marginBottom={10}>
          <GridItem colSpan={2}>
            <Upload
              id="course-certificate"
              label="Course Certificate"
              isRequired={isEditMode ? false : true}
              onFileSelect={certificateUpload.handleFileSelect}
              imageUrl={certificateUpload.image.url}
              accept={certificateUpload.accept}
            />
          </GridItem>
        </Grid> */}
        {/* Row 5 */}
        <Grid marginBottom={10}>
          <GridItem>
            <Checkbox
              label="Use default certificate"
              borderColor="primary.base"
              isChecked
              disabled
            />
          </GridItem>
        </Grid>
      </CreatePageLayout>
    </>
  );
};

export const CreateCoursePageRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => <CreateCoursePage {...props} />} />
  );
};
