import { Image } from "@chakra-ui/image";
import { Box, Flex, HStack } from "@chakra-ui/layout";
import bgImagePlaceholder from "../../../assets/images/Auth.svg";
import onBoardingImage from "../../../assets/images/onboarding1.png";

import { Heading, Text, Brand } from "../../../components";

const Hero = () => {
  return (
    <Flex
      display={{ sm: "none", lg: "flex", md: "none", base: "none" }}
      flexDirection="column"
      flex={1}
    >
      <Box flex={1.5} backgroundColor="primary.hover" position="relative">
        <Image
          position="absolute"
          height="100%"
          width="100%"
          objectFit="cover"
          src={onBoardingImage}
          opacity={0.65}
        />
      </Box>

      <Flex
        flex={1}
        backgroundColor="secondary.8"
        color="white"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        position="relative"
        overflow="hidden"
      >
        <Image
          src={bgImagePlaceholder}
          width="100%"
          height="100%"
          objectFit="cover"
          opacity={0.5}
          top={0}
          left={0}
          position="absolute"
          alt="Course Header"
        />

        <Flex
          position="relative"
          flexDirection="column"
          alignItems="center"
          marginTop="-10"
          padding={10}
        >
          <Box paddingTop={10} marginBottom={16}>
            <Box mt={39} mb={3}>
              <Brand lg />
            </Box>
            <Heading
              as="h1"
              fontSize={42}
              size="md"
              color="white"
              marginBottom={6}
            >
              CREM E-LEARNING
            </Heading>

            <Text maxWidth="500px">
              ...<i> Promoting Online Education</i>...
            </Text>
          </Box>

          <HStack spacing={5}>
            <Box boxSize="10px" rounded="full" backgroundColor="white" />
            <Box boxSize="10px" rounded="full" backgroundColor="white" />
            <Box boxSize="10px" rounded="full" backgroundColor="white" />
          </HStack>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Hero;
