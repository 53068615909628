import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex } from "@chakra-ui/react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { Heading, Link,RichTextToView,Text } from "../../../../components";
import { MdPlayCircleOutline } from "react-icons/md";
import { truncateText } from "../../../../utils";

export const Modules = ({
    moduleIndex,
    module,
    courseId,
    moduleAccess,
  }) => {
    return (
      <Box pt={5} pb={0}>
        <Box>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            _hover={{ opacity: 0.8, cursor: "pointer" }}
          >
            <Box w="100%">
              <Accordion allowMultiple w={"100%"}>
                <AccordionItem borderTop="none" borderBottom="1px solid #E8E8E8">
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          _hover={{
                            bg: "#fff",
                          }}
                          pos={"relative"}
                          w={"320px"}
                          border={"none"}
                          _focus={{
                            border: "none",
                            bg: "#fff",
                          }}
                          paddingLeft="0px"
                        >
                          <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            color="#1D060B"
                          >
                            {isExpanded ? (
                              <Heading
                                as="h3"
                                fontSize="text.level3"
                                width="250px"
                              >
                                Module {moduleIndex + 1} -{" "}
                                {module.name}
                              </Heading>
                            ) : (
                              <Heading as="h3" fontSize="text.level3">
                                Module {moduleIndex + 1} -{" "}
                                {truncateText(module.name, 20)}
                              </Heading>
                            )}
                          </Box>
                          <Box as="div" pos={"absolute"} right={0} top={1}>
                            {isExpanded ? (
                              <AiFillCaretUp color="#800020" />
                            ) : (
                              <AiFillCaretDown color="#800020" />
                            )}
                          </Box>
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pl={4}>
                        {module ? (
                          <div>
                            {module?.moduleLessons?.map(
                              (purchasedLesson, li) => (
                                <Flex
                                  key={purchasedLesson.id}
                                  alignItems="start"
                                  flexDirection={"column"}
                                  marginBottom="15px"
                                >
                                  <Flex
                                  
                                    alignItems="center"
                                    justifyContent={"space-between"}
                                  >
                                    <Flex>
                                    <div>
                                    <Text as="level3" bold color="#800020">
                                        {purchasedLesson.title}
                                      </Text>
                                      <RichTextToView text={purchasedLesson.description}/>
                                    </div>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              )
                            )}
                          </div>
                        ) : (
                          <Text as="level4">No lessons</Text>
                        )}
                        {module.examination && (
                          <Link
                            onClick={() =>
                              localStorage.setItem("moduleId", module.id)
                            }
                            href={`/courses/take/${courseId}/module/${module.id}/assessment/${module.courseModule.id}`}
                          >
                            <Flex alignItems={"center"} gap={2}>
                              <Text
                                color="purple.500"
                                _hover={{
                                  textDecoration: "underline",
                                }}
                                as="level3"
                                bold
                                mb={{ base: 1, tablet: 2 }}
                              >
                                Quiz-
                              </Text>
                              <Text
                                color="purple.500"
                                _hover={{
                                  textDecoration: "underline",
                                }}
                                as="level3"
                                bold
                                mb={{ base: 1, tablet: 2 }}
                              >
                                {module.examination.title}
                              </Text>
                            </Flex>
                          </Link>
                        )}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>
          </Flex>
        </Box>
      </Box>
    );
  };