export const userGetCategoryListingRes = {
  data: [
    {
      id: 1,
      title: "Development",
    },
    {
      id: 2,
      title: "Design",
    },
    {
      id: 3,
      title: "Business",
    },
    {
      id: 4,
      title: "Lifestyle",
    },
  ],
};
