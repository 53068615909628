import { Box, Flex } from "@chakra-ui/layout";
import { Route, useParams } from "react-router-dom";
import { FaSortAmountUpAlt } from "react-icons/fa";
import {
  Button,
  Heading,
  Table,
  Text,
  Breadcrumb,
  Link,
} from "../../../../../components";
import { AdminMainAreaWrapper } from "../../../../../layouts/admin/MainArea/Wrapper";
import {
  adminDeleteForModuleId,
  adminDeleteMultipleCourses,
  adminGetModuleListing,
} from "../../../../../services";
import { Tag } from "@chakra-ui/tag";
import { BreadcrumbItem } from "@chakra-ui/react";
import { useTableRows } from "../../../../../hooks";
import { useApp } from "../../../../../contexts";

const ModuleListingPage = () => {
  const appManager = useApp();

  const departmentName = appManager.state.metadata?.courseCategory.map(
    (department) => department.categoryName
  );

  const tableProps = {
    filterControls: [
      {
        triggerText: "Course Category ",
        queryKey: "department",
        width: "125%",
        body: {
          checks: [
            ...(departmentName?.map((name) => ({
              label: name,
              queryValue: name,
            })) || []),
          ],
        },
      },
      {
        triggerText: "Sort",
        queryKey: "sort",
        triggerIcon: <FaSortAmountUpAlt />,
        width: "200px",
        position: "right-bottom",
        // noFilterTags: true,
        body: {
          radios: [
            {
              label: "Alphabetically: ascending",
              queryValue: "asc",
              additionalParams: { date: false },
            },
            {
              label: "Alphabetically: descending",
              queryValue: "desc",
              additionalParams: { date: false },
            },
            {
              label: "Date: ascending",
              queryValue: "asc",
              additionalParams: { date: true },
            },
            {
              label: "Date: descending",
              queryValue: "desc",
              additionalParams: { date: true },
            },
          ],
        },
      },
    ],

    columns: [
      {
        id: "1",
        key: "title",
        text: "Module Title",
        fraction: "3fr",
        renderContent: (data) => (
          <Link
            href={`/admin/courses/${data.courseId}/module/${data.moduleId}/view`}
          >
            <Text>{data.text}</Text>
          </Link>
        ),
      },
      {
        id: "3",
        key: "instructor",
        text: "Instructor",
        fraction: "100px",
      },
      {
        id: "5",
        key: "status",
        text: "Status",
        fraction: "100px",
        renderContent: (status) => (
          <Box>
            <Tag
              borderRadius="full"
              size="sm"
              backgroundColor={status ? "accent.4" : "accent.1"}
              color={status ? "accent.5" : "accent.3"}
            >
              <Text bold>{status ? "Active" : "Inactive"}</Text>
            </Tag>
          </Box>
        ),
      },
    ],

    options: {
      action: [
        {
          text: "View",
          link: (module) =>
            `/admin/courses/${module.courseId}/module/${module.id}/view`,
        },
        {
          text: "Edit",
          link: (module) =>
            `/admin/courses/${module.courseId}/modules/edit/${module.id}`,
        },
        {
          isDelete: true,
        },
      ],
      selection: false,
      multipleDeleteFetcher: async (module) => {
        await adminDeleteForModuleId(module[0]?.id)
      },
      pagination: true,
    },
  };

  const { id: courseId } = useParams();

  const mapCourseToRow = (module) => ({
    id: module.id,
    courseId: module.courseId,
    title: {
      text: module.title,
      moduleId: module.id,
      courseId: module.courseId,
    },
    status: module.active,
    // instructor: `${module.instructor.firstName} ${module.instructor.lastName}`,
  });
  
  const fetcher = (props) => async () => {
    const { modules, showingDocumentsCount, totalDocumentsCount } =
      await adminGetModuleListing(courseId, props?.params);
    const rows = modules
      .map(mapCourseToRow)
      .filter((row) => row.status); // Filter out inactive modules
  
    return { rows, showingDocumentsCount, totalDocumentsCount };
  };

  const { rows, setRows, fetchRowItems } = useTableRows(fetcher);

  return (
    <AdminMainAreaWrapper>
      <Breadcrumb
        item2={
          <BreadcrumbItem isCurrentPage>
            <Link href="/admin/courses">Courses</Link>
          </BreadcrumbItem>
        }
        item3={
          <BreadcrumbItem isCurrentPage>
            <Link href="#">Modules</Link>
          </BreadcrumbItem>
        }
      />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px"
        borderColor="accent.2"
        paddingBottom={5}
        marginBottom={5}
      >
        <Heading as="h1" fontSize="heading.h3">
          Modules
        </Heading>

        <Button link={`/admin/courses/${courseId}/modules/edit/new`}>
          Add Module
        </Button>
      </Flex>

      <Table
        {...tableProps}
        placeholder="Title, department, instructor"
        rows={rows}
        setRows={setRows}
        handleFetch={fetchRowItems}
      />
    </AdminMainAreaWrapper>
  );
};

export const ModuleListingPageRoute = ({ ...rest }) => {
  return (
    <Route {...rest} render={(props) => <ModuleListingPage {...props} />} />
  );
};

export default ModuleListingPage;
