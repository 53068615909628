import { useEffect } from 'react';
import { useToast } from '@chakra-ui/toast';
import { Flex, Grid, GridItem } from '@chakra-ui/layout';
import { Route, useParams, useHistory } from 'react-router-dom';
import {
    Input,
    Select,
    Upload,
    Breadcrumb,
    Link,
    Heading,
    Spinner,
    Textarea,
} from '../../../components';
import { CreatePageLayout } from '../../../layouts';
import { BreadcrumbItem, Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useUpload } from '../../../hooks';
import {
    appendFormData,
    capitalizeFirstLetter,
    populateSelectOptions,
} from '../../../utils';
import { useApp, useCache } from '../../../contexts';
import {
    adminEditLibraryFile,
    adminUploadLibraryFile,
} from '../../../services';
import useViewLibraryFile from './hook/useViewLibraryFile';

const CreateLibraryFilePage = () => {
    const { id: fileId } = useParams();
    const isEditMode = fileId && fileId !== 'new';
    const { push } = useHistory();
    const toast = useToast();
    const { handleDelete } = useCache();
    const { handleSubmit, register, watch, getValues, setValue, formState: { errors, isSubmitting }, } = useForm();
    const { state: { allMetadata }, } = useApp();

    const fileManager = useUpload();
    const previewManager = useUpload(); // Separate upload manager for preview

    const { library } = useViewLibraryFile();
    const libraryFile = library.data;
    const isLoading = library.loading;
    const isError = library.error;

    useEffect(() => {
        if (libraryFile) {
            setValue('title', libraryFile.title);
            setValue('price', libraryFile.price);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [libraryFile]);

    useEffect(() => {
        if (libraryFile && allMetadata?.departments) {
            setValue('departmentId', libraryFile?.departmentId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [libraryFile, allMetadata?.departments]);

    useEffect(() => {
        if (libraryFile) {
            setValue('description', libraryFile.description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [libraryFile]);

    const setLibraryAccept = libraryTypeId => {
        if (libraryTypeId === 'pdf') {
            fileManager.handleAcceptChange('application/pdf');
            previewManager.handleAcceptChange('application/pdf');
        }

        if (libraryTypeId === 'video') {
            fileManager.handleAcceptChange('video/mp4, video/mkv');
            previewManager.handleAcceptChange('video/mp4, video/mkv');
        }

        if (libraryTypeId === 'audio') {
            fileManager.handleAcceptChange('audio/mpeg, audio/ogg, audio/wav');
            previewManager.handleAcceptChange('audio/mpeg, audio/ogg, audio/wav');
        }
    };

    useEffect(() => {
        if (libraryFile) {
            setValue('libraryTypeId', libraryFile.fileType);
            setLibraryAccept(libraryFile.fileType);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [libraryFile]);

    useEffect(() => {
        if (libraryFile) {
            const fileIsAVideo = libraryFile.fileExtension === 'mp4';
            const fileIsPDF = libraryFile.fileExtension === 'pdf';
            const fileIsAudio = libraryFile.fileExtension === 'mpeg';

            if (fileIsAVideo) {
                fileManager.handleInitialVideoSelect(libraryFile.file);
            }
            if (fileIsPDF) {
                fileManager.handleInitialPdfSelect(libraryFile.file);
            }
            if (fileIsAudio) {
                fileManager.handleInitialAudioSelect(libraryFile.file);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [libraryFile]);

    useEffect(() => {
        const subscription = watch((data, { name }) => {
            if (name === 'libraryTypeId') {
                fileManager.handleFileSelect(null);
                previewManager.handleFileSelect(null);
                setLibraryAccept(data.libraryTypeId);
            }
        });

        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const onSubmit = async data => {
        try {
            const file = fileManager.handleGetFileAndValidate('Library File', isEditMode);
            const preview = previewManager.handleGetFileAndValidate('Library Preview', isEditMode);

            data = {
                ...data,
                libraryTypeId: null,
                file,
                preview, // Add preview file to data
            };
            console.log(file,preview);
            if (isEditMode) Reflect.deleteProperty(data, 'fileId');
            Reflect.deleteProperty(data, 'departmentId');
            const body = appendFormData(data);

            const { message, library } = await (isEditMode
                ? adminEditLibraryFile(fileId, body)
                : adminUploadLibraryFile(body));

            if (isEditMode) handleDelete(library.id);
               push("/admin/library")
            toast({
                description: capitalizeFirstLetter(message),
                position: 'top',
                status: 'success',
            });

            if (isEditMode) push(`/admin/library/details/${fileId}`);
        } catch (error) {
            toast({
                description: capitalizeFirstLetter(error.message),
                position: 'top',
                status: 'error',
            });
        }
    };

    return isEditMode && (isLoading || isError) ? (
        <Flex
            height='calc(100vh - 200px)'
            justifyContent='center'
            alignItems='center'
        >
            {isLoading ? (
                <Spinner />
            ) : isError ? (
                <Heading color='red.500'>{isError}</Heading>
            ) : null}
        </Flex>
    ) : (
        <>
            <Box paddingLeft={6}>
                <Breadcrumb
                    item2={
                        <BreadcrumbItem>
                            <Link href='/admin/library'>Library</Link>
                        </BreadcrumbItem>
                    }
                    item3={
                        <BreadcrumbItem isCurrentPage>
                            <Link href='#'>{isEditMode ? 'Edit' : 'Create'}</Link>
                        </BreadcrumbItem>
                    }
                />
            </Box>

            <CreatePageLayout
                title={isEditMode ? 'Edit File details' : 'Upload File'}
                submitButtonText={
                    isSubmitting
                        ? 'Please wait this might take a while'
                        : isEditMode
                        ? 'Update File'
                        : 'Add File'
                }
                onSubmit={handleSubmit(onSubmit)}
                submitButtonIsLoading={isSubmitting}
            >
                <Grid templateColumns='repeat(2, 1fr)' gap={10} marginBottom={10}>
                    <GridItem>
                        <Input
                            label='Title'
                            id='title'
                            {...register('title', {
                                required: 'Title is required',
                            })}
                            error={errors.title?.message}
                        />
                    </GridItem>
                    <GridItem>
                        <Input
                            label='Price'
                            id='price'
                            isRequired
                            {...register('price', {
                                required: 'Price is required',
                            })}
                            error={errors.price?.message}
                        />
                    </GridItem>
                </Grid>
                <Grid marginBottom={10}>
                    <Textarea
                        minHeight='150px'
                        label='Description'
                        id='description'
                        isRequired
                        {...register('description', {
                            required: 'Please add a description',
                            maxLength: 1000,
                        })}
                        error={
                            errors.description?.type === 'maxLength'
                                ? 'Maximum length of 1000 characters'
                                : errors.description?.message
                        }
                    />
                </Grid>

                <Grid marginBottom={10}>
                    <GridItem width='50%'>
                        <Select
                            id='libraryTypeId'
                            label='File type'
                            options={[
                                { value: 'pdf', label: 'Pdf' },
                                { value: 'video', label: 'Video' },
                                { value: 'audio', label: 'Audio' },
                            ]}
                            isRequired
                            error={errors.libraryTypeId?.message}
                            {...register('libraryTypeId', {
                                required: 'File type is required',
                            })}
                        />
                    </GridItem>
                </Grid>
                <Grid marginBottom={10}>
                    <GridItem colSpan={2}>
                        <Upload
                            id='file'
                            label='Library file'
                            isRequired={isEditMode ? false : true}
                            videoUrl={fileManager.video.url}
                            pdfUrl={fileManager.pdf.url}
                            audioUrl={fileManager.audio.url}
                            onFileSelect={fileManager.handleFileSelect}
                            accept={fileManager.accept}
                        />
                    </GridItem>
                </Grid>
                <Grid marginBottom={10}>
                    <GridItem colSpan={2}>
                        <Upload
                            id='preview'
                            label='Library Preview'
                            isRequired={isEditMode ? false : true}
                            videoUrl={previewManager.video.url}
                            pdfUrl={previewManager.pdf.url}
                            audioUrl={previewManager.audio.url}
                            onFileSelect={previewManager.handleFileSelect}
                            accept={previewManager.accept}
                        />
                    </GridItem>
                </Grid>
            </CreatePageLayout>
        </>
    );
};

export const CreateLibraryFilePageRoute = ({
    component: Component,
    ...rest
}) => {
    return (
        <Route {...rest} render={props => <CreateLibraryFilePage {...props} />} />
    );
};
