import { useViewModuleInfo } from "../../useModule";
import { useCallback, useEffect, useState } from "react";
import { Route, useParams } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/layout";
import { BreadcrumbItem } from "@chakra-ui/react";
import {
  Text,
  Heading,
  Breadcrumb,
  SkeletonText,
  Button,
  Link,
  Spinner,
} from "../../../../../components";
import { FaEdit } from "react-icons/fa";
import { EmptyState } from "../../../../../layouts";
import { adminRequestModuleDetailsForModuleId } from "../../../../../services";
import { useFetchAndCache } from "../../../../../hooks";
import useModule from "../../../../../hooks/useModule";
const ViewModuleInfoPage = () => {
  const { isLoading, isError, moduleDetails, moduleId, courseId } = useModule();
  console.log(moduleDetails);
  return isLoading || isError ? (
    <Flex
      // Make the height 100% of the screen minus the `height` of the Header and Footer
      height="calc(100vh - 200px)"
      justifyContent="center"
      alignItems="center"
    >
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <EmptyState
          cta={
            <Button onClick={() => window.location.reload()}>Try Again</Button>
          }
          heading="Oops an error occurred"
          description="An unexpected error occurred, please try again later"
        />
      ) : null}
    </Flex>
  ) : (
    <Box paddingX={4}>
      <Box paddingX={4}>
        <Breadcrumb
          item2={
            <BreadcrumbItem isCurrentPage>
              <Link href="/admin/courses">Courses </Link>
            </BreadcrumbItem>
          }
          item3={
            <BreadcrumbItem isCurrentPage>
              <Link href={`/admin/courses/details/${courseId}/modules`}>
                Modules
              </Link>
            </BreadcrumbItem>
          }
          item4={
            <BreadcrumbItem isCurrentPage>
              <Link href="#">Info</Link>
            </BreadcrumbItem>
          }
        />
      </Box>

      <Box marginY={2} padding={4}>
        <Flex
          paddingBottom={8}
          justifyContent="space-between"
          gap={4}
          flexDirection={{lg:"row", md:"row", base:"column"}}
          alignContent="center"
          
        >
          <Heading fontSize="heading.h3">Module details</Heading>
          <Flex>
            <Button
              sizes="small"
              disabled={!moduleDetails}
              marginRight={4}
              link={`/admin/courses/details/${moduleId}/lessons`}
            >
              View Lessons
            </Button>
            <Button
              sizes="small"
              disabled={!moduleDetails}
              marginRight={4}
              link={`/admin/courses/${courseId}/module/${moduleId}/exam`}
            >
              Quiz
            </Button>

            <Button
              disabled={!moduleDetails}
              paddingLeft={2}
              sizes="small"
              rightIcon={<FaEdit />}
              secondary
              link={`/admin/courses/${courseId}/modules/edit/${moduleId}`}
            >
              Edit
            </Button>
          </Flex>
        </Flex>

        <Box backgroundColor="white" paddingX={10} paddingY={12} shadow="md">
          {isLoading ? (
            <SkeletonText width="400px" paddingBottom={8} numberOfLines={1} />
          ) : (
            <>
              <Heading
                as="h3"
                fontSize="heading.h4"
                fontWeight="700"
                color="black"
              >
                Title
              </Heading>
              <Text paddingTop={4} paddingBottom={6} color="accent.3">
                {moduleDetails?.name}
              </Text>
            </>
          )}

          <Box marginBottom={10}>
            {isLoading ? (
              <SkeletonText numberOfLines={14} />
            ) : (
              <>
                <Heading fontSize="heading.h6">Description</Heading>
                <Text paddingTop={4} color="accent.3">
                  {moduleDetails?.description}
                </Text>
              </>
            )}
          </Box>
          <Box marginBottom={10}>
            {isLoading ? (
              <SkeletonText numberOfLines={14} />
            ) : (
              <>
                <Heading fontSize="heading.h6">Number Of Lessons</Heading>
                <Text paddingTop={4} color="accent.3">
                  {moduleDetails?.numberOfLessons}
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ViewModuleInfoPageRoute = ({ ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ViewModuleInfoPage key={props.match.params.parameter} {...props} />
      )}
    />
  );
};
