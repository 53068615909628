import { useState } from "react";
import { useApp, useTakeCourse } from "../../../../../contexts";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const mapLessonsToLinks = (course) => {
  console.log(course, "mimi");
  const moduleLinks = course?.purchasedCourseModules.map((pm, i) => ({
    id: pm.id,
    text: pm.courseModule.name,
    disabled: course.purchasedCourseModules[i - 1]?.status !== "Completed",
    hasCompleted: pm.status === "Completed",
    lessonLinks: pm.purchasedModuleLessons.map((pl, j) => ({
      id: pl.id,
      to: `/courses/take/${course.id}/module/${pm.id}/lessons/${pl.id}`,
      text: pl.moduleLesson.title,
      disabled: pm.purchasedModuleLessons[j - 1]
        ? pm.purchasedModuleLessons[j - 1].status !== "Completed"
        : false,
      type: pl.moduleLesson.fileType.type,
      hasCompleted: pl.status === "Completed",
    })),
    examinationLink: pm.courseModule.examination
      ? {
          id: pm.courseModule.examination.id,
          to: `/courses/take/${course.id}/module/${pm.id}/assessment/${pm.courseModule.id}`,
          text: `Quiz - ${pm.courseModule.examination.title}`,
          // disabled:
          //   !isOngoing(assessment.startTime, assessment.endTime) ||
          //   assessment.hasCompleted,
          type: "assessment",
          // hasCompleted: assessment.hasCompleted,
        }
      : null,
  }));
  console.log(moduleLinks, "hi");
  return moduleLinks;
};

/**
 * TakeCourseLayout's Sidebar functionality `Manager`
 * @returns Object { links: `Array<Object>` | `null`, courseTitle: `string`, isLoading: `boolean` }
 */
const useSidebar = () => {
  const appManager = useApp();
  const {
    state: { data: course, isLoading },
    setState: setCourseState,
  } = useTakeCourse();

  const sidebarLinkClickedState = useState(false);

  const links = mapLessonsToLinks(course);

  const loading = isLoading || !appManager.state.metadata; // TODO:replace with `!appManager.metadataIsLoading`

  return {
    course,
    setCourseState,
    links,
    isLoading: loading,
    sidebarLinkClickedState,
  };
};

export default useSidebar;
