import { http } from "../http";

/**
 * Endpoint to get user `book-listing`
 *
 * @returns {Promise<{ books: BookListArray }>}
 */
export const userGetBookListing = async () => {
  const path = `/library/pdf`;

  const {
    data: { data },
  } = await http.get(path);

  return {
    books: data.map((book) => ({
      id: book.id,
      description: book.description,
      title: book.title,
      file: book.file,
      fileExtension: book.fileExtension,
      price: book.price,
      isPurchased: book.isPurchased,
      instructor: {
        // id: book.user.id,
        // profilePics: book.user.profilePics,
        firstName: book.resourceUploader.firstName,
        lastName: book.resourceUploader.lastName,
        // title: book.user.title,
      },
    })),
  };
};

/**
 * Endpoint to get user `audio-listing`
 *
 * @returns {Promise<{ audio: AudioListArray }>}
 */
export const userGetAudioListing = async () => {
  const path = `/library/audio`;

  const {
    data: { data },
  } = await http.get(path);

  return {
    audio: data.map((aud) => ({
      id: aud.id,
      description: aud.description,
      title: aud.title,
      file: aud.file,
      fileExtension: aud.fileExtension,
      price: aud.price,
      isPurchased: aud.isPurchased,
      instructor: {
        // id: aud.user.id,
        // profilePics: aud.user.profilePics,
        firstName: aud.resourceUploader.firstName,
        lastName: aud.resourceUploader.lastName,
        // title: aud.user.title,
      },
    })),
  };
};
export const adminDeleteLibrary = async (id) => {
  const path = `/library/${id}`;

  await http.delete(path);
};
/**
 * Endpoint to get user `video-listing`
 *
 * @returns {Promise<{ videos: VideoListArray }>}
 */
export const userGetVideoListing = async () => {
  const path = `/library/video`;

  const {
    data: { data },
  } = await http.get(path);
  console.log(data);
  return {
    videos: data.map((video) => ({
      id: video.id,
      description: video.description,
      title: video.title,
      file: video.file,
      fileExtension: video.fileExtension,
      price: video.price,
      isPurchased: video.isPurchased,
      instructor: {
        // id: video.resourceUploader.id,
        // profilePics: video.resourceUploader.profilePics,
        firstName: video.resourceUploader.firstName,
        lastName: video.resourceUploader.lastName,
        // title: video.resourceUploader.title,
      },
    })),
  };
};

/**
 * Endpoint to get `library-listing`
 *
 * @param {object} params
 *
 * @returns {Promise<{ data: LibraryListArray }>}
 */
export const adminLibraryListing = async (params) => {
  const path = `/library/admin`;

  const {
    data: { data },
  } = await http.get(path, { params });

  return {
    library: data.rows.map((lib) => ({
      id: lib.id,
      title: lib.title,
      description: lib.description,
      // departmentId: lib.departmentId,
      // department: {
      //   id: lib.department.id,
      //   name: lib.department.name,
      // },
      fileType: lib.fileType,
      // libraryType: {
      //   id: lib.libraryType.id,
      //   name: lib.libraryType.name,
      // },
      file: lib.file,
      fileExtension: lib.fileExtension,
      instructor: {
        id: lib.resourceUploader.id,
        // profilePics: lib.user.profilePics,
        firstName: lib.resourceUploader.firstName,
        lastName: lib.resourceUploader.lastName,
        // title: lib.user.title,
      },
    })),
    showingDocumentsCount: data.rows.length,
    totalDocumentsCount: data.rows.length,
  };
};

/**
 * Endpoint to get `library-file-details`
 * @param {string} id - fileId
 *
 * @returns {Promise<{ library: Library }>}
 */
export const requestLibraryFileDetails = async (id) => {
  const path = `/library/details/${id}`;

  const {
    data: { data },
  } = await http.get(path);

  return {
    library: {
      ...data,
    },
  };
};

/**
 * Endpoint to for admin to upload library file
 * @param {{ title: string, departmentId: string, description: string, libraryTypeId: string, file: File }} body
 *
 * @returns {Promise<{ message: string, library: { id: string } }>}
 */
export const adminUploadLibraryFile = async (body) => {
  const path = `/library/create`;

  const {
    data: { message, data },
  } = await http.post(path, body);

  const library = {
    id: data.id,
  };

  return { message, library };
};

/**
 * Endpoint to for admin to edit a library file
 * @param {{ title: string, departmentId: string, description: string, libraryTypeId: string, file: File }} body
 *
 * @returns {Promise<{ message: string, library: { id: string } }>}
 */
export const adminEditLibraryFile = async (id, body) => {
  const path = `/library/edit/${id}`;

  const {
    data: { message, data },
  } = await http.patch(path, body);

  const library = {
    id: data[0].id,
  };

  return { message, library };
};

export const userGetLibraryPreview = async (params) => {
  try {
    const path = `/library/landing`;

    const { data } = await http.get(path, { params });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};
