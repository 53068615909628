import { Box, Flex } from "@chakra-ui/layout";
import { Text } from "../../components";
import { pageWrapperSpacing_userPages } from "../../theme/breakpoints";
import {
  Twitter,
  Email,
  LinkedIn,
  Facebook,
  Instagram,
} from "@material-ui/icons";
const date = new Date();
const year = date.getFullYear();
const Footer = () => {
  return (
    <Box
      as="footer"
      color="white"
      backgroundColor="secondary.base"
      height="120px"
      padding={5}
      px={12}
      overflowX="hidden"
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexDirection={{ lg: "row", md: "column", base: "column" }}
    >
      <Box as="div" display={"flex"} alignItems={"center"}>
        <Text as="level1">Powered by CREM TRAINING {year}</Text>
      </Box>
      <Flex>
        <Box as="div" display={"flex"} alignItems={"center"}>
          <Box as="div" display="flex" alignItems="center" gap={5}>
            <Text
              as="level3"
              display={{ lg: "block", md: "block", base: "none" }}
            >
              Kindly reach out to us on{" "}
            </Text>
            <a href="https://twitter.com/crem_nigeria" target="_blank">
              {" "}
              <Twitter style={{ width: "30px" }} />
            </a>
            <a href="mailto:info@cremnigeria.org" target="_blank">
              {" "}
              <Email style={{ width: "30px" }} />
            </a>
            <a
              href="https://www.linkedin.com/company/crem-nigeria"
              target="_blank"
            >
              <LinkedIn style={{ width: "30px" }} />
            </a>
            <a href="facebook.com/CREMNigeria" target="_blank">
              <Facebook style={{ width: "30px" }} />
            </a>
            <a href="https://instagram.com/cremnigeria" target="_blank">
              <Instagram style={{ width: "30px" }} />
            </a>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
