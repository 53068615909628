const colors = {
  primary: {
    base: "#800020",
    hover: "#8000209d",
  },
  secondary: {
    base: "#800020",
    "05": "#ffc5d336",
    1: "#FFC5D3",
    2: "#FF93AE",
    3: "#FC5780",
    4: "#DD3A63",
    5: "#BD1F46",
    6: "#9B203F",
    7: "#5A051A",
    8: "#3C0311",
    9: "#1D060B",
  },
  accent: {
    1: "#E8E8E8",
    2: "#C1C9D2",
    3: "#697386",
    4: "#7FFFDF",
    5: "#27AE60",
    6: "#5298DA",
    7: "#FF8E09",
    8: "#FA09FF",
    9: "#DFE0EB",
  },
  others: {
    1: "#FDFDFD",
    2: "#46BD84",
    3: "#800020",
    4: "#0083E2",
    5: "#4FAC16",
    6: "#DCFDD4",
    7: "#FAFDD4",
    8: "#B6A92E",
  },
  white: "#FFFFFF",
  black: "#000000",
};

export default colors;
