import { Grid } from "@chakra-ui/layout";
import { Redirect } from "react-router-dom";
import { Switch } from "react-router-dom";
import {
  ForgotPasswordPageRoute,
  NewPasswordPageRoute,
  RegistrationPageRoute,
  SigninPageRoute,
} from "../../../pages/user";
import { VerifyEmailRoute } from "../../../pages/user/OnBoarding/VerifyEmail";

const MainArea = () => {
  return (
    <Grid
      overflowY="auto"
      as="main"
      minWidth={{ base: "500px", xl: "700px" }}
      placeItems="center"
      py={10}
    >
      <Switch>
        <RegistrationPageRoute exact path="/auth/signup" />
        <SigninPageRoute exact path="/auth/signin" />
        <ForgotPasswordPageRoute exact path="/auth/forgot-password" />
        <NewPasswordPageRoute exact path="/auth/new-password/:token" />
        <VerifyEmailRoute exact path="/auth/emailverification/:token" />

        <Redirect to="/not-found" />
      </Switch>
    </Grid>
  );
};

export default MainArea;
// \resetpassword\eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im9sYWRpcHVwb2JsZXNzaW5nN0BnbWFpbC5jb20iLCJpYXQiOjE2NTQzMzY3MTcsImV4cCI6MTY1NjkyODcxN30.VXgdSgshuNFpK6lDgbENvB_nTbiEUWLeWqe6LdNusAc
