import { Flex } from "@chakra-ui/layout";
import { Redirect, Route, Switch } from "react-router-dom";
import { TakeCourseProvider } from "../../../../contexts";
import {
  useRedirectNewUserToNewPasswordPage,
  useRedirectNonAuthUserToSigninPage,
} from "../../../../hooks";
import {
  AssessmentPreviewPageRoute,
  LessonDetailsPageRoute,
} from "../../../../pages/user";
import breakpoints from "../../../../theme/breakpoints";
import useSidebar from "./hooks/useSidebar";
import Sidebar from "./Sidebar";

const TakeCourseLayout = () => {
  const sidebarManager = useSidebar();
  useRedirectNonAuthUserToSigninPage();
  useRedirectNewUserToNewPasswordPage();

  return (
    <Flex maxWidth={breakpoints["4k"]} marginX="auto" height="100vh">
      <Sidebar manager={sidebarManager} />

      <Switch>
        {/* <LessonDetailsPageRoute exact
          path="/takecourse/courses/:course_id/lessons/:lesson_id"
          sidebarLinks={sidebarManager.links}
          setCourseState={sidebarManager.setCourseState}
        /> */}
        <LessonDetailsPageRoute
          exact
          path="/courses/take/:course_id/module/:module_id/lessons/:lesson_id"
          sidebarLinks={sidebarManager.links}
          setCourseState={sidebarManager.setCourseState}
        />
        <AssessmentPreviewPageRoute
          exact
          path="/courses/take/:course_id/module/:module_id/assessment/:assessment_id" // TODO: replace `/take` to `/preview`
          sidebarLinks={sidebarManager.links}
          sidebarLinkClickedState={sidebarManager.sidebarLinkClickedState}
        />

        <Redirect to="/not-found" />
      </Switch>
    </Flex>
  );
};

export const TakeCourseLayoutRoute = ({ ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <TakeCourseProvider>
          <TakeCourseLayout {...props} />
        </TakeCourseProvider>
      )}
    />
  );
};
