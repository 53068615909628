import { FaStar } from "react-icons/fa";
import React from "react";
function Star({ filled, onClick }) {
  return (
    <FaStar
      color={filled ? "orange" : "lightgray"}
      onClick={onClick}
      display="inline"
      width="30px"
      height="40px"
    />
  );
}
export default Star;
