export const requestMyDataRes = {
  data: {
    id: "userId_2",
    firstName: "Victoria",
    lastName: "Vivian :)",
    email: "admin@admin.io",
    phone: "08012345678",
    gender: "female",
    profilePics:
      "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1740&q=80",
    userRoleId: "userRoleId_2",
    departmentId: null,
    isInviteActive: false,
    currentDateTime: new Date().toISOString(),
  },
};
