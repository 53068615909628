import { useState } from "react";
import Star from "../Star";
import { Flex } from "@chakra-ui/react";
import ProgressBar from "../ProgressBar";
function StarRating({ onChange, rating, setRating }) {
  const changeRating = (newRating) => {
    setRating(newRating);
    onChange?.(newRating);
  };
  const testData = { bgcolor: "red", completed: rating * 20 };

  return (
    <>
      <Flex alignItems="center" gap={3}>
        {[1, 2, 3, 4, 5].map((value) => (
          <Star
            key={value}
            filled={value <= rating}
            onClick={() => changeRating(value)}
          />
        ))}
      </Flex>
      <ProgressBar completed={testData.completed} />
    </>
  );
}
export default StarRating;
