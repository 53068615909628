import { Flex } from "@chakra-ui/layout";
import { BreadcrumbItem } from "@chakra-ui/react";
import dayjs from "dayjs";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { Route } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Heading,
  Link,
  Table,
  Text,
} from "../../../components";
import { useTableRows } from "../../../hooks";
import { AdminMainAreaWrapper } from "../../../layouts/admin/MainArea/Wrapper";
import {
  adminDeleteMultipleCategories,
  adminDeleteMultipleCourses,
  adminGetDepartmentListing,
} from "../../../services";

const tableProps = {
  filterControls: [
    {
      triggerText: "Sort",
      queryKey: "sort",
      triggerIcon: <FaSortAmountUpAlt />,
      width: "200px",
      position: "right-bottom",
      // noFilterTags: true,
      body: {
        radios: [
          {
            label: "Alphabetically: ascending",
            queryValue: "asc",
            additionalParams: { date: false },
          },
          {
            label: "Alphabetically: descending",
            queryValue: "desc",
            additionalParams: { date: false },
          },
          {
            label: "Date: ascending",
            queryValue: "asc",
            additionalParams: { date: true },
          },
          {
            label: "Date: descending",
            queryValue: "desc",
            additionalParams: { date: true },
          },
        ],
      },
    },
  ],

  columns: [
    {
      id: "2",
      key: "title",
      text: "Title",
      fraction: "2fr",
      renderContent: (data) => (
        // <Link href={`/admin/departments/details/${data.departmentId}/info`}>
        <Text>{data.text}</Text>
        // </Link>
      ),
    },
    {
      id: "4",
      key: "createdAt",
      text: "Created at",
      fraction: "200px",
    },
    {
      id: "5",
      key: "noOfusers",
      text: "No of Courses",
      fraction: "150px",
    },
  ],

  options: {
    action: [
    
      {
        isDelete: true,
      },
    ],
    selection: true,
    multipleDeleteFetcher: async (selectedDepartments) => {
      await adminDeleteMultipleCategories(selectedDepartments);
    },
    pagination: true,
  },
};

const DepartmentListingPage = () => {
  const mapDepartmentToRow = (department) => ({
    id: department.id,
    title: { text: department.name, departmentId: department.id },
    createdAt: dayjs(department.createdAt).format("DD/MM/YYYY h:mm a"),
    noOfusers: department.noOfusers,
  });

  const fetcher = (props) => async () => {
    const { departments, showingDocumentsCount, totalDocumentsCount } =
      await adminGetDepartmentListing(props?.params);

    const rows = departments.map(mapDepartmentToRow);

    return { rows, showingDocumentsCount, totalDocumentsCount };
  };

  const { rows, setRows, fetchRowItems } = useTableRows(fetcher);

  return (
    <AdminMainAreaWrapper>
      <Breadcrumb
        item2={
          <BreadcrumbItem isCurrentPage>
            <Link href="#">Categories</Link>
          </BreadcrumbItem>
        }
      />
      <Flex
         justifyContent="space-between"
         gap={4}
         flexDirection={{lg:"row", md:"row", base:"column"}}
         alignItems={{lg:"center", md:"center", base:"start"}}
        borderBottom="1px"
        borderColor="accent.2"
        paddingBottom={5}
        marginBottom={5}
      >
        <Heading as="h1" fontSize="heading.h3">
          Categories
        </Heading>

        <Button link={`/admin/category/create`}>Add Course Category</Button>
      </Flex>

      <Table
        {...tableProps}
        placeholder="Title"
        rows={rows}
        setRows={setRows}
        handleFetch={fetchRowItems}
      />
    </AdminMainAreaWrapper>
  );
};

export const DepartmentListingPageRoute = ({ ...rest }) => {
  return (
    <Route {...rest} render={(props) => <DepartmentListingPage {...props} />} />
  );
};

export default DepartmentListingPage;
