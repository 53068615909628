import { Box, Flex } from "@chakra-ui/react";
import { Brand, Button, SearchBar, Text } from "../../../../components";
import { FiShoppingCart, FiMenu, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useState } from "react";
export const Header = ({ onSearch, length }) => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      display={"flex"}
      pos="fixed"
      top="0"
      left="0"
      w="full"
      justifyContent={"space-between"}
      alignItems={"center"}
      px={6}
      py={3}
      shadow={"lg"}
      zIndex="1000"
      bg="#fff"
    >
      <Brand href="/home-preview" />

      {onSearch && (
        <SearchBar
          display={{
            base: "none",
            lg: "flex",
            md: "flex",
            sm: "none",
          }}
          onChange={onSearch}
          w={"400px"}
          placeholder="Search for courses"
        />
      )}

      <Flex alignItems={"center"} gap={6}>
        <Box pos="relative">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pos="absolute"
            top="0"
            fontSize={"12px"}
            rounded="full"
            right="0"
            zIndex="10"
            bg="#800040"
            w="20px"
            h="20px"
            color="#fff"
          >
            {length}
          </Box>
          <Button link="/auth/signin" asIcon>
            <FiShoppingCart />
          </Button>
        </Box>
        {/* <Button
          link="/home-preview/testimonials"
          ghost
          style={{
            fontSize: "16px",
            color: "#000",
          }}
          display={{
            base: "none",
            lg: "block",
            md: "none",
            sm: "none",
          }}
        >
          Testimonials
        </Button> */}
        <Button
          onClick={() => {
            setOpen(true);
          }}
          asIcon
          display={{
            base: "flex",
            lg: "none",
            md: "flex",
            sm: "flex",
          }}
        >
          <FiMenu />
        </Button>
        <Button
          display={{
            base: "none",
            lg: "block",
            md: "none",
            sm: "none",
          }}
          link="/auth/signin"
        >
          Sign in
        </Button>
        <Button
          display={{
            base: "none",
            lg: "block",
            md: "none",
            sm: "none",
          }}
          secondary
          link="/auth/signup"
          ml="-10px"
        >
          Register
        </Button>
        <Button
          display={{
            base: "none",
            lg: "block",
            md: "none",
            sm: "none",
          }}
          secondary
          link="/auth/signin"
          ml="-10px"
        >
          Forum
        </Button>
      </Flex>
      <Box
        w="full"
        display={{
          base: "flex",
          lg: "none",
          sm: "flex",
          md: "flex",
        }}
        flexDirection="column"
        gap={8}
        transitionDuration={"0.5s"}
        justifyContent="center"
        alignItems="center"
        zIndex={10000}
        h="full"
        top={open ? "0" : "-100%"}
        left="0"
        pos="fixed"
        bg="#000000a2"
      >
        <Button
          pos="absolute"
          right="30px"
          top="30px"
          onClick={() => {
            setOpen(false);
          }}
          asIcon
        >
          <FiX />
        </Button>
        <SearchBar
          onChange={onSearch}
          w={"400px"}
          placeholder="Search for courses"
        />
        <Link
          to="/auth/signup"
          style={{
            fontSize: "20px",
            color: "#fff",
          }}
        >
          Register Now
        </Link>
        <Link
          to="/auth/signin"
          style={{
            fontSize: "20px",
            color: "#fff",
          }}
        >
          Login to your account
        </Link>
        {/* <Link
          to="/testimonals"
          style={{
            fontSize: "20px",
            color: "#fff",
          }}
        >
          Testimonials
        </Link> */}
      </Box>
    </Box>
  );
};
