import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import {
  mainAreaHeight_userPages,
  pageWrapperSpacing_userPages,
} from "../../../../theme/breakpoints";
import { Button, Heading, Text } from "../../../../components";
import { useState } from "react";
import {
  AboutPage,
  ModuleAccordion,
  MyCoursesPage,
  Section,
} from "../../Courses/CourseDetails/NewModulesDetail";
import { BsPlayFill } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { formatDateToISO, formatPriceToNaira } from "../../../../utils";
import { Modules } from "./Modules";

export const NewModulesPageContent = ({
  course,
  buttonTexts,
  toggleCartItem,
}) => {
  const [preview, setPreview] = useState(false);
  return (
    <Box {...pageWrapperSpacing_userPages} pt={8}>
      <Flex>
        <>
          <Box>
            <Box
              display={"grid"}
              gap={10}
              gridTemplateColumns={{
                base: "1fr",
                lg: "1fr 1fr",
                md: "1fr",
                sm: "1fr",
              }}
            >
              <Box h="fit-content" rounded="md" a={5} spacing={8} px={3} py={5}>
                <Heading size="md" mb={4}>
                  {course?.title}
                </Heading>
                <Text>{course?.description}</Text>

                <Flex alignItems={"center"} gap={"10px"} mt={3}>
                  <Text fontWeight="bold" size="md" textAlign="start">
                    {formatPriceToNaira(Number(course?.discountedPrice))}
                  </Text>
                  <Text color="gray" textAlign="start">
                    <del>{formatPriceToNaira(Number(course?.price))}</del>
                  </Text>
                </Flex>
                <Text mt={1} textAlign="start">
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "3px",
                    }}
                  >
                    {" "}
                    Instructor:
                  </span>
                  {course?.instructor?.firstName}
                  {course?.instructor?.lastName}
                </Text>
                <Text mt={1} textAlign="start">
                  <span
                    style={{
                      fontWeight: "bold",
                      marginRight: "3px",
                    }}
                  >
                    {" "}
                    Last Updated:
                  </span>
                  {new Date(course?.updatedAt).toLocaleString()}
                </Text>
                <Box>
                  <Button
                    onClick={() => {
                      toggleCartItem(course);
                    }}
                    display="flex"
                    my={5}
                    justifyContent="start"
                    secondary
                    leftIcon={<FiShoppingCart />}
                  >
                    {buttonTexts[course.id] || "Add to cart"}
                  </Button>
                </Box>
              </Box>
              <Box
                overflow="hidden"
                rounded="md"
                mb={{ base: "30px", md: "30px", lg: "0px", sm: "30px" }}
                minH="450px"
                pos="relative"
                border={"10px solid #800020"}
              >
                {course?.previewUrl ? (
                  <video
                    title={course?.title}
                    src={course?.previewUrl}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    controls
                  />
                ) : preview ? (
                  <video
                    title={course?.title}
                    src={course?.preview}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    autoPlay
                    controls
                  />
                ) : (
                  !preview && (
                    <>
                      <Image
                        src={course?.image}
                        alt={course?.title}
                        width="100%"
                        height="100%"
                      />

                      <Flex
                        onClick={() => setPreview(true)}
                        pos="absolute"
                        top={0}
                        left={0}
                        alignItems="center"
                        justifyContent="center"
                        color="#fff"
                        bg="rgba(0, 0, 0, .5)"
                        width="100%"
                        height="100%"
                        flexDirection="column"
                        cursor="pointer"
                        sx={{
                          "svg, p": {
                            transition: "all .3s ease-in-out",
                          },
                          "&:hover svg": {
                            transform: "scale(1.2)",
                          },
                          "&:hover p": {
                            transform: "translateY(-10px)",
                          },
                        }}
                      >
                        <BsPlayFill fontSize="100px" />
                        <Text as="level1">Preview this course</Text>
                      </Flex>
                    </>
                  )
                )}
              </Box>
            </Box>
          </Box>
        </>
      </Flex>
      <Box>
      <Heading size="md" mb={3}>
        Course Modules
        </Heading>
      {course?.modules?.map(
                  (purchasedModule, moduleIndex) => (
                    <Modules
                      key={purchasedModule.id}
                      moduleIndex={moduleIndex}
                      module={purchasedModule}
                      courseId={course.id}
                    />
                  )
                )}
        <Heading size="md" mt={7} mb={4}>
          Objectives
        </Heading>
        <AboutPage course={course} />
      </Box>
    </Box>
  );
};
